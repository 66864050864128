import styled from 'styled-components'

export const SubtitleCollapsibles = styled.h2`
  font-size: 16px;
  color: #9B9B9B;
  margin-bottom: 15px;
  font-family: 'Futura-Bold', sans-serif;
  margin-top: 30px;
`

export const Collapsibles = styled.div`
    padding: 30px 0 5px 5%;
    background-color: #fff;

  .Collapsible {
    width: 100%;

    .Collapsible__trigger {
      font-family: 'Futura-Bold', sans-serif;
      font-size: 24px;
      color: #535353;
      border-bottom: 1px solid #ddd;
      width: 100%;
      display: block;
      padding: 20px 0;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }

      &.is-open {
        > div > svg {
          transform: rotate(180deg)
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        > svg {
          transition: all ease 0.2s;
        }
      }
    }

    .Collapsible__contentOuter {
      .Collapsible__contentInner {
        .collapsible-content {
          padding: 25px 0;
          width: 80%;

          p {
            font-size: 16px;
            color: #9B9B9B;
            font-weight: 500;
            :not(:last-child) {
              margin-bottom: 10px;

            }
          }
        }
      }
    }
  }
`
