import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100% - 50px);
`;

export const IframeStyled = styled.div`
  flex: 5;

  .error-page {
    text-align: center;
    margin-top: 100px;
    color: #8c8c8c;
  }

  iframe {
    display: block;
  }
`;

export const SpanErrorStyled = styled.span`
  color: red;
  font-size: 12px;

  &.spanErrorBlock {
    display: block;
    margin-top: -7px;
  }
`;

export const ModalStyle = styled.div`
  display: flex;
  justify-content: center;
  > div {
    .btn-wrapper {
      margin-top: 20px;

      button {
        background-color: #ee1d25;
        border: 0;
        color: #FFF;
        font-family: "Futura-Bold";
        text-transform: uppercase;
        text-align: center;
        padding: 10px 25px;
        cursor: pointer;
        width: 130px;
        :hover {
          opacity: 0.8;
        }

        :first-child {
          margin-right: 10px;
        }
      }
    }
  }
`;

const ModalInner = styled.div`
  h2 {
    font-family: "Futura-Bold", sans-serif;
    color: #535353;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .btn-wrapper {
    button {
      background-color: #fbce07;
      color: #535353;
      padding: 15px 20px;
      text-transform: uppercase;
      font-family: "Futura-Bold", sans-serif;
      border: 0;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }

      :first-child {
        margin-right: 15px;
      }
    }
  }
`;

export const ModalConclusaoInner = styled(ModalInner)`
  .input-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
