import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as EnrollmentActions } from "../../store/ducks/enroll";
import { Creators as TopicsActions } from "../../store/ducks/topics";
import { Creators as CourseActions } from "../../store/ducks/course";
import { Creators as RatingActions } from "../../store/ducks/rating";
import { Creators as RatingAnwerActions } from "../../store/ducks/ratingAnswer";
import { Creators as CertificateActions } from "../../store/ducks/certificado";
import { Creators as FileDownloadActions } from "../../store/ducks/fileDownload";
import NewCarouselRatings from "../../components/Carousel";
import { Content } from "../../styles";
import { CursoContentBlock, CursoContent } from "./styles";
import CursoInformacoes from "./components/CursoInformacoes";
import HeaderInfoCurso from "./components/HeaderInfoCurso";
import { ToastContainer } from "react-toastify";

class Curso extends Component {
  constructor(props) {
    super(props);
    const {
      getCourseRequest,
      getRatingsRequest,
      getEnrollRequest
    } = this.props;

    getCourseRequest(this.props.match.params.id);

    if (this.props.match.params.idMatricula) {
      getEnrollRequest(this.props.match.params.idMatricula);
    }

    getRatingsRequest(this.props.match.params.id, "Avaliação", true);
    this.state = {
      enrollmentAtivo: false,
      comments: [],
      modalAcessCourseOpen: false,
      idOferta: null,
      registerUpdated: false,
      currentSlide: 1
    };
  }

  componentWillUnmount() {
    this.props.clearEnrollment();
    this.props.clearTopicItems();
    this.setState({
      enrollmentAtivo: false,
      comments: [],
      modalAcessCourseOpen: false
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.idOferta) {
      state.idOferta = props.match.params.idOferta;
    }
    if (
      props.enrollment.data instanceof Object &&
      typeof props.enrollment.data.id != "undefined"
    ) {
      state.idOferta = props.enrollment.data.offer_id;
      state.enrollmentAtivo = props.enrollment.data;
    } else {
      state.enrollmentAtivo = false;
    }

    return state;
  }

  shouldComponentUpdate(nextProps) {
    const { modality, id } = this.props.course.data;

    if (nextProps.enrollment.enrolled === true && nextProps.ratings.success) {
      if (modality === "presencial") {
        this.onCloseModal();

        if (this.state.registerUpdated) return true;

        this.props.getCourseRequest(id);

        if (!nextProps.enrollment.data.onHold) {
          this.props.history.push(
            `/curso/${id}/matricula/${nextProps.enrollment.data.id}`
          );
        }

        this.setState({ registerUpdated: true });
      } else {
        const idRating = this.props.ratings.data.id;
        this.props.history.push(
          `/conteudo/${this.props.match.params.id}/oferta/${this.state.idOferta}/matricula/${nextProps.enrollment.data.id}/topico/initial/rating/${idRating}/rever/false`
        );
      }
    }
    return true;
  }

  onOpenModal = () => {
    this.setState({ modalAcessCourseOpen: true });
  };

  onCloseModal = () => {
    if (this.state.modalAcessCourseOpen) {
      this.setState({ modalAcessCourseOpen: false });
    }
  };

  getOferta(course, offerId) {
    return (
      course.data &&
      course.data.offers.filter(item => {
        return item.id === parseInt(offerId) ? item : null;
      })[0]
    );
  }

  getRealizacoes(course, offerId) {
    let oferta = this.getOferta(course, offerId);

    if (oferta && oferta.__meta__) {
      return oferta.__meta__.count_enrollments;
    }

    return 0;
  }

  verifyTopicCompleted = topic => {
    if (topic.scormUser) {
      if (
        topic.scormUser.session_status === "completed" ||
        topic.scormUser.session_status === "passed"
      ) {
        return true;
      }
    }
    return false;
  };

  getUrlScorm = topic => {
    const { ratings } = this.props;
    if (ratings.data) {
      const idRating = ratings.data.id;
      const { enrollmentAtivo } = this.state;
      if (enrollmentAtivo) {
        return `/conteudo/${this.props.match.params.id}/oferta/${
          this.state.idOferta
        }/matricula/${enrollmentAtivo.id}/topico/${
          topic.id
        }/rating/${idRating}/rever/${this.verifyTopicCompleted(topic)}`;
      }
    }
    return "#!";
  };

  onDownloadCertificate = () => {
    const { enrollmentAtivo } = this.state;

    if (enrollmentAtivo.certificate) {
      this.props.downloadCertificadoRequest(enrollmentAtivo.certificate.id);
    }
  };

  onDownloadFile = (id, fileName) => {
    this.props.getFileDownloadRequest(id, fileName);
  };

  render() {
    const { ratings, course } = this.props;
    const { enrollmentAtivo } = this.state;
    let oferta =
      !course.loading && course.data.level
        ? this.getOferta(course, this.state.idOferta)
        : enrollmentAtivo
        ? enrollmentAtivo.offer
        : {};
    if (course.loading || !course.success || !ratings.success) {
      return null;
    }

    if (!course.loading && course.success && ratings.success) {
      const idRating = this.props.ratings.data.id;

      return (
        <Fragment>
          <ToastContainer autoClose={3000} />
          <HeaderInfoCurso
            history={this.props.history}
            course={course}
            offer={oferta}
            enrollmentAtivo={this.state.enrollmentAtivo}
            idRating={idRating}
            modalAcessCourseOpen={this.state.modalAcessCourseOpen}
          />
          <Content>
            <CursoInformacoes
              course={course}
              certificate={this.props.certificate}
              downloadCertificado={this.onDownloadCertificate}
              fileDownload={this.props.fileDownload}
              downloadFile={this.onDownloadFile}
              realizacoesCount={this.getRealizacoes(
                course,
                this.state.idOferta
              )}
              verifyTopicCompleted={this.verifyTopicCompleted}
              getUrlScorm={this.getUrlScorm}
              enrollmentAtivo={this.state.enrollmentAtivo}
              history={this.props.history}
              ratings={ratings}
              ratingAnswer={this.props.ratingAnswer}
              postRatingAnswerRequest={this.props.postRatingAnswerRequest}
            />

            <CursoContent>
              {idRating && (
                <CursoContentBlock style={{ margin: 0 }}>
                  <h2>Comentários</h2>
                  <Fragment>
                    <NewCarouselRatings
                      courseRatingId={idRating}
                      loading={ratings.loading}
                    />
                  </Fragment>
                </CursoContentBlock>
              )}
            </CursoContent>
          </Content>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  course: state.course,
  enrollment: state.enrollment,
  ratings: state.ratings,
  ratingAnswer: state.ratingAnswer,
  certificate: state.certificado,
  fileDownload: state.fileDownload,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CourseActions,
      ...EnrollmentActions,
      ...TopicsActions,
      ...RatingActions,
      ...CertificateActions,
      ...RatingAnwerActions,
      ...FileDownloadActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Curso);
