import React from 'react';

import {
  HeaderInfo,
  HeaderInfoInner,
  Header,
  TitleAndViewWrapper,
  TitleWrapper,
} from '../../../../styles';

const MaterialHeader = ({ category, material }) => {
  return (
    <HeaderInfo style={{ paddingBottom: '80px' }}>
      <HeaderInfoInner>
        <Header>
          <div style={{ display: 'flex' }}>
            <span className="label notranslate">{category.title}</span>
          </div>
          <TitleAndViewWrapper>
            <TitleWrapper
              style={{
                width: '100%',
                marginTop: 15,
              }}
            >
              <h1 className="notranslate">{material.name}</h1>
            </TitleWrapper>
          </TitleAndViewWrapper>
        </Header>
      </HeaderInfoInner>
    </HeaderInfo>
  );
};

export default MaterialHeader;
