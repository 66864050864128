import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "loginAsUse/GET_REQUEST",
  GET_SUCCESS: "loginAsUser/GET_SUCCESS",
  UPDATE_REQUEST: "loginAsUser/UPDATE_REQUEST",
  UPDATE_SUCCESS: "loginAsUser/UPDATE_SUCCESS",
  UPDATE_IMAGE_REQUEST: "loginAsUser/UPDATE_IMAGE_REQUEST",
  UPDATE_IMAGE_SUCCESS: "loginAsUser/UPDATE_IMAGE_SUCCESS",
  GET_ERROR: "loginAsUser/GET_ERROR",
  RESET: "loginAsUser/RESET"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  error: false
});

export default function loginAsUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false
      };

    case Types.GET_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        success: true,
        error: false
      };

    case Types.GET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };

    case Types.RESET:
      return {
        data: {},
        loading: false,
        success: false,
        error: false
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  loginAsUserRequest: (username) => ({
    type: Types.GET_REQUEST,
    payload: { username }
  }),

  loginAsUserRequestSuccess: (token, refreshToken) => ({
    type: Types.GET_SUCCESS,
    payload: { token, refreshToken }
  }),

  loginAsUserRequestError: (error) => ({
    type: Types.GET_ERROR,
    payload: error
  }),

  loginAsUserReset: () => ({
    type: Types.RESET
  })
};
