import React, { Component } from 'react';

import IosArrowForward from 'react-ionicons/lib/IosArrowForward';
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS, GA_LABELS } from '../../analytics';
import ReactTooltip from "react-tooltip";

export default class CursoConteudoItem extends Component {

  accessTopic = () => {
    const {urlScorm, titulo} = this.props;
    AnalyticsEvent({
      category: GA_CATEGORIES.ENROLLMENT,
      action: GA_ACTIONS.CLICKED_TRAIL_ITEM,
      label: `${GA_LABELS.TITLE}"${titulo}"`,
    })

    this.props.history.push(urlScorm)
  }


  render() {
    const { titulo, duracao, isFeito, enrollmentAtivo, visible } = this.props;
    return (
      <li>
        <ReactTooltip place="bottom" effect="solid" />
        <div className="marker-wrapper">
          <div
            className="marker"
            style={
              !isFeito
                ? { backgroundColor: '#D8D8D8' }
                : { backgroundColor: '#0c4f92' }
            }
          />
          <div className="reta" />
        </div>
        <div className="info-and-seta-wrapper">
          <div className="info-wrapper">
            <span className="notranslate">{titulo}</span>
            {duracao && (<span>Duração: {duracao}min</span>)}
          </div>
          {enrollmentAtivo && (
            <div className="seta-wrapper">
              <a
                href="#!"
                onClick={() => {
                  if (visible.bool) {
                    this.accessTopic()
                  }
                }}
                data-tip={visible.message}>
                <IosArrowForward color={visible.bool ? "#0c4f92" : "#D8D8D8"} fontSize="24px" />
              </a>
            </div>
          )}
        </div>
      </li>
    );
  }
}
