import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "notification/GET_REQUEST",
  GET_SUCCESS: "notification/GET_SUCCESS",
  DELETE_REQUEST: "notification/DELETE_REQUEST",
  DELETE_SUCCESS: "notification/DELETE_SUCCESS",
  READ_REQUEST: "notification/READ_REQUEST",
  READ_SUCCESS: "notification/READ_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false
});

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data.data,
        loading: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    case Types.DELETE_REQUEST:
      return { ...state };
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.map(notification => {
          if (action.payload.data === notification.id) {
            notification.active = false;
          }
          return notification;
        })
      };
    case Types.READ_SUCCESS:
      return {
        ...state,
        data: state.data.map(notification => {
          if (action.payload.data === notification.id) {
            notification.read = true;
          }
          return notification;
        })
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getNotificationRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getNotificationSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  removeNotificationRequest: data => ({
    type: Types.DELETE_REQUEST,
    payload: { data }
  }),

  removeNotificationSuccess: data => ({
    type: Types.DELETE_SUCCESS,
    payload: { data }
  }),

  readNotificationRequest: data => ({
    type: Types.READ_REQUEST,
    payload: { data }
  }),

  readNotificationSuccess: data => ({
    type: Types.READ_SUCCESS,
    payload: { data }
  })
};
