import api from "../../services/api";
import { download } from "../../services/download";
import { call, put } from "redux-saga/effects";

import { Creators as TreinometroPerfilCertificadoActions } from "../ducks/certificado";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as SuccessActions } from "../ducks/success";

export function* getTreinometroPerfilCertificado(action) {
  try {
    let uri = `/api/v1/certificates?user_id=${action.payload.userId}`;
    uri += `&page=${action.payload.page || 1}`;

    const response = yield call(api.get, uri);

    yield put(
      TreinometroPerfilCertificadoActions.getTreinometroPerfilCertificadoSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter o certificado"));
  }
}

export function* downloadCertificado(action) {
  try {
    const { data } = yield call(api.get, `/api/v1/certificates/download/${action.payload.id}`, {
      responseType: 'blob'
    });

    const filename = `certificado.pdf`;

    yield call(download, { filename, data});
    yield put(TreinometroPerfilCertificadoActions.downloadCertificadoSuccess());
    yield put(SuccessActions.setSuccess("Download realizado com sucesso", { toasted: true }));
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter o certificado", { toasted: true }));
  }
}
