import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "enroll/POST_REQUEST",
  GET_SUCCESS: "enroll/GET_SUCCESS",
  GET_REQUEST: "enroll/GET_REQUEST",
  POST_CONCLUDE_REQUEST: "enroll/POST_CONCLUDE_REQUEST",
  POST_CONCLUDE_SUCCESS: "enroll/POST_CONCLUDE_SUCCESS",
  SET_ENROLLED: "enroll/SET_ENROLLED",
  CLEAR_ITEMS: "enroll/CLEAR_ITEMS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  enrolled: false
});

export default function enroll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true };
    case Types.POST_CONCLUDE_REQUEST:
      return { ...state, loading: true };
    case Types.POST_CONCLUDE_SUCCESS:
      return { data: action.payload.data, loading: false, enrolled: true };
    case Types.SET_ENROLLED:
      return { ...state, loading: false, enrolled: action.payload.flag };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false
      };
    case Types.CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        enrolled: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postEnrollRequest: offerId => ({
    type: Types.POST_REQUEST,
    payload: { offerId }
  }),

  getEnrollSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  getEnrollRequest: enrollId => ({
    type: Types.GET_REQUEST,
    payload: { enrollId },
  }),

  postEnrollConclude: enrollId => ({
    type: Types.POST_CONCLUDE_REQUEST,
    payload: { enrollId }
  }),

  postEnrollConcludeSuccess: data => ({
    type: Types.POST_CONCLUDE_SUCCESS,
    payload: { data }
  }),

  setEnrolled: flag => ({
    type: Types.SET_ENROLLED,
    payload: { flag }
  }),

  clearEnrollment: () => ({
    type: Types.CLEAR_ITEMS
  })
};
