import styled from "styled-components";

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-weight: bold;
`;
