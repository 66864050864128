import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { Container } from "../styles";
import HeaderMain from "../components/HeaderMain";
import { AnalyticsPageview } from "../analytics";

class ProtectedRoute extends Component {
  state = {
    ready: false
  };

  componentDidMount() {
    const {
      userInfo: {
        data: { isCompleted }
      }
    } = this.props;
    if (
      !isCompleted &&
      window.location.pathname.split("/", 2)[1] !== "complemento"
    ) {
      // window.location.href = "/complemento";
    }
    if (localStorage.getItem("@emae-cursos:token") === null) {
      window.location.href = "/";
    } else {
      this.setState({ ready: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.userInfo.data.id) {
      window.location.href = "/";
    }
    if (
      this.props.userInfo.data.user_terms === false &&
      window.location.pathname.split("/", 2)[1] !== "termos-uso"
    ) {
      window.location.href = "/termos-uso";
    }
    AnalyticsPageview();
  }

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    const { component: Component, location, ...props } = this.props;
    return (
      <Fragment>
        <div className="App">
          <HeaderMain />
          <Navbar location={location.pathname} />
          <Container>
            <Route
              {...props}
              render={props =>
                1 !== null ? <Component {...props} /> : <Redirect to="/" />
              }
            />
          </Container>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRoute);
