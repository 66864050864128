import { Types as ErrorTypes } from '../store/ducks/error'
import { Types as SuccessTypes } from '../store/ducks/success'

import { toast } from 'react-toastify';

/**
 * PS: importa adicionar o componente ToastContainer, nas telas em que o toast for utilizado:
 *    <ToastContainer autoClose={3000} />
 */
const ToastMiddleware = () => next => (action) => {
  if (action.payload && action.payload.toasted) {
    switch(action.type) {
      case ErrorTypes.SET:
        toast.error(action.payload.message);
        break;

      case SuccessTypes.SET:
        toast.success(action.payload.message);
        break;

      default:
        break;
    }
  }

  return next(action);
};

export default ToastMiddleware;
