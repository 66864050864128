import React, { Component } from "react";
import { Notice } from "./styles";
export default class BoxNotice extends Component {
  state = {
    verifyProfile: false,
    messages: [
      "Favor conferir os seus dados cadastrados no portal de treinamento",
      "Verifique se suas informações estão corretas no formulário abaixo",
      "Confira se os dados de e-mail e telefone celular estão corretos",
      "Importante sempre conferir se suas informações estão corretas"
    ]
  };

  componentDidMount() {
    const { userInfo } = this.props;
    if (!this.state.verifyProfile && userInfo.data.countAccess % 15 === 0) {
      this.setState({ verifyProfile: true });
    }
  }

  sortMessage = () => {
    let min = 0;
    let max = 3;
    let rand = parseInt(min + Math.random() * (max - min));
    return this.state.messages[rand];
  };

  componentDidUpdate(prevProps) {}

  render() {
    if (!this.state.verifyProfile) {
      return null;
    }
    return (
      <Notice>
        <h2>Confirme suas Informações!</h2> <p>{this.sortMessage()}</p>
      </Notice>
    );
  }
}
