import styled, { css } from "styled-components";

export const SidenavStyled = styled.div`
  flex: 2;
  background-color: #0e65bc;
  padding: 40px 0;
  max-width: 400px;

  .sidenav-header {
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 24px;
      line-height: 28px;
      color: #fff;
      font-family: "Futura-Bold", sans-serif;
      margin-bottom: 10px;
    }

    span {
      font-size: 14px;
      color: #fff;
      font-family: "Futura-Light", sans-serif;
    }
  }

  .progress-wrapper {
    margin-top: 30px;
  }
`;

export const ItemSidenav = styled.div`
  display: flex;
  padding-right: 25px;
  min-height: 40px;
  position: relative;
  padding-bottom: 25px;

  ${props => !props.visible && {
    opacity: 0.5
  }}

  cursor: ${props => props.visible ? 'pointer' : 'context-menu'};

  ${props => !props.completed && css`
    :hover {
      .left-side {
        .ball-wrapper {
          .ball {
            background-color: #fff;
          }
        }
      }
      .right-side {
        span {
          opacity: 0.8;
        }
      }
    }
  `}

  .left-side {
    padding-left: 25px;
    margin-right: 25px;

    .ball-wrapper {
      border: 1px solid #fef4d1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      z-index: 1;
      position: absolute;
      background-color: #0e65bc;

      .ball {
        width: 14px;
        height: 14px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: ${props => props.completed ? '#fbce07' : props.isActive ? 'white' : 'transparent'}
      }
    }

    .stroke {
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: #fef4d1;
      top: 0;
      left: 35px;
      z-index: 0;
    }
  }

  .right-side {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    span {
      font-size: 18px;
      font-family: "Futura-Bold", sans-serif;
      color: ${props => props.isActive ? '#fbce07' : '#fff'}
    }
  }

  :first-child {
    .stroke {
      top: 20px;
    }
  }

  :last-child {
    .stroke {
      height: 0;
    }
  }
`;
