import styled from "styled-components";

export const Notice = styled.div`
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 20px;
  color: #0c4f92;
  background-color: #fff;
  border-bottom: 1px solid red;

  h2 {
    font-size: 28px;
    display: block;
  }

  p {
    display: block;
    font-size: 16px;
  }
`;
