import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Content, Filter } from "../../../styles";
import CursoItem from "../../../components/CursoItem";

import {
  CounterAndFilterWrapper,
  LoadMoreWrapper,
  LoadButton
} from "../styles";

import { CursoList } from "../../../components/CursoItem/styles";

import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";

import { Creators as EnrollmentCompletedActions } from "../../../store/ducks/enrollmentCompleted";

import Header from "../components/Header";
import Preloader from "../../../components/Preloader";
import Shake from "../../../components/Shake";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../../analytics";

class Categoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: false,
      isSearching: false,
      shake: false
    };
  }

  handleChangeOrderFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleChoiceFilter = event => {
    this.refs.filterSpan.innerHTML = event.target.innerHTML;

    const filterOption = event.target.value;

    this.setState({
      filterOption,
      isFilterOpen: false
    });

    this.props.clearEnrollmentCompleted();
    const { getEnrollmentCompletedRequest } = this.props;
    getEnrollmentCompletedRequest({
      categoryId: this.props.match.params.id,
      order: filterOption
    });

    AnalyticsEvent({
      category: GA_CATEGORIES.ORDER,
      action: GA_ACTIONS.CHANGED_ORDER,
      label: `[${GA_LABELS.CATEGORY} ${
        this.props.match.params.id
      }] [Concluídos] - ${GA_LABELS.ORDER} "${event.target.innerHTML}"`
    });
  };

  componentDidMount() {
    const { getEnrollmentCompletedRequest } = this.props;
    getEnrollmentCompletedRequest({ categoryId: this.props.match.params.id });
  }

  getCountCursos() {
    const { data } = this.props.enrollmentCompleted;

    if (data.length === 0 || !data.length) {
      return "Nenhum treinamento disponível";
    } else if (data.length === 1) {
      return "1 treinamento";
    }

    return data.length + " treinamentos";
  }

  handleSearch = e => {
    const { value } = e.target;
    const { id } = this.props.match.params;

    this.props.clearEnrollmentCompleted();
    this.props.getEnrollmentCompletedRequest({ title: value, categoryId: id });
    this.setState({ isSearching: !!value.length });
  };

  renderCourses = () => {
    const courses = this.props.enrollmentCompleted;
    if (!courses) {
      return;
    }

    return courses.data.map((enroll, key) => {
      const offer = enroll.offer;
      return (
        <CursoItem
          icon={offer.course.category.icon}
          rating={offer.course.rating}
          title={offer.title}
          isNew={offer.course.new}
          progress={100}
          nivel={offer.course.level}
          inicio={enroll.begin}
          fim={enroll.end}
          id={offer.course.id}
          idMatricula={enroll.id}
          oferta={offer.id}
          key={key}
          modality={offer.course.modality}
          nota={enroll.final_score}
        />
      );
    });
  };

  componentWillUnmount() {
    this.props.clearEnrollmentCompleted();
  }

  triggerSearchMode = () => {
    this.setState({
      isSearching: !this.state.isSearching
    });

    const { getEnrollmentCompletedRequest } = this.props;
    this.props.clearEnrollmentCompleted();
    getEnrollmentCompletedRequest({ categoryId: this.props.match.params.id });
  };

  reloadItems = () => {
    const { id } = this.props.match.params;
    const { title } = this.state;
    const { enrollmentCompleted } = this.props;

    if (this.nextPage()) {
      this.props.getEnrollmentCompletedRequest({
        title,
        categoryId: id,
        page: enrollmentCompleted.page + 1
      });

      AnalyticsEvent({
        category: GA_CATEGORIES.COURSES_LIST,
        action: GA_ACTIONS.CLICKED_LOAD_MORE,
        label: `[${GA_LABELS.CATEGORY} ${id}] [Concluídos] - ${
          GA_LABELS.PAGE
        } ${enrollmentCompleted.page + 1}`
      });
    }
  };

  nextPage = () => {
    const { enrollmentCompleted } = this.props;
    return enrollmentCompleted.lastPage >= enrollmentCompleted.page + 1;
  };

  render() {
    const { isFilterOpen } = this.state;
    const { enrollmentCompleted } = this.props;

    return (
      <Fragment>
        <Header
          handleSearch={this.handleSearch}
          categoryId={this.props.match.params.id}
          mode="finished"
          triggerSearchMode={this.triggerSearchMode}
        />

        <Content>
          <CounterAndFilterWrapper>
            <span>{this.getCountCursos()}</span>
            <a href="#!" onClick={this.handleChangeOrderFilter}>
              <span ref="filterSpan">recentes</span>
              <MdArrowDropdown color="#686868" />
            </a>
            {isFilterOpen && (
              <Filter>
                <ul>
                  <li value="0" onClick={this.handleChoiceFilter}>
                    recentes
                  </li>
                  <li value="1" onClick={this.handleChoiceFilter}>
                    mais antigo
                  </li>
                  <li value="2" onClick={this.handleChoiceFilter}>
                    melhor avaliado
                  </li>
                  <li value="3" onClick={this.handleChoiceFilter}>
                    pior avaliado
                  </li>
                </ul>
              </Filter>
            )}
          </CounterAndFilterWrapper>

          <Shake shake={this.state.shake}>
            <CursoList>
              {enrollmentCompleted.loading && <Preloader />}
              {this.renderCourses()}
            </CursoList>
            <LoadMoreWrapper>
              {this.nextPage() && !enrollmentCompleted.loading ? (
                <LoadButton onClick={this.reloadItems}>
                  Carregar mais
                </LoadButton>
              ) : (
                <LoadButton disabled>Carregar mais</LoadButton>
              )}
            </LoadMoreWrapper>
          </Shake>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enrollmentCompleted: state.enrollmentCompleted
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...EnrollmentCompletedActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Categoria);
