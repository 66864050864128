import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as CategoriesActions } from "../ducks/categories";
import { Creators as ErrorActions } from "../ducks/error";

export function* getCategories(action) {
  try {
    let url = `/api/v1/categories?paginate=${action.payload.paginate || true}`;
    const response = yield call(api.get, url);
    yield put(
      CategoriesActions.getCategoriesSuccess(
        response.data.details || response.data.data
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
