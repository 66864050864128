import styled from 'styled-components'

export const SearchModeStyled = styled.div`
  margin-bottom: 20px;

  > div {
    margin-bottom: 10px;
    span {
      font-family: 'Futura-Light', sans-serif;
      font-size: 18px;
      color: #fff;
      margin-right: 25px;
    }
    a {
      font-size: 12px;
      color: #fff;
      text-decoration: underline;
    }
  }

  input {
    border: 0;
    font-size: 42px;
    color: #fff;
    font-family: 'Futura-Bold', sans-serif;
    background: transparent;

    ::placeholder {
      color: #fff;
      opacity: 0.5;
    }
  }
`
