import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content:space-between;
  align-items: center;
  height: 50px;
  background-color: #0E65BC;
  padding: 0 55px;
  color: #fff;

  @media screen and (max-width:450px) {
    padding: 0 15px;
  }

  .licenciada-logo {
      width: 100px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    font-size: 12px;
  }
`;

export const EmaeLogoWrapper = styled.div`
  display:flex;
  align-items:center;

  .logo-emae {
    height: 26px
  }

  span {
    display: block;
    margin-left: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Futura-Light', sans-serif;
  }
`
