import React, { Component, Fragment } from "react";

import {
  TitleAndViewWrapper,
  TitleWrapper,
  Header,
  HeaderInfo,
  HeaderInfoInner,
  Content,
  HeaderBtns,
  ModalInner,
  ContainerButtons,
  RedButton,
  CancelButton
} from "../../styles";

import IconCake from "../../assets/img/icon/ico-cake.svg";
import IconAjuda from "../../assets/img/icon/ico-ajuda.svg";
import IosCameraOutline from "react-ionicons/lib/IosCamera";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";

import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-image-crop/dist/ReactCrop.css";

import {
  UserHeadInfo,
  LeftSide,
  RightSide,
  RightSideContainer,
  UserContent,
  ModalPicBox,
  ModalButtonWrapper,
  InputFileWrapper
} from "./styles";
import ReactCrop from "react-image-crop";
import {
  FormInput,
  FormSelect,
  FormInputMask,
  FormError,
  FormInputPhoto,
  FormCheckBox
} from "../../components/Form";
import { FormItemWrapper } from "../../components/Form/styles";
import Loader from "../Login/components/Loader";
import Modal from "react-responsive-modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as UserInfoActions } from "../../store/ducks/userInfo";
import { Creators as ErrorActions } from "../../store/ducks/error";
import { Creators as SuccessActions } from "../../store/ducks/success";
import { Creators as CategoriesActions } from "../../store/ducks/categories";
import { Creators as InterestsActions } from "../../store/ducks/interests";

import { Formik } from "formik";
import * as Yup from "yup";
import { crop } from "../../services/image";

import DefaultPhoto from "../../assets/img/frentista.jpg";
import Photo from "../../components/Photo";
import BoxNotice from "../../components/BoxNotice";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required("É necessário preencher esse campo!"),
  genre: Yup.string()
    .nullable()
    .required("É necessário preencher esse campo!"),
  birthday: Yup.string()
    .nullable()
    .required("É necessário preencher esse campo!"),
  marital_status: Yup.string()
    .nullable()
    .required("É necessário preencher esse campo!"),
  scholarity: Yup.string()
    .nullable()
    .required("É necessário preencher esse campo!")
});

class Perfil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyProfile: false,
      isModalPicOpen: false,
      file: {
        uploadedUri: null,
        uploadedFile: {},
        croppedFile: {},
        croppedUri: null,
        filename: null
      },
      crop: {
        width: 50,
        aspect: 4 / 4
      },
      interests: {
        categories: [],
        loaded: false
      }
    };

    this.onChangePic = this.onChangePic.bind(this);
  }

  componentDidMount() {
    this.props.getUserInfoRequest();
    this.props.getCategoriesRequest({ paginate: "false" });
  }

  componentDidUpdate() {
    const isComplemento = this.props.match.path === "/complemento";
    if (isComplemento && this.props.userInfo.updated) {
      return this.props.history.push(`/home`);
    }
  }

  componentWillReceiveProps(props) {
    let { data } = props.userInfo;
    let {
      id,
      name,
      genre,
      phone,
      cellphone,
      birthday,
      marital_status,
      scholarity,
      email,
      origin
    } = data;

    !props.userInfo.loading &&
      this.setState({
        id,
        name,
        genre,
        phone,
        cellphone,
        marital_status,
        scholarity,
        email,
        birthday: data.birthday
          ? moment(birthday)
              .utc()
              .format("YYYY-MM-DD")
          : "",
        origin
      });

    if (!props.categories.loading && !this.state.interests.loaded) {
      let categories = [];
      props.categories.data.map(
        category => category.interests.length && categories.push(category.id)
      );

      this.setState({
        interests: { categories, loaded: true }
      });
    }

    !props.userInfo.loading && this.closeModalChangePic();
  }

  openModalChangePic = () => {
    this.setState({ isModalPicOpen: true });
  };

  closeModalChangePic = () => {
    this.setState({ isModalPicOpen: false });
  };

  onChangePic = e => {
    const file = e.target.files[0];
    if (!file) return;

    this.setState({
      file: {
        ...this.state.file,
        uploadedUri: URL.createObjectURL(file),
        uploadedFile: file,
        filename: file.name
      }
    });
  };

  handleLogout = () => {
    localStorage.removeItem("@emae-cursos:userInfo");
    localStorage.removeItem("@emae-cursos:refreshToken");
    localStorage.removeItem("@emae-cursos:token");
    window.location.href = "/";
  };

  handleSubmit = values => {
    this.props.updateUserInfoRequest(values);

    const { categories } = this.state.interests;
    const { id } = this.props.userInfo.data;
    this.props.postInterestsRequest({ categories, user_id: id });
  };

  saveFile = () => {
    const {
      file: { croppedFile }
    } = this.state;

    const form = new FormData();
    form.append("file", croppedFile);
    this.props.updateUserImageRequest(form);
  };

  renderForm = ({
    handleSubmit,
    errors,
    handleChange,
    values,
    setFieldValue
  }) => {
    const {
      name,
      genre,
      phone,
      cellphone,
      birthday,
      marital_status,
      scholarity,
      email,
    } = values;
    const { loading } = this.props.userInfo;

    const optionsGenrer = [
      {
        value: "",
        nome: "Selecionar"
      },
      {
        value: "masculino",
        nome: "Masculino"
      },
      {
        value: "feminino",
        nome: "Feminino"
      },
      {
        value: "prefiro não responder",
        nome: "Prefiro não responder"
      },
      {
        value: "outros",
        nome: "Outros"
      }
    ];

    const optionsMarital = [
      {
        value: "",
        nome: "Selecionar"
      },
      {
        value: "solteiro",
        nome: "Solteiro"
      },
      {
        value: "casado",
        nome: "Casado"
      },
      {
        value: "divorciado",
        nome: "Divorciado"
      },
      {
        value: "viúvo",
        nome: "Viúvo"
      }
    ];

    const optionsScholarity = [
      {
        value: "",
        nome: "Selecionar"
      },
      {
        value: "fundamental incompleto",
        nome: "Ensino Fundamental Incompleto"
      },
      {
        value: "fundamental",
        nome: "Ensino Fundamental"
      },
      {
        value: "médio incompleto",
        nome: "Ensino Médio Incompleto"
      },
      {
        value: "médio",
        nome: "Ensino Médio"
      },
      {
        value: "superior",
        nome: "Ensino Superior"
      },
      {
        value: "pos_graduacao",
        nome: "Pós Graduação"
      },
      {
        value: "mestrado",
        nome: "Mestrado"
      },
      {
        value: "mestrado incompleto",
        nome: "Mestrado Incompleto"
      },
      {
        value: "Doutorado",
        nome: "Doutorado"
      },
      {
        value: "doutorado incompleto",
        nome: "Doutorado Incompleto"
      },
      {
        value: "sem_instrucao_formal",
        nome: "Sem instrução formal"
      }
    ];

    const isComplemento = this.props.match.path === "/complemento";

    return (
      <LeftSide>
        <BoxNotice userInfo={this.props.userInfo} />
        <FormItemWrapper>
          {errors.name && <FormError message={errors.name} />}
          <FormInput
            value={name || ""}
            label="Nome Social"
            type="text"
            name="name"
            onChange={handleChange}
            disabled={true}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          <FormInput
            value={email || ""}
            label="E-mail"
            type="text"
            name="email"
            onChange={handleChange}
            disabled={true}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          {errors.genre && <FormError message={errors.genre} />}
          <FormSelect
            label="Sexo"
            onChange={handleChange}
            options={optionsGenrer}
            value={genre || ""}
            name="genre"
          />
        </FormItemWrapper>

        <FormItemWrapper>
          <FormInputMask
            mask="(99) 9999-9999"
            value={phone || ""}
            label="Telefone Comercial"
            type="text"
            name="phone"
            onChange={handleChange}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          <FormInputMask
            mask="(99) 99999-9999"
            value={cellphone || ""}
            label="Telefone Celular"
            type="text"
            name="cellphone"
            onChange={handleChange}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          {errors.birthday && <FormError message={errors.birthday} />}
          <FormInput
            value={birthday || ""}
            label="Data de nascimento"
            type="date"
            max={moment().format("YYYY-MM-DD")}
            name="birthday"
            onChange={handleChange}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          {errors.marital_status && (
            <FormError message={errors.marital_status} />
          )}
          <FormSelect
            options={optionsMarital}
            value={marital_status || ""}
            label="Estado civil"
            type="text"
            name="marital_status"
            onChange={handleChange}
          />
        </FormItemWrapper>

        <FormItemWrapper>
          {errors.scholarity && <FormError message={errors.scholarity} />}
          <FormSelect
            options={optionsScholarity}
            value={scholarity || ""}
            label="Escolaridade"
            type="text"
            name="scholarity"
            onChange={handleChange}
          />
        </FormItemWrapper>

        <ContainerButtons>
          <RedButton loading={loading} onClick={handleSubmit}>
            {loading ? <Loader /> : isComplemento ? "Continuar" : "Salvar"}
          </RedButton>
          {isComplemento && (
            <RedButton onClick={this.handleLogout}>Sair</RedButton>
          )}
        </ContainerButtons>
      </LeftSide>
    );
  };

  handleInterest = e => {
    let categories = this.state.interests.categories;

    if (e.target.checked) {
      this.setState({
        interests: {
          ...this.state.interests,
          categories: [...categories, Number(e.target.id)]
        }
      });
    } else {
      categories = categories.filter(
        category => category !== Number(e.target.id)
      );
      this.setState({
        interests: {
          ...this.state.interests,
          categories
        }
      });
    }
  };

  fetchInterests = () => {
    const { categories } = this.props;

    if (categories.loading) return;

    return categories.data.map(category => (
      <FormCheckBox
        onChange={this.handleInterest}
        data={{ id: category.id }}
        key={category.id}
        label={category.title}
        defaultChecked={!!category.interests.length}
      />
    ));
  };

  onComplete = async (image, cropData) => {
    const {
      file: { uploadedUri, uploadedFile }
    } = this.state;
    if (cropData.width && cropData.height) {
      const croppedFile = await crop(uploadedUri, cropData, uploadedFile.name);

      this.setState({
        file: {
          ...this.state.file,
          croppedFile,
          croppedUri: URL.createObjectURL(croppedFile)
        }
      });
    }
  };
  renderProfilePhotoModal = () => {
    const { file_uuid } = this.props.userInfo.data;
    const { croppedUri } = this.state.file;

    if (croppedUri) {
      return <img alt="Foto de perfil" src={croppedUri} />;
    }

    return <Photo fileId={file_uuid} defaultPhoto={DefaultPhoto} />;
  };

  renderProfilePhoto = () => {
    const { file_uuid } = this.props.userInfo.data;

    return (
      <div className="img-wrapper">
        <Photo fileId={file_uuid} defaultPhoto={DefaultPhoto} />
      </div>
    );
  };

  render() {
    const { name, role } = this.props.userInfo.data;
    const { birthday } = this.state;
    const { loading } = this.props.userInfo;
    const isComplemento = this.props.match.path === "/complemento";

    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        <Modal
          open={this.state.isModalPicOpen}
          onClose={this.closeModalChangePic}
          showCloseIcon={false}
          styles={{
            overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
            modal: { borderRadius: "3px", width: "500px", padding: "0" }
          }}
        >
          <ModalInner>
            <div className="header">
              <h2>Alterar foto</h2>
            </div>
            <div className="body">
              <InputFileWrapper>
                <FormInputPhoto
                  type="file"
                  filename={this.state.file.filename}
                  onChange={this.onChangePic}
                  accept="image/jpg,image/jpeg,image/png"
                />
              </InputFileWrapper>
              <ModalPicBox>
                <div className="content-img">
                  {this.renderProfilePhotoModal()}
                </div>

                <div className="content-crop">
                  {this.state.file.uploadedUri && (
                    <ReactCrop
                      onComplete={this.onComplete}
                      crop={this.state.crop}
                      onChange={crop => this.setState({ crop })}
                      src={this.state.file.uploadedUri}
                    />
                  )}
                </div>
              </ModalPicBox>

              <ModalButtonWrapper>
                <CancelButton type="button" onClick={this.closeModalChangePic}>
                  Cancelar
                </CancelButton>
                <RedButton
                  type="button"
                  loading={loading}
                  onClick={this.saveFile}
                >
                  {loading ? <Loader /> : "Salvar"}
                </RedButton>
              </ModalButtonWrapper>
            </div>
          </ModalInner>
        </Modal>

        <HeaderInfo>
          <HeaderInfoInner>
            <Header>
              {isComplemento && <h1>Finalizando cadastro</h1>}
              <span className="label">{role ? role.title : ""}</span>
              <TitleAndViewWrapper>
                <TitleWrapper forMobile>
                  <h1>{name}</h1>
                </TitleWrapper>
              </TitleAndViewWrapper>
              <HeaderBtns>
                <UserHeadInfo>
                  <div>
                    <img src={IconCake} alt="" />
                    <span>
                      {birthday
                        ? moment(birthday).format("DD/MM/YYYY")
                        : "Não informado"}
                    </span>
                  </div>
                </UserHeadInfo>
              </HeaderBtns>
            </Header>
          </HeaderInfoInner>
        </HeaderInfo>

        <Content>
          <UserContent>
            {this.props.userInfo.success && (
              <Formik
                render={this.renderForm}
                validationSchema={ValidationSchema}
                onSubmit={values => this.handleSubmit(values)}
                initialValues={this.state}
              />
            )}
            <RightSideContainer>
              <RightSide overlayMenu>
                <div className="header">
                  {this.renderProfilePhoto()}
                  <div
                    className="change-pic-wrapper"
                    onClick={this.openModalChangePic}
                  >
                    <IosCameraOutline fontSize="24px" color="#535353" />
                    <span>
                      Alterar foto de perfil <br />
                      (até 2mb)
                    </span>
                  </div>
                </div>

                <ul className="list">
                  {/*<li>
                    <a href='#!'>
                      <div>
                        <img src={IconConfig} alt='' />
                        <span>Configurações</span>
                      </div>
                      <IosArrowForward fontsSize='24px' color='#b11b21' />
                    </a>
                  </li>*/}
                  <li>
                    <a href="/ajuda">
                      <div>
                        <img src={IconAjuda} alt="" />
                        <span>Dúvidas</span>
                      </div>
                      <IosArrowForward fontsSize="24px" color="#b11b21" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="/sobre">
                      <div>
                        <img src={IconInfo} alt="" />
                        <span>Sobre o app</span>
                      </div>
                      <IosArrowForward fontsSize="24px" color="#b11b21" />
                    </a>
                  </li> */}
                </ul>
              </RightSide>
            </RightSideContainer>
          </UserContent>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  categories: state.categories
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserInfoActions,
      ...ErrorActions,
      ...SuccessActions,
      ...CategoriesActions,
      ...InterestsActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Perfil);
