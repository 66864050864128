import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as SearcActions } from "../ducks/search";
import { Creators as ErrorActions } from "../ducks/error";
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS, GA_LABELS } from "../../analytics";

export function* getSearch(action) {
  try {
    let params = "?text=" + action.payload.text;

    if (action.payload.categoryId) {
      params += "&category_id=" + action.payload.categoryId;
    }

    const response = yield call(api.get, "/api/v1/courses-categories" + params);

    yield put(SearcActions.getSearchSuccess(response.data.data));

    AnalyticsEvent({
      category: GA_CATEGORIES.SEARCH,
      action: GA_ACTIONS.SEARCHED,
      label: `[${GA_LABELS.CATEGORIES_LIST}] - ` + GA_LABELS.TERM + `"${action.payload.text}"`
    });

  } catch (err) {
    if (err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro ao realizar a busca"));
    }
  }
}
