import styled,{css} from 'styled-components'

export const ProgressBarStyle = styled.div`
    height:100%;
    width:100%;

    .progress-wrapper {
        height:4px;
        width:100%;
        background-color: #D8D8D8;
        display: flex;
    }

    .progress-done {
        width: ${props => props.progress && css`${props.progress}%`};
        height:4px;
        background-color: #FBCE07;
    }
`