import api from '../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as InterestsActions } from '../ducks/interests';
import { Creators as ErrorActions } from '../ducks/error';

export function* postInterests(action) {
  try {
    yield call(api.post, '/api/v1/interests', {
      ...action.payload
    });

    yield put(InterestsActions.postInterestsSuccess());
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.'));
    }
  }
}
