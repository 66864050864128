import styled from "styled-components";

export const CursoList = styled.ul`
  li {
    width: 100%;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 15px 20px 0 25px;
      flex-direction: column;

      :hover {
        background-color: #f9f9f9;
      }

      @media screen and (max-width: 874px) {
        padding: 0;
        padding-left: 10px;
      }
    }
  }
`;

export const CursoItemWrapper = styled.li`
  display: flex;
  margin-bottom: 3px;
  @media screen and (max-width: 874px) {
    width: 100%;
  }
`;

export const CursoInfoAndBadge = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const CursoIconAndStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const CursoIconWrapper = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 874px) {
    display: none;
  }
`;

export const CursoInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const CursoInfoStars = styled.div``;

export const CursoTitle = styled.div`
  h2 {
    font-size: 1.5em;
    color: #535353;
    font-family: "Futura-Bold", sans-serif;
  }
`;

export const CursoStats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 15px;
  max-width: 300px;

  div {
    display: flex;
    flex-direction: column;
    color: #9b9b9b;

    .title {
      font-size: 12px;
    }

    .result {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

export const CursoBadge = styled.div`
  display: flex;
  align-self: flex-start;

  .badge {
    text-transform: uppercase;
    padding: 3px 10px;
    color: #FFF;
    background-color: #ee1d25;
    font-size: 10px;
    font-family: "Futura-Bold", sans-serif;
    &:last-child {
      margin-left: 1px;
    }
    &.presencial {
      background-color: #676767;
      color: #fff;
    }
  }
`;

export const CursoProgressBar = styled.div`
  display: flex;
  width: calc(100% - 65px);
  margin-left: 65px;

  @media screen and (max-width: 874px) {
    width: 100%;
    margin-left: 0;
    padding-right: 15px;
  }
`;

export const BadgeNota = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;

  span {
    line-height: 24px;
    color: #535353;
    font-family: "Futura-Bold", sans-serif;
  }

  .value {
    font-size: 30px;
  }
`;

export const ContainerBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
