import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { ContainerLogin, AppLogin } from "../styles";
import HeaderMain from "../components/HeaderMain";
import Loading from "../components/Loading";
import { AnalyticsPageview } from "../analytics";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class LoginRoute extends Component {
  state = {
    ready: false
  };

  componentDidMount() {
    if (localStorage.getItem("@emae-cursos:token") !== null) {
      if (this.props.userInfo.data.countAccess % 15 === 0) {
        window.location.href = window.location.origin + "/perfil";
      } else {
        window.location.href = window.location.origin + "/home";
      }
    } else {
      this.setState({ ready: true });
    }
  }

  componentDidUpdate() {
    AnalyticsPageview();
  }

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    const { component: Component, ...props } = this.props;
    return (
      <Fragment>
        <AppLogin>
          <HeaderMain />
          <ContainerLogin>
            <Route
              {...props}
              render={props =>
                1 !== null ? <Component {...props} /> : <Redirect to="/" />
              }
            />
          </ContainerLogin>
        </AppLogin>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  categories: state.categories
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginRoute);
