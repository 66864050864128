import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as TopicsActions } from "../ducks/topics";
import { Creators as ErrorActions } from "../ducks/error";

export function* getTopics(action) {
  try {
    const response = yield call(
      api.get,
      "/api/v1/topics?course_id=" +
        action.payload.courseId +
        "&enrollment_id=" +
        action.payload.enrollmentId
    );

    yield put(TopicsActions.getTopicsSuccess(response.data.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Ocorreu um erro na requisição"));
    }
  }
}
