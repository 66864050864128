import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  definePasswordRequest: ["token", "password"],
  definePasswordSuccess: ["success"],
  definePasswordError: ["error"],
  definePasswordClearError: null,
  definePasswordLoading: null
});

export const DefinePasswordTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  username: null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { token, password }) => {
  return state.merge({
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEFINE_PASSWORD_SUCCESS]: success,
  [Types.DEFINE_PASSWORD_ERROR]: error,
  [Types.DEFINE_PASSWORD_CLEAR_ERROR]: clearError,
  [Types.DEFINE_PASSWORD_LOADING]: loading
});
