import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "scorm/POST_REQUEST",
  GET_REQUEST: "scorm/GET_REQUEST",
  GET_DATA_SUCCESS: "scorm/GET_DATA_SUCCESS",
  GET_SUCCESS: "scorm/GET_SUCCESS",
  GET_FAILURE: "scorm/GET_FAILURE",
  CLEAR_STATE: "scorm/CLEAR_STATE"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  saved: false,
  getData: false,
});

export default function scorm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true, saved: false };
    case Types.GET_REQUEST:
      return { ...state, loading: true, getData: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        saved: true,
        getData: false,
      };
    case Types.GET_DATA_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        saved: false,
        getData: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    case Types.CLEAR_STATE:
      return {
        data: [],
        loading: false,
        saved: false,
        getData: false,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  updateScormRequest: (enrollmentId, topicId, data) => ({
    type: Types.POST_REQUEST,
    payload: { enrollmentId, topicId, data }
  }),
  getScormRequest: (enrollmentId, topicId) => ({
    type: Types.GET_REQUEST,
    payload: { enrollmentId, topicId }
  }),
  getScormDataSuccess: data => ({
    type: Types.GET_DATA_SUCCESS,
    payload: { data }
  }),
  getScormSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),
  getFailure: () => ({
    type: Types.GET_FAILURE
  }),
  clearScorm: () => ({
    type: Types.CLEAR_STATE
  })
};
