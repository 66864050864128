import React, { Component } from 'react';

import { ProgressBarStyle } from './styles'

export default class ProgressBar extends Component {
    render() {
        const { progress } = this.props;
        return (
            <ProgressBarStyle progress={progress}>
                <div className='progress-wrapper'>
                    <div className='progress-done'></div>
                </div>
            </ProgressBarStyle>
        )
    }
}