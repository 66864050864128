import styled from "styled-components";

export const ButtonAcessarCurso = styled.button`
  border: 0;
  padding: 15px 30px;
  color: ${props => (!props.isPresencial ? "#fff" : "#fff")};
  text-transform: uppercase;
  font-family: "Futura-Bold", sans-serif;
  background-color: ${props => (!props.isPresencial ? "#ee1d25" : "#838383")};
  cursor: ${props => (!props.isPresencial ? "pointer" : "default")};

  :hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 680px) {
    padding: 15px;
  }
`;

export const CursoDados = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -50px;

  .btn-certificado, .codigo-conduta-link {
    cursor: pointer;
    color: #0E65BC !important;
  }

  .codigo-conduta-link {
    color: #0E65BC !important;
  }

  div {
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    :not(:last-child) {
      margin-right: 2px;
    }

    span {
      color: #535353;
      font-size: 14px;

      :nth-child(2) {
        font-size: 20px;
        color: #9b9b9b;
        font-family: "Futura-Bold", sans-serif;
        margin-top: 5px;
      }
    }

    @media screen and (max-width: 680px) {
      padding: 10px;
      :not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
  }
`;

export const CursoContent = styled.div`
  margin-top: 60px;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const CursoContentBlock = styled.div`
  flex: 1;
  :first-child {
    flex: 2;
    margin-right: 50px;

    @media screen and (max-width: 900px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: 24px;
    font-family: "Futura-Bold", sans-serif;
    color: #535353;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #9b9b9b;
    line-height: 21px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 680px) {
    padding: 0 15px;
  }
`;

export const CursoConteudoList = styled.ul`
  background-color: #fff;

  li {
    display: flex;

    .marker-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin: 0 10px;

      .marker {
        margin-top: 15px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid #d8d8d8;
        z-index: 2;
      }

      .reta {
        background-color: #d8d8d8;
        width: 2px;
        height: 100%;
        position: absolute;
        z-index: 1;
      }
    }

    .info-and-seta-wrapper {
      flex: 10;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      .info-wrapper {
        display: flex;
        flex-direction: column;
        flex: 10;

        span {
          font-size: 14px;
          color: #4a4a4a;
          margin-top: 4px;

          &:first-child {
            font-family: "Futura-Bold", sans-serif;
          }
        }
      }

      .seta-wrapper {
        display: flex;
        align-items: center;
        padding-right: 15px;
      }
    }

    :not(:last-child) {
      .info-and-seta-wrapper {
        border-bottom: 1px solid #d8d8d8;
      }
    }

    :first-child {
      .reta {
        top: 15px;
        height: calc(100% - 10px);
      }
    }

    :last-child {
      .reta {
        height: 20px;
      }
    }
  }
`;

export const CursoConteudoLocal = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-left: 3px solid #0c4f92;

  p {
    margin: 0;
    font-size: 14px;
  }
`;

export const CursoDepoimentos = styled.div`
  display: flex;
  background-color: #fff;
  padding: 0 15px 15px 0;

  .btn-carousel {
    position: absolute;
    top: 15px;
    border: 0;
    background: 0;

    &-back {
      right: 60px;
    }
    &-next {
      right: 0px;
    }

    &[disabled] {
      cursor: default;
      svg {
        fill: #e0e0e0;
      }
    }
  }
`;

export const Depoimento = styled.div`
  display: flex;

  .img-wrapper {
    max-width: 150px;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    > div {
      padding: 25px 0 5px;
    }

    p {
      font-size: 14px;
      color: #686868;
      line-height: 21px;
      max-height: 50px;
      overflow-y: auto;
    }

    span {
      color: #9b9b9b;

      &.nome {
        font-size: 14px;
        font-family: "Futura-Bold", sans-serif;
      }

      &.estado {
        font-size: 12px;
      }
    }
  }
`;

export const ModalStyle = styled.div`
  display: flex;
  justify-content: center;
  > div {
    h2 {
      color: #535353;
      font-family: "Futura-Bold";
      font-size: 20px;
      margin-bottom: 25px;
      text-transform: uppercase;
      line-height: 24px;
    }

    .btn-wrapper {
      button {
        background-color: #fbce07;
        border: 0;
        color: #535353;
        font-family: "Futura-Bold";
        text-transform: uppercase;
        text-align: center;
        padding: 10px 25px;
        cursor: pointer;
        width: 90px;
        :hover {
          opacity: 0.8;
        }

        :first-child {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const BoxAvaliarCurso = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  .avalia-curso-message {
    background: #fff;
    flex: 1;
    box-shadow: 0px 0px 3px #bebbbb;
    padding: 20px;
    font-size: 15px;
    color: #686868;
    margin-right: 3px;
  }

  .avalia-curso-button {
    border: 0;
    padding: 15px 30px;
    color: ${props => (!props.isPresencial ? "#FFF" : "#fff")};
    text-transform: uppercase;
    font-family: "Futura-Bold", sans-serif;
    background-color: ${props => (!props.isPresencial ? "#ee1d25" : "#838383")};
    cursor: ${props => (!props.isPresencial ? "pointer" : "default")};

    :hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 680px) {
      padding: 15px;
    }
  }
`;

export const ModalConclusaoInner = styled.div`
  h2 {
    font-family: "Futura-Bold", sans-serif;
    color: #535353;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .input-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-wrapper {
    button {
      background-color: #fbce07;
      color: #535353;
      padding: 15px 20px;
      text-transform: uppercase;
      font-family: "Futura-Bold", sans-serif;
      border: 0;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }

      :first-child {
        margin-right: 15px;
      }
    }
  }
`;
