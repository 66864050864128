import React, { Component, Fragment } from "react";
import {
  Header,
  HeaderInfo,
  TitleWrapper,
  TitleAndViewWrapper,
  Content,
  HeaderInfoInner
} from "../../styles";
import { Collapsibles } from "./styles";

import Collapsible from "react-collapsible";
import { CollapsibleItemHead } from "./components/Collapsible";

export default class Help extends Component {
  render() {
    return (
      <Fragment>
        <HeaderInfo style={{ paddingBottom: "40px" }}>
          <HeaderInfoInner>
            <Header>
              <TitleAndViewWrapper>
                <TitleWrapper>
                  <h1>Dúvidas</h1>
                </TitleWrapper>
              </TitleAndViewWrapper>
            </Header>
          </HeaderInfoInner>
        </HeaderInfo>
        <Content>
          <Collapsibles>
            <Collapsible
              trigger={<CollapsibleItemHead title="Senha" />}
              transitionTime={200}
            >
              <div className="collapsible-content">
                <p>
                  Pressione o cursor do mouse no botão que está no canto superior direito da tela, ao lado de seu nome, e clique na opção <strong>ALTERAR SENHA</strong>.
                </p>
                <p>
                  Insira seu e-mail no campo solicitado e clique em <strong>ALTERAR SENHA</strong>. Um link para recuperação de senha será enviado para o seu e-mail.
                </p>
                <p>
                  Cole o endereço do link na barra de pesquisa superior do Google assim que recebê-lo (prazos de links de recuperação de senhas expiram rapidamente).
                </p>
                <p>
                  Crie uma nova senha e a repita no campo solicitado e pressione o cursor do mouse em <strong>DEFINIR SENHA</strong>.
                </p>
                <p>
                  Repita os procedimentos anteriores caso receba um alerta de <strong>TOKEN EXPIRADO</strong>.
                </p>
              </div>
            </Collapsible>
          </Collapsibles>
        </Content>
      </Fragment>
    );
  }
}
