import styled, { css } from "styled-components";

export const ListAndProfileInfo = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  flex: 1;
  margin-top: -150px;
  margin-left: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1000px) {
    margin: 0 0 20px;
    order: 1;
    max-height: none;
  }
`;

export const ProfileHeader = styled.div`
  height: 122px;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
`;

export const ProfileHeaderPic = styled.div`
  flex: 1;
  img {
    width: 120px;
    height: 120px;
  }
`;

export const ProfileHeaderInfo = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .title {
    font-size: 14px;
    color: #535353;
  }

  .pontos {
    font-size: 3em;
    color: #535353;
    font-family: "Futura-Bold";
  }
`;

export const ProfileBody = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 2px solid #f0f0f0;

    .left-side {
      display: flex;
      flex-direction: column;

      span {
        font-size: 12px;
        color: #b0b0b0;
        font-weight: bold;
        :first-child {
          font-size: 16px;
          color: #686868;
          font-weight: 500;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        :not(:last-child) {
          margin-right: 15px;
        }

        span {
          font-size: 16px;
          color: #b0b0b0;
          font-weight: bold;

          :first-child {
            font-size: 12px;
            color: #686868;
            font-weight: 500;
          }
        }
      }
    }
  }
`;

export const ProfileFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  align-items: center;
  flex: 1;

  ${props => props.bottomBorder && css`
    border-bottom: 2px solid #f0f0f0;
  `}

  p {
    font-size: 12px;
    color: #9e9e9e;
    line-height: 15px;
    font-weight: bold;
    margin-left: 15px;
  }
`;

export const ProfileUpdate = styled.div`
  display: flex;
  padding: 15px;
  flex: 1;
  flex-direction: column;
  border-bottom: 2px solid #f0f0f0;

  p {
    font-size: 14px;
    color: #9e9e9e;
    font-weight: bold;
  }
`;
