import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "htmlCourse/POST_REQUEST",
  GET_REQUEST: "htmlCourse/GET_REQUEST",
  GET_SUCCESS: "htmlCourse/GET_SUCCESS",
  GET_FAILURE: "htmlCourse/GET_FAILURE",
  CLEAR_STATE: "htmlCourse/CLEAR_STATE"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  saved: false,
});

export default function htmlCourse(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true, saved: false };
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        saved: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    case Types.CLEAR_STATE:
      return {
        data: [],
        loading: false,
        saved: false,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  updateHTMLCourseRequest: (enrollmentId, topicId, data, courseId, onConclude) => ({
    type: Types.POST_REQUEST,
    payload: { enrollmentId, topicId, data, courseId, onConclude }
  }),
  getHTMLCourseRequest: (enrollmentId, topicId) => ({
    type: Types.GET_REQUEST,
    payload: { enrollmentId, topicId }
  }),
  getHTMLCourseSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),
  getFailure: () => ({
    type: Types.GET_FAILURE
  }),
  clearHTMLCourse: () => ({
    type: Types.CLEAR_STATE
  })
};
