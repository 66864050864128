import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  DOWNLOAD_CERTIFICADO_REQUEST:
    "treinometro-perfil-certificado/DOWNLOAD_CERTIFICADO_REQUEST",
  DOWNLOAD_CERTIFICADO_SUCCESS:
    "treinometro-perfil-certificado/DOWNLOAD_CERTIFICADO_SUCCESS",
  GET_PERFIL_CERTIFICADO_REQUEST:
    "treinometro-perfil-certificado/GET_PERFIL_CERTIFICADO_REQUEST",
  GET_PERFIL_CERTIFICADO_SUCCESS:
    "treinometro-perfil-certificado/GET_PERFIL_CERTIFICADO_SUCCESS",
  GET_REQUEST_CLEAR_ITEMS:
    "treinometro-perfil-certificado/GET_REQUEST_CLEAR_ITEMS",
  GET_CLEAR_ITEMS: "treinometro-perfil-certificado/GET_CLEAR_ITEMS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  downloading: false,
  page: 0
});

export default function certificado(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PERFIL_CERTIFICADO_REQUEST:
      return { ...state, loading: true };
    case Types.GET_PERFIL_CERTIFICADO_SUCCESS:
      return {
        ...action.payload,
        data:
          action.payload.page > 1
            ? [...state.data, ...action.payload.data]
            : action.payload.data,
        loading: false
      };
    case Types.GET_CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        page: 0
      };
    case Types.DOWNLOAD_CERTIFICADO_REQUEST:
      return {
        ...state,
        downloading: true
      };
    case Types.DOWNLOAD_CERTIFICADO_SUCCESS:
      return {
        ...state,
        downloading: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getCertificadoRequest: data => ({
    type: Types.GET_PERFIL_CERTIFICADO_REQUEST,
    payload: data
  }),

  getTreinometroPerfilCertificadoSuccess: data => ({
    type: Types.GET_PERFIL_CERTIFICADO_SUCCESS,
    payload: data
  }),

  downloadCertificadoRequest: id => ({
    type: Types.DOWNLOAD_CERTIFICADO_REQUEST,
    payload: { id }
  }),

  downloadCertificadoSuccess: data => ({
    type: Types.DOWNLOAD_CERTIFICADO_SUCCESS,
    payload: data
  }),

  getRequestClearCertificadoItems: () => ({
    type: Types.GET_REQUEST_CLEAR_ITEMS,
    payload: { area: "certificado" }
  }),

  getClearCertificadoItems: () => ({
    type: Types.GET_CLEAR_ITEMS
  })
};
