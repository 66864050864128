import React, { Component, Fragment } from 'react';

import { BannerSlide, CountGroup } from './style';

import {
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  WithStore
} from "pure-react-carousel";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";

import "pure-react-carousel/dist/react-carousel.es.css";

class Banner extends Component {
  componentDidUpdate(prevProps) {
    const { carouselStore, currentSlide, totalSlides } = this.props;
    if (prevProps.currentSlide === currentSlide) {
      if (prevProps.currentSlide === 0) {
        carouselStore.setStoreState({ currentSlide: totalSlides - 1 });
      } else {
        carouselStore.setStoreState({ currentSlide: 0 });
      }
    }
  }

  handleClick = () => {
    this.forceUpdate();
  };

  render() {
    const { slides } = this.props;

    return (
      <Fragment>
        <Slider>
          {slides.map(slide => (
            <Slide key={Math.random()}>
              <BannerSlide source={slide.source}>
                <div>
                  <h2>{slide.title}</h2>
                  <span>{slide.description}</span>
                </div>
              </BannerSlide>
            </Slide>
          ))}
        </Slider>
        <CountGroup />

        {slides.length > 1 && (
          <ButtonBack onClick={this.handleClick} disabled={false} className="btn-carousel btn-carousel-back">
            <IosArrowBack fontSize="50px" color="white" />
          </ButtonBack>
        )}

        {slides.length > 1 && (
          <ButtonNext onClick={this.handleClick} disabled={false} className="btn-carousel btn-carousel-next">
            <IosArrowForward fontSize="50px" color="white" />
          </ButtonNext>
        )}
      </Fragment>
    )
  }
}

export default WithStore(Banner, state => ({
  ...state
}));
