import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { download } from '../../../services/download';

import { Creators as SupportMaterialAcceptanceActions } from '../../ducks/materials/acceptance';
import { Creators as SupportMaterialDownloadActions } from '../../ducks/materials/download';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';

export function* postAcceptance(action) {
  try {
    const { id, file_id, filename, support_material_id  } = action.payload;

    const { data } = yield call(
      api.post,
      `/api/v1/support-materials/${id}/register-acceptance?file_id=${file_id}`,
    );

    if (data.status === "ok") {
      const { data } = yield call(
        api.get,
        `/api/v1/support-materials/${support_material_id}/download?file_id=${file_id}`,
        {
          responseType: 'blob',
        }
      );
      yield call(download, { filename, data });
      yield put(
        SupportMaterialDownloadActions.getSupportMaterialDownloadSuccess()
      );
      yield put(
        SupportMaterialAcceptanceActions.registerAcceptanceSuccess()
      );
      yield put(
        SuccessActions.setSuccess('Download realizado com sucesso', {
          toasted: true,
        })
      );
    }

  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.',
          {
            toasted: true,
          }
        )
      );
    }
  }
}
