import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import { Creators as NotificationsActions } from "../ducks/notification";
import { Creators as ErrorActions } from "../ducks/error";

export function* getNotifications() {
  try {
    const response = yield call(api.get, "/api/v1/notifications");
    yield put(NotificationsActions.getNotificationSuccess(response.data));
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter as notificações"));
  }
}

export function* removeNotificacation(action) {
  try {
    yield call(api.delete, `/api/v1/notifications/${action.payload.data.id}`);
    yield put(
      NotificationsActions.removeNotificationSuccess(action.payload.data.id)
    );
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível deletar a notificação"));
  }
}

export function* readNotificacation(action) {
  try {
    yield call(api.put, `/api/v1/notifications/${action.payload.data.id}/read`);
    yield put(
      NotificationsActions.readNotificationSuccess(action.payload.data.id)
    );
  } catch (err) {
    yield put(
      ErrorActions.setError("Não foi possível marcar como lido a notificação")
    );
  }
}
