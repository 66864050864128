import React, { Component } from "react";

import { SidenavStyled, ItemSidenav } from "./styles";
import ReactTooltip from "react-tooltip";

export default class Sidenav extends Component {
  getPrerequisite = topic => {
    if (!topic.prerequisite)
      return null

    return this.props.chapters.find(_topic => {
      return _topic.id === topic.prerequisite
    })
  }

  isVisible = topic => {
    const prerequisite = this.getPrerequisite(topic)

    if (topic.is_test && topic.completed)
      return {
        bool: false,
        message: "Você já realizou essa avaliação."
      }


    if (prerequisite) {
      const { session_status } = prerequisite;
      if (session_status !== 'passed' && session_status !== 'failed' &&  session_status !== 'completed') {
        return {
          bool: false,
          message: `É necessário visualizar a unidade "${prerequisite.name}" para prosseguir`
        }
      }
    }

    return {
      bool: true
    }
  }

  render() {
    return (
      <SidenavStyled>
        <ReactTooltip place="bottom" effect="solid" />
        <div className="sidenav-header">
          <h2>{this.props.courseTitle}</h2>
          <span>
            {" "}
            {this.props.countCompleted} de {this.props.chapters.length}{" "}
            concluído
          </span>
        </div>

        <div className="progress-wrapper">
          <ul>
            {this.props.chapters.map((chapter, i) => (
              <ItemSidenav
                data-tip={this.isVisible(chapter).message}
                key={i}
                isActive={chapter.isActive}
                completed={chapter.completed}
                visible={this.isVisible(chapter).bool}
                onClick={() => {
                  if (this.isVisible(chapter).bool)
                    this.props.topicClick(chapter);
                }}
              >
                <div className="left-side">
                  <div className="ball-wrapper">
                    <div className="ball" />
                  </div>
                  <div className="stroke" />
                </div>
                <div className="right-side">
                  <span>{chapter.name}</span>
                </div>
              </ItemSidenav>
            ))}
          </ul>
        </div>
      </SidenavStyled>
    );
  }
}
