import { combineReducers } from "redux";
import login from "./auth";
import categories from "./categories";
import category from "./category";
import courses from "./courses";
import course from "./course";
//import adminCourses from "./adminCourses";
import ratings from "./rating";
import ratingAnswer from "./ratingAnswer";
import enrollment from "./enroll";
import enrollmentCompleted from "./enrollmentCompleted";
import enrollmentInProgress from "./enrollmentInProgress";
import historico from "./historico";
import certificado from "./certificado";
import topics from "./topics";
import userInfo from "./userInfo";
import notification from "./notification";
import scorm from "./scorm";
import search from "./search";
import searchOffer from "./searchOffer";
import interests from "./interests";
import termosUso from "./termosUso";
import loginAsUser from "./admin/loginAsUser";
import { reducer as forgotPassword } from "./forgotPassword";
import { reducer as definePassword } from "./definePassword";
import fileDownload from "./fileDownload";
import supportMaterialDownload from "./materials/download";
import supportMaterials from "./materials/supportMaterials";
import acceptance from "./materials/acceptance";
import htmlCourse from "./htmlCourse";

const reducers = combineReducers({
  login,
  loginAsUser,
  categories,
  category,
  courses,
  course,
  ratings,
  ratingAnswer,
  enrollment,
  enrollmentCompleted,
  enrollmentInProgress,
  userInfo,
  historico,
  certificado,
  topics,
  notification,
  scorm,
  search,
  searchOffer,
  interests,
  termosUso,
  forgotPassword,
  definePassword,
  fileDownload,
  supportMaterials,
  supportMaterialDownload,
  acceptance,
  htmlCourse,
});

export default reducers;
