import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_PERFIL_HISTORICO_REQUEST:
    "treinometro-perfil-historico/GET_PERFIL_HISTORICO_REQUEST",
  GET_PERFIL_HISTORICO_SUCCESS:
    "treinometro-perfil-historico/GET_PERFIL_HISTORICO_SUCCESS",
  GET_REQUEST_CLEAR_ITEMS:
    "treinometro-perfil-historico/GET_REQUEST_CLEAR_ITEMS",
  GET_CLEAR_ITEMS: "treinometro-perfil-historico/GET_CLEAR_ITEMS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  page: 0
});

export default function historico(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PERFIL_HISTORICO_REQUEST:
      return { ...state, loading: true };
    case Types.GET_PERFIL_HISTORICO_SUCCESS:
      return {
        ...action.payload,
        data:
          action.payload.page > 1
            ? [...state.data, ...action.payload.data]
            : action.payload.data,
        loading: false
      };
    case Types.GET_CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        page: 0
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getHistoricoRequest: data => ({
    type: Types.GET_PERFIL_HISTORICO_REQUEST,
    payload: data
  }),

  getTreinometroPerfilHistoricoSuccess: data => ({
    type: Types.GET_PERFIL_HISTORICO_SUCCESS,
    payload: data
  }),

  getRequestClearHistoricoItems: () => ({
    type: Types.GET_REQUEST_CLEAR_ITEMS,
    payload: { area: "historico" }
  }),

  getClearHistoricoItems: () => ({
    type: Types.GET_CLEAR_ITEMS
  })
};
