import styled, {css} from 'styled-components'

import ImgSelectSeta from '../../assets/img/select-seta-down.png'

export const FormItemWrapper = styled.div`
    margin-bottom: 20px;
`

export const Input = styled.div`
    width: 100%;

    label {
        color: #686868;
        font-size: 12px;
        display: block;
    }

    input {
        border: 0;
        border-bottom: 1px solid #D8D8D8;
        width: 100%;
        padding: 10px 0;
        font-size: 18px;
        color: #535353;
        font-family: 'Futura-Bold', sans-serif;

        &[type="date"] {
          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator,
          &::-webkit-clear-button {
            display: none;
            -webkit-appearance: none;
          }
        }
    }
`

export const Select = styled.div`
    width:100%;

    label {
        color: #686868;
        font-size: 12px;
        display: block;
    }

    .selectArrowWrapper {
        display:flex;
        align-items:center;
        position:relative;
    }

    select {
        border: 0;
        border-bottom: 1px solid #D8D8D8;
        width: 100%;
        padding: 10px 0;
        font-size: 18px;
        color: #535353;
        font-family: 'Futura-Bold', sans-serif;
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        background-image: url(${ImgSelectSeta});
        background-color: transparent;
        background-repeat:no-repeat;
        background-position: right;
        background-size: 20px;
    }
`

export const SubmitButton = styled.button`
    border: 0 !important;
    width: 100%;
    padding: 15px;
    background-color: #323232;
    text-align:center;
    font-family: 'Futura-Bold', sans-serif;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    height: 64px;
    cursor: pointer;
    margin: 20px 0;

    &:hover {
        opacity: 0.8;
    }

`

export const CheckBox = styled.div`

/* The container */
.container {
  display: flex;
    align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container span:first-child {
    font-size:14px;
    color:#686868;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border:1px solid #DDD;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a white background */
.container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #0c4f92;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`

export const InputAdmin = styled.div`

  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  label {
    color: #666666;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Futura-Bold', sans-serif;
    margin-bottom: 4px;

    ${props => props.isThisLogin  && css`
      font-family: 'Futura-Medium', sans-serif;
      text-transform: uppercase;
      font-size: 16px;
    `}

  }

  input {
    height: 40px;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
`

export const ErrorMessage = styled.p`
  color: #0c4f92;
`

export const InputPhoto = styled.label`
  display: flex;
  justify-content: flex-start;
  padding: 20px;

  div {
    flex: 0.5;
    margin-right: 20px;
    font-family: 'Futura-Bold',sans-serif;
    padding: 10px 15px;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
    background-color: #ccc;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }

  span {
    flex: 0.5;
  }

  input {
    display: none !important;
  }

`;
