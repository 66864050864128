import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import ProtectedRoute from "./protected";

import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";

import Home from "../pages/Home";
import Categoria from "../pages/Categoria";
import CategoriaEmAndamento from "../pages/Categoria/InProgress";
import CategoriaConcluidos from "../pages/Categoria/Finished";
import Curso from "../pages/Curso";
import Perfil from "../pages/Perfil";
import Help from "../pages/Help";

import ConteudoRoute from "./conteudo";
import Conteudo from "../pages/Conteudo";
import LoginRoute from "./login";

import SupportMaterial from "../pages/SupportMaterial";
import SupportMaterialCategory from "../pages/SupportMaterial/SupportMaterialCategory";
import Material from "../pages/SupportMaterial/Material";

import Desempenho from "../pages/Desempenho";
import DefinirSenha from "../pages/DefinirSenha";
import TermosUso from "../pages/TermosUso";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <LoginRoute exact path="/" component={Login} />
      <LoginRoute path="/esqueci-senha" component={ForgotPassword} />
      <Route path="/alterar-senha" component={ForgotPassword} />
      <Route exact path="/definir-senha" component={DefinirSenha} />
      <ProtectedRoute exact path="/home" component={Home} />
      <ProtectedRoute
        exact
        path="/categoria/:id/concluidos"
        component={CategoriaConcluidos}
      />
      <ProtectedRoute
        exact
        path="/categoria/:id/em-andamento"
        component={CategoriaEmAndamento}
      />
      <ProtectedRoute exact path="/categoria/:id" component={Categoria} />
      <ProtectedRoute
        path="/curso/:id/matricula/:idMatricula"
        component={Curso}
      />
      <ProtectedRoute path="/curso/:id/oferta/:idOferta" component={Curso} />
      <ProtectedRoute path="/perfil" component={Perfil} />
      <ProtectedRoute path="/complemento" component={Perfil} />
      {/* <ProtectedRoute path="/sobre" component={About} /> */}
      <ProtectedRoute path="/ajuda" component={Help} />
      <ProtectedRoute exact path="/desempenho" component={Desempenho} />
      <ProtectedRoute exact path="/termos-uso" component={TermosUso} />

      <ConteudoRoute
        path="/conteudo/:id/oferta/:idOferta/matricula/:idMatricula/topico/:idTopico/rating/:idRating/rever/:reverCurso"
        component={Conteudo}
      />

      <ProtectedRoute
        exact
        path="/documentos-corporativos"
        component={SupportMaterial}
      />
      <ProtectedRoute
        exact
        path="/documentos-corporativos/categoria/:id"
        component={SupportMaterialCategory}
      />
       <ProtectedRoute
        path="/documentos-corporativos/categoria/:idCategoria/material/:id"
        component={Material}
      />
    </Switch>
  </BrowserRouter>
);

export default Router;
