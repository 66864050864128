import React, { Component } from "react";
import { LoginWrapper, LeftSide, RightSide } from "../Login/styles";
import { CommonTextWrapper, RedButton } from "../../styles";
import LogoDp from "../../assets/img/dp-logotipo.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPasswordActions from "../../store/ducks/forgotPassword";

import Loader from "../Login/components/Loader";

import { FormInput, FormSubmitButton } from "../../components/Form";
import { FormItemWrapper } from "../../components/Form/styles";

class ForgotPassword extends Component {
  handleRedirect() {
    return (window.location.href = "/");
  }

  static propTypes = {
    forgotPasswordRequest: PropTypes.func.isRequired
  };

  state = {
    success: false,
    username: "",
    forgot: true
  };

  componentDidMount() {
    this.setState({
      forgot: this.props.match.path === "/esqueci-senha"
    });
  }

  handleSubmitForm = e => {
    e.preventDefault();
    const { username } = this.state;
    if (!!username && username !== "") {
      const { forgotPasswordRequest } = this.props;
      this.setState({ success: false });
      forgotPasswordRequest(username);
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isLoading = () => {
    return this.props.forgotPassword.loading;
  };

  renderError = () => {
    if (!this.props.forgotPassword.error) {
      return null;
    }
    return (
      <h2 style={{ color: "#ec0606", fontSize: "15px", margin: "5px" }}>
        {this.props.forgotPassword.error}
      </h2>
    );
  };

  renderLoading = () => {
    if (!this.props.forgotPassword.loading) {
      return null;
    }
    return (
      <h2 style={{ fontSize: "15px", margin: "5px" }}>
        Carregando recuperação de senha...
      </h2>
    );
  };

  renderSuccess = () => {
    if (!this.props.forgotPassword.success) {
      return null;
    }
    return (
      <h2 style={{ color: "#14d448", fontSize: "15px", margin: "5px" }}>
        Nova senha será enviada ao seu e-mail.
      </h2>
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.forgotPassword.success && !state.success) {
      state.username = "";
      state.success = true;
    }
    return state;
  }

  render() {
    return (
      <LoginWrapper>
        <LeftSide
          style={{
            alignItems: "unset",
            paddingTop: "40px",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <CommonTextWrapper>
            <h1>{this.state.forgot ? "Recuperar Senha" : "Alterar Senha"}</h1>
            <div style={{ maxHeight: "500px" }}>
              <form onSubmit={this.handleSubmitForm}>
                {this.renderError()}
                {this.renderLoading()}
                {this.renderSuccess()}
                <FormItemWrapper>
                  <FormInput
                    label="E-mail ou Matrícula"
                    placeholder={""}
                    name="username"
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </FormItemWrapper>
                <FormSubmitButton
                  disabled={this.isLoading()}
                  label={
                    this.isLoading() ? (
                      <Loader />
                    ) : this.state.forgot ? (
                      "Recuperar Senha"
                    ) : (
                      "Alterar Senha"
                    )
                  }
                />
              </form>
            </div>
          </CommonTextWrapper>

          <RedButton
            style={{
              alignSelf: "center",
              padding: "15px 50px",
              marginTop: "20px",
              marginBottom: "20px"
            }}
            onClick={this.handleRedirect}
          >
            {this.state.forgot ? "Voltar para o login" : "Voltar"}
          </RedButton>

          <a
            href="https://portugues.digitalpages.com.br"
            style={{ alignSelf: "center" }}
          >
            <img src={LogoDp} alt="Digital Pages" width="100" />
          </a>
        </LeftSide>
        <RightSide></RightSide>
      </LoginWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ForgotPasswordActions }, dispatch);

const mapStateToProps = state => ({ forgotPassword: state.forgotPassword });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
