import { call, put } from "redux-saga/effects";
import jwtDecode from "jwt-decode";

import api from "../../../services/api";
import { getUserInfo } from "../userInfo";

import { Creators as Actions } from "../../ducks/admin/loginAsUser";
import { Creators as LoginActions } from "../../ducks/auth";

export function* loginAsUser(action) {
  let { username } = action.payload;
  let url = `/api/v1/admin/access-token?username=${username}`;
  try {
    const response = yield call(api.get, url);
    const { token, refreshToken } = response.data;

    const userId = jwtDecode(token);

    let adminToken = localStorage.getItem("@emae-cursos:token");
    let adminRefreshToken = localStorage.getItem("@emae-cursos:refreshToken");

    localStorage.setItem("@emae-cursos:admin-token", adminToken);
    localStorage.setItem("@emae-cursos:admin-refreshToken", adminRefreshToken);
    localStorage.setItem("@emae-cursos:token", token);
    localStorage.setItem("@emae-cursos:refreshToken", refreshToken);

    yield put(LoginActions.getLoginSuccess({ ...response.data, user_id: userId.uid }));
    yield* getUserInfo();

    yield put(Actions.loginAsUserRequestSuccess(token, refreshToken));

  } catch (err) {
    if (err.response.status === 400) {
      let data = err.response.data;
      let error = err.response.data.messages[0];
      if (data.details instanceof Array) {
        let userNotFound = data.details.filter(error => error.title === 'exists' && error.source.pointer === 'username').length > 0;
        if (userNotFound) {
          error = "Usuário não encontrado";
        }
      }
      yield put(Actions.loginAsUserRequestError(error));
    } else {
      yield put(Actions.loginAsUserRequestError("Ocorreu um erro na requisição, tente novamente."));
    }
  }
}
