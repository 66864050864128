import React, { Component } from "react";
import {
  Select,
  Input,
  CheckBox,
  SubmitButton,
  InputAdmin,
  ErrorMessage,
  InputPhoto
} from "./styles";

import InputMask from "react-input-mask";

export class FormSelect extends Component {
  render() {
    const { options, label, id, name, onChange, value } = this.props;
    return (
      <Select>
        <label htmlFor={id}>{label}</label>
        <div className="selectArrowWrapper" onClick={this.handleOpenSelect}>
          <select id={id} name={name} value={value} onChange={onChange}>
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.nome}
              </option>
            ))}
          </select>
        </div>
      </Select>
    );
  }
}

export class FormInput extends Component {
  render() {
    const {
      label,
      type,
      id,
      name,
      value,
      onChange,
      max,
      placeholder = null,
      disabled
    } = this.props;
    return (
      <Input>
        <label htmlFor={id}>{label}</label>
        <input
          max={max}
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Input>
    );
  }
}

export class FormInputMask extends Component {
  render() {
    const { label, type, id, name, value, onChange, mask, maskChar, placeholder } = this.props;
    return (
      <Input>
        <label htmlFor={id}>{label}</label>
        <InputMask
          placeholder={placeholder}
          mask={mask}
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          name={name}
          maskChar={maskChar !== null ? maskChar : null}
        />
      </Input>
    );
  }
}

export class FormCheckBox extends Component {
  render() {
    const { label, onChange, data, defaultChecked = true } = this.props;
    return (
      <CheckBox>
        <label className="container">
          {label && <span>{label}</span>}
          <input {...data} onChange={onChange} type="checkbox" defaultChecked={defaultChecked} />
          <span className="checkmark" />
        </label>
      </CheckBox>
    );
  }
}

export class FormSubmitButton extends Component {
  render() {
    const { label } = this.props;

    return <SubmitButton type="submit">{label}</SubmitButton>;
  }
}

export class FormInputAdmin extends Component {
  render() {
    const {
      label,
      type,
      placeholder,
      id,
      name,
      onChange,
      isLoginField
    } = this.props;
    const isThisLogin = isLoginField === true ? isLoginField : false;
    return (
      <InputAdmin isThisLogin={isThisLogin}>
        <label htmlFor={id}>{label}</label>
        <input
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          name={name}
        />
      </InputAdmin>
    );
  }
}

export class FormError extends Component {
  render() {
    const { message } = this.props;

    return <ErrorMessage>{message}</ErrorMessage>;
  }
}

export class FormInputPhoto extends Component {
  render() {
    return (
      <InputPhoto>
        <div>Escolher o arquivo</div>
        <span>{this.props.filename}</span>
        <input type='file' onChange={this.props.onChange} accept='image/jpg,image/jpeg,image/png' />
      </InputPhoto>
    )
  }
}
