import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import IosSearch from "react-ionicons/lib/IosSearch";
import IosArrowRoundForward from "react-ionicons/lib/IosArrowRoundForward";

import { Creators as CategoriesActions } from "../../store/ducks/categories";
import { Creators as SearchActions } from "../../store/ducks/search";
import { Creators as UserInfoActions } from "../../store/ducks/userInfo";

import {
  SearchAndViewWrapper,
  SearchWrapper,
  Categorias,
  Content
} from "./styles";
import { Carousel } from "./components/Banner/style";

import { ToastContainer } from "react-toastify";

import Categoria from "./categoria";
import Preloader from "../../components/Preloader";
import Shake from "../../components/Shake";
import Banner from "./components/Banner";
import ReactTooltip from "react-tooltip";

import ImgBannerApp from "../../assets/img/banner-home-emae.jpg";

import "video-react/dist/video-react.css";
import { Player } from "video-react";
import Modal from "react-responsive-modal";
import { previewVideoURL } from "../../config";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      shake: false,
      modalOpened: false,
      modalVideoVisible: false
    };
  }

  handleSearchInput(e) {
    if (e.target.value.length) {
      this.props.getSearchRequest(e.target.value);
      this.setState({ search: true });
    } else {
      this.setState({ search: false });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.userInfo.data.first_access &&
      !state.modalVideoVisible &&
      !state.modalOpened
    ) {
      state.modalVideoVisible = true;
      state.modalOpened = true;

      props.updateUserInfoRequest({
        id: props.userInfo.data.id,
        first_access: false,
        notify: false
      });
    }

    return state;
  }

  componentDidMount() {
    const { getCategoriesRequest } = this.props;
    getCategoriesRequest();
  }

  closeModalVideo = () => {
    this.setState({
      modalVideoVisible: false
    });
  };

  renderPreviewVideo() {
    return (
      <Modal
        open={this.state.modalVideoVisible}
        onClose={this.closeModalVideo}
        showCloseIcon={true}
        styles={{
          modal: { background: "transparent", width: "80%", boxShadow: "none" }
        }}
      >
        <Player playsInline src={previewVideoURL} />
      </Modal>
    );
  }

  render() {
    const slides = [
      {
        source: ImgBannerApp,
        title: "EMAE",
        description: "Portal de Treinamentos"
      }
    ];

    let { categories, search } = this.props;

    if (this.state.search) {
      categories = search;
    }

    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        <ReactTooltip place="bottom" effect="solid" />
        <Carousel
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={slides.length}
          style={{
            width: "100%",
            overflowY: "hidden",
            position: "relative",
            overflowX: "hidden"
          }}
          isPlaying={true}
        >
          <Banner slides={slides} />
        </Carousel>
        <Content>
          <SearchAndViewWrapper>
            <SearchWrapper>
              <div>
                <IosSearch color="#939393" fontSize="24px" />
              </div>
              <input
                type="text"
                placeholder="Buscar treinamentos ou categorias"
                onChange={e => this.handleSearchInput(e)}
              />
              <div>
                <a href="#!">
                  <IosArrowRoundForward color="#939393" fontSize="38px" />
                </a>
              </div>
            </SearchWrapper>
          </SearchAndViewWrapper>

          <Shake shake={this.state.shake}>
            <Categorias>
              {!categories.loading &&
                categories.data.length === 0 &&
                "Nenhuma categoria ou curso encontrado."}
              <ul>
                {categories.loading || search.loading ? (
                  <Preloader />
                ) : (
                  categories.data.map((category, key) => (
                    <Categoria
                      icon={category.icon}
                      titulo={category.title}
                      qtdNovosCursos={category.count_new_courses}
                      qtdCursos={category.count_courses}
                      qtdCursosAndamento={category.count_courses_in_progress}
                      cor={category.color}
                      key={key}
                      id={category.id}
                      type={this.state.search ? category.type : "category"}
                      oferta={category.offer_id}
                      idMatricula={category.enrollments_id}
                      statusMatricula={category.enrollments_status}
                    />
                  ))
                )}
              </ul>
            </Categorias>
          </Shake>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  search: state.search,
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...CategoriesActions, ...SearchActions, ...UserInfoActions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
