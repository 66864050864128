import React, { Component } from "react";
import { HeaderStyle } from "./styles";

import Logo from "../../../../assets/img/emaeBranco.png";
import MenuConteudoIcon from "../../../../assets/img/menu-conteudo-icon.svg";
import MenuConteudoIconSelected from "../../../../assets/img/menu-conteudo-icon-selected.svg";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS } from "../../../../analytics";

export default class Header extends Component {
  handleClickMenu = () => {
    this.props.toggleSidenav();
  };

  handleBackButton = () => {
    const { backRoute, history } = this.props;
    AnalyticsEvent({
      category: GA_CATEGORIES.CONTENT,
      action: GA_ACTIONS.CLICKED_BACK_BUTTON,
    })
    history.push(backRoute)
  }


  render() {
    const { isSidenavOpen } = this.props;
    return (
      <HeaderStyle>
        <div className="left-content">
          <a href='#!' onClick={this.handleBackButton}>
            <IosArrowBack fontSize="20px" color="#fff" />
          </a>
          <img
            src={!isSidenavOpen ? MenuConteudoIcon : MenuConteudoIconSelected}
            onClick={this.handleClickMenu}
            alt="menu"
          />
        </div>

        <div className="right-content">
          <img src={Logo} alt="logo" />
        </div>
      </HeaderStyle>
    );
  }
}
