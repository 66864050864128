import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as TreinometrohistoricoActions } from "../../store/ducks/historico";
import { Creators as TreinometrocertificadoActions } from "../../store/ducks/certificado";

import {
  Header,
  HeaderInfo,
  HeaderInfoInner,
  Content,
  HeaderBtns
} from "../../styles";

import {
  ListAndProfileInfo
} from "./styles";

import CertificadoList from "./components/CertificadoList";
import HistoricoList from "./components/HistoricoList";
import moment from "moment";

class TreinometroPerfil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        date: moment().format("YYYY-MM-DD"),
        period: "monthly",
        userId: null,
        order: "desc",
        page: 1
      },
      isShowHistorico: true,
      rankings: [],
      certificados: [],
      historico: []
    };
  }

  fetchHistorico(historico) {
    let historicos = [];
    historico.data.forEach(item => {
      historicos.push({
        titulo: item.text,
        nota: item.rate,
        data: moment(item.created_at).format("DD/MM/YYYY"),
      });
    });

    this.setState({ historico: historicos });
  }

  fetchCertificados(certificado) {
    let certificados = [];
    certificado.data.forEach(item => {
      let existe = false;
      certificados.forEach(cert => {
        if (cert.categoria && cert.categoria === item.categories_title) {
          existe = true;
        }
      });

      if (!existe) {
        certificados.push({
          categoria: item.categories_title,
          id: item.id,
          certificados: [
            {
              id: item.id,
              nome: item.course_title,
              conclusao: moment(item.created_at).format("DD/MM/YYYY"),
              nota: item.record,
              validade: moment(item.expiration_date).format("DD/MM/YYYY")
            }
          ]
        });
      }

      if (existe) {
        certificados.forEach(cert => {
          if (cert.categoria === item.categories_title) {
            cert.certificados.push({
              id: item.id,
              nome: item.course_title,
              conclusao: moment(item.created_at).format("DD/MM/YYYY"),
              nota: item.record,
              validade: moment(item.expiration_date).format("DD/MM/YYYY")
            });
          }
        });
        existe = false;
      }
    });

    this.setState({ certificados });
  }

  loadScreen = () => {
    let userId;
    if (this.props.match.params.id === undefined) {
      userId = JSON.parse(localStorage.getItem("@emae-cursos:userInfo")).id;
    } else {
      userId = this.props.match.params.id;
    }

    this.props.getHistoricoRequest({ userId });
    this.props.getCertificadoRequest({ userId });
  };

  handleChangeShowData = async tipo => {
    let userId;
    if (this.props.match.params.id === undefined) {
      userId = JSON.parse(localStorage.getItem("@emae-cursos:userInfo")).id;
    } else {
      userId = this.props.match.params.id;
    }

    const { isShowHistorico } = this.state;
    if (tipo === "historico") {
      if (isShowHistorico) return false;
      await this.props.getHistoricoRequest({ userId });
      this.setState({ isShowHistorico: true });
    } else {
      if (!isShowHistorico) return false;
      await this.props.getCertificadoRequest({ userId });
      this.setState({ isShowHistorico: false });
    }
  };

  componentDidMount() {
    this.loadScreen(this.state.filter);
  }

  componentDidUpdate(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.loadScreen(this.state.filter);
    }
  }

  componentWillReceiveProps(props) {
    this.fetchCertificados(props.certificado);
  }

  render() {
    const { isShowHistorico, certificados } = this.state;
    return (
      <Fragment>
        <HeaderInfo>
          <HeaderInfoInner>
            <Header>
              <HeaderBtns>
                <a
                  href="#!"
                  onClick={() => this.handleChangeShowData("historico")}
                  style={
                    isShowHistorico ? { color: "#f9283d" } : { color: "#fff" }
                  }
                >
                  Histórico
                </a>
                <a
                  href="#!"
                  onClick={() => this.handleChangeShowData("certificados")}
                  style={
                    !isShowHistorico ? { color: "#f9283d" } : { color: "#fff" }
                  }
                >
                  Certificados
                </a>
              </HeaderBtns>
            </Header>
          </HeaderInfoInner>
        </HeaderInfo>
        <Content>
          <ListAndProfileInfo>
            {isShowHistorico ? (
              <HistoricoList historico={this.props.historico.data} />
            ) : (
              <CertificadoList certificados={certificados} />
            )}
          </ListAndProfileInfo>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  historico: state.historico,
  certificado: state.certificado
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TreinometrohistoricoActions,
      ...TreinometrocertificadoActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreinometroPerfil);
