import { call, put } from "redux-saga/effects";
import { apiQuiz } from "../../services/api";
import DefinePasswordActions from "../ducks/definePassword";
import { parseErrorResponse } from "../../utils/FormErrorResponse";

export function* definePassword({ token, password }) {
  yield put(DefinePasswordActions.definePasswordClearError());
  yield put(DefinePasswordActions.definePasswordLoading());
  try {
    yield call(apiQuiz.put, "api/v1/change-password", {
      token,
      password
    });
    yield put(DefinePasswordActions.definePasswordSuccess());
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let error = parseErrorResponse(request);
        return yield put(DefinePasswordActions.definePasswordError(error));
      }
    }
    yield put(
      DefinePasswordActions.definePasswordError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
