import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'register-acceptance/GET_REQUEST',
  GET_SUCCESS: 'register-acceptance/GET_SUCCESS',
  CALL_REFRESH: 'register-acceptance/CALL_REFRESH',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false
});

export default function acceptance(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return { ...state, loading: false, success: true };
    case Types.CALL_REFRESH:
      return { data: {}, loading: false, success: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postRegisterAcceptanceRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  registerAcceptanceSuccess: () => ({
    type: Types.GET_SUCCESS,
  }),

  resetAcceptance: () => ({
    type: Types.CALL_REFRESH,
  }),

};
