import React from "react";

import { List, ItemLeftSide, ItemInnerLeftSide, ItemRightSide } from "./styles";
import moment from "moment";

export default function HistoricoList(props) {
  const { historico } = props;
  return (
    <List>
      {historico.length === 0 && "Nenhum histórico encontrado."}
      {historico.map(item => (
        <li key={item.id}>
          <ItemLeftSide>
            <ItemInnerLeftSide>
              <span className="title notranslate">{item.text}</span>
              {item.rate !== null && <span className="nota">Nota: {item.rate}</span>}
            </ItemInnerLeftSide>
          </ItemLeftSide>
          <ItemRightSide>
            <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>
          </ItemRightSide>
        </li>
      ))}
    </List>
  );
}
