import api from "../../services/api";
import { download } from "../../services/download";
import { call, put } from "redux-saga/effects";

import { Creators as FileDownloadActions } from "../ducks/fileDownload";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as SuccessActions } from "../ducks/success";

export function* fileDownload(action) {
  try {
    const res = yield call(api.get, `/api/v1/files/${action.payload.id}`, {
      responseType: "blob"
    });

    const filename =
      action.payload.fileName ||
      `arquivo.${String(res.headers["content-type"]).split("/")[1]}`;

    yield call(download, { filename, data: res.data });

    if (action.payload.course_file_id && action.payload.user_id) {
      yield call(api.post, '/api/v1/course-file-logs/', {
        user_id: action.payload.user_id,
        course_file_id: action.payload.course_file_id
      });
    }

    yield put(FileDownloadActions.getFileDownloadSuccess());
    yield put(
      SuccessActions.setSuccess("Download realizado com sucesso", {
        toasted: true
      })
    );
  } catch (err) {
    yield put(FileDownloadActions.getFileDownloadFailure());
    yield put(
      ErrorActions.setError("Não foi possível fazer download do arquivo", {
        toasted: true
      })
    );
  }
}
