import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  POST_REQUEST: 'interests/POST_REQUEST',
  POST_SUCCESS: 'interests/POST_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
});

export default function interests(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true };
    case Types.POST_SUCCESS:
      return { ...state, loading: false };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postInterestsRequest: payload => ({
    type: Types.POST_REQUEST,
    payload
  }),

  postInterestsSuccess: () => ({
    type: Types.POST_SUCCESS
  }),
};
