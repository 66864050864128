import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as SupportMaterialsActions } from '../../ducks/materials/supportMaterials';
import { Creators as ErrorActions } from '../../ducks/error';

export function* getSupportMaterial(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/support-materials/${action.payload.id}`
    );

    yield put(
      SupportMaterialsActions.getSupportMaterialsSuccess(response.data.details)
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* getSupportMaterialsFiles(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/support-materials/categories/${action.payload.categoryId}`
    );

    yield put(
      SupportMaterialsActions.getSupportMaterialsFilesSuccess(
        response.data.details
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* getSupportMaterialsCategories() {
  try {
    const response = yield call(
      api.get,
      '/api/v1/support-materials/categories?paginate=false'
    );

    yield put(
      SupportMaterialsActions.getSupportMaterialsCategoriesSuccess(
        {data: response.data.details}
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
