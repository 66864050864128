import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as AuthActions } from "./../../store/ducks/auth";
import { Creators as UserInfoActions } from "./../../store/ducks/userInfo";
import Loading from "../../components/Loading";
import Routes from "./../../routes/routes";

class LoadingApp extends Component {
  static propTypes = {
    loadLocalUser: PropTypes.func.isRequired
    //signInClearError: PropTypes.func.isRequired
  };

  state = { load: false, fetch: true };

  componentDidMount() {
    this.props.loadLocalUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.logged !== null) {
      if (this.props.auth.logged === true && this.props.fetch) {
        this.setState({ fetch: false });
      }
    }
    if (
      this.props.auth.logged === false ||
      !!this.props.userInfo.success ||
      !!this.props.userInfo.error
    ) {
      this.setState({ load: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.userInfo.data instanceof Object &&
      this.props.userInfo.data.id !== nextProps.userInfo.data.id
    ) {
      return true;
    }
    return !this.state.load;
  }

  render() {
    if (this.state.load) {
      return <Routes />;
    }
    return <Loading loading={true} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions, ...UserInfoActions }, dispatch);

const mapStateToProps = state => ({
  auth: state.login,
  userInfo: state.userInfo
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingApp);
