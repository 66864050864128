import styled, { css } from 'styled-components';

export const CategoriaStyle = styled.li`
  width: 100%;
  height: 70px;
  border-left: 2px solid
    ${props =>
      props.cor
        ? css`
            ${props.cor}
          `
        : '#dd1d21'};
  margin-bottom: 2px;
  overflow: hidden;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    :hover {
      background-color: #dfdfdf;
    }

    > div {
      display: flex;
      align-items: center;
    }
    .tituloCategoria {
      font-size: 24px;
      font-family: 'Futura-Bold', sans-serif;
      color: #535353;
    }

    .badges {
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .badge {
      color: #dd1d21;
      font-size: 14px;
      font-family: 'Futura-Bold', sans-serif;

      &.grey {
        color: #535353;
      }
    }
  }

  ${props =>
    props.isViewGrid &&
    css`
            border-left: 0;
            margin-right: 2px;
            height: auto;
            flex: 1;
            min-width: 200px;

            div {
                border-bottom: 1px solid ${props =>
                  props.cor &&
                  css`
                    ${props.cor}
                  `}
                margin-bottom:10px;
            }
            a {
                flex-direction: column;
                padding: 30px;

                    img {
                        width: 48px;
                        height: 60px;
                        padding-bottom: 10px;
                    }

                    .tituloCategoria {
                        margin-left: 0
                        font-size: 1em;
                        text-align: center;
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }
            }
    `}
`;
