import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as CategoryActions } from '../../../store/ducks/category';
import { Creators as SupportMaterialsActions } from '../../../store/ducks/materials/supportMaterials';
import { Creators as SupportMaterialDownloadActions } from '../../../store/ducks/materials/download';

import MaterialHeader from '../components/MaterialHeader';
import Preloader from '../../../components/Preloader';
import SupportMaterialFile from '../components/SupportMaterialFile';

import { Content } from '../../../styles';
import {
  Counter,
  MaterialContent,
  SupportMaterialWrapperStyled,
  MaterialDadosWrapper,
  MaterialDados,
} from './styles';

export class Material extends Component {

  state = {
    open: false
  }

  componentDidMount() {
    const { id, idCategoria } = this.props.match.params;
    this.props.clearSupportMaterials();
    this.props.clearSupportMaterialDownload();
    this.props.getSupportMaterialsRequest({ id });
    this.props.getCategoryRequest(idCategoria);
  }

  refreshMaterials() {
    const { id } = this.props.match.params;
    this.props.clearSupportMaterials();
    this.props.clearSupportMaterialDownload();
    this.props.getSupportMaterialsRequest({ id });
  }

  getFilesSize = supportMaterials => {
    if (
      supportMaterials &&
      supportMaterials.data &&
      supportMaterials.data.files_size
    ) {
      const sum = supportMaterials.data.files_size;

      if (sum.type === 'bytes') {
        const value = (Number(sum.value) / 1024).toFixed(2);
        return `${value} MB`;
      }

      return `${sum.value} MB`;
    }
    return '0 MB';
  };

  render() {
    const { category, supportMaterials } = this.props;

    const downloadsCount =
      supportMaterials.success &&
      supportMaterials.data &&
      supportMaterials.data.__meta__
        ? supportMaterials.data.__meta__.count_downloads
        : '0';

    const materials =
      supportMaterials.data && supportMaterials.data.files
        ? supportMaterials.data.files
        : [];

    if (Array.isArray(supportMaterials.data)) {
      return (
        <Content>
          <Preloader />
        </Content>
      );
    }

    return (
      <Fragment>
        {!supportMaterials.loading && (
          <Fragment>
            <MaterialHeader
              category={category.data}
              material={supportMaterials.data}
            />
            <MaterialDadosWrapper>
              <MaterialDados>
                <div>
                  <span>Tamanho total dos arquivos</span>
                  <span>{this.getFilesSize(supportMaterials)}</span>
                </div>
                {/* <div>
                  <span>Downloads totais</span>
                  <span>{downloadsCount}</span>
                </div> */}
              </MaterialDados>
            </MaterialDadosWrapper>
          </Fragment>
        )}
        <Content style={{ paddingTop: 30 }}>
          {supportMaterials.loading ? (
            <Preloader />
          ) : (
            <Fragment>
              <Counter>
                <p>
                  {materials.length === 1
                    ? '1 material de apoio disponível para download'
                    : `${materials.length} materiais de apoio disponíveis para download`}
                </p>
              </Counter>
              <MaterialContent>
                {materials.length > 0 && (
                  <SupportMaterialWrapperStyled>
                    {materials.map(item => (
                      <SupportMaterialFile
                        key={item.id}
                        item={item}
                        supportMaterials={supportMaterials}
                        refresh={() => this.refreshMaterials()}
                      />
                    ))}
                  </SupportMaterialWrapperStyled>
                )}
              </MaterialContent>
            </Fragment>
          )}
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  category: state.category,
  supportMaterials: state.supportMaterials,
  supportMaterialDownload: state.supportMaterialDownload,
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
  {
    ...CategoryActions,
    ...SupportMaterialsActions,
    ...SupportMaterialDownloadActions,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Material);
