import styled from "styled-components";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 2;

  li {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 3px;
    padding: 15px;
    position: relative;
  }

  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

export const ItemLeftSide = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;

  img {
    background-color: #fff;
  }
`;

export const ItemInnerLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .title {
    font-size: 16px;
    color: #686868;
    font-weight: bold;
  }

  .nota {
    font-size: 12px;
    color: #b0b0b0;
    font-weight: bold;
  }
`;

export const ItemRightSide = styled.div`
  span {
    font-size: 12px;
    color: #b0b0b0;
  }
`;
