import React, { Component } from "react";

import MdStarOutline from "react-ionicons/lib/MdStarOutline";
import MdStar from "react-ionicons/lib/MdStar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as RatingAnswerActions } from "../../../../store/ducks/ratingAnswer";

class RatingModal extends Component {
  state = {
    stars: ["empty", "empty", "empty", "empty", "empty"],
    rate: ""
  };

  handleClickStar = i => {
    let chosen = i + 1;
    let newStars = [];
    for (let j = 0; j < chosen; j++) {
      newStars.push("filled");
    }

    for (let k = 0; k < 5 - chosen; k++) {
      newStars.push("empty");
    }
    this.props.setRatingValue(chosen);
    this.setState({
      stars: newStars,
      rate: chosen
    });
    this.props.updateStars(chosen);
  };

  render() {
    const { stars } = this.state;
    return (
      <div style={{ display: "flex" }}>
        {stars.map((star, i) => (
          <div
            key={i}
            style={{ cursor: "pointer" }}
            onClick={() => this.handleClickStar(i)}
          >
            {star === "empty" ? (
              <MdStarOutline fontSize="26px" color="#b11b21" />
            ) : (
              <MdStar fontSize="26px" color="#b11b21" />
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ratingAnswer: state.ratingAnswer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RatingAnswerActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RatingModal);
