import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IosArchiveOutline from "react-ionicons/lib/IosArchiveOutline";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import { NotificationBoxStyled } from "./styles";

import Notification from '../Notification'

import { Creators as NotificationsActions } from "../../../../store/ducks/notification";

class NotificationBox extends Component {
  state = {
    archived: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === true && this.props.visible === false) {
      this.props.notifications
        .filter(item => item.read === false && item.active === true)
        .forEach(item => {
          const { id } = item;
          this.props.readNotificationRequest({id})
        })
    }
  }

  toggleArchived = e => {
    this.props.setTogglingNotifications(() => {
      this.setState({
        archived: !this.state.archived
      })
    });
  }

  renderCurrent = () => {
    const notsNew = this.props.notifications.filter(item => {
      return item.read === false && item.active === true
    })

    const notsOld = this.props.notifications.filter(item => {
      return item.read === true && item.active === true
    })

    return (
      <Fragment>
        <div className="list-wrapper">
          <div className="archive-btn" onClick={this.toggleArchived}>
            <IosArchiveOutline fontSize="32px" color="#686868" />
          </div>
          <span className="list-header">Novas</span>
          {
            notsNew.length > 0 ?
              <ul className="list-notification">
                {notsNew.map((not, i) => (
                  <Notification not={not} key={i} read={not.read} active={not.active} onClick={this.props.handleRemoveNotification} />
                ))}
              </ul>
              :
              <span className='empty-list'>Nenhuma notificação</span>
          }

        </div>
        <div className="list-wrapper">
          <span className="list-header">Anteriores</span>
          {
            notsOld.length > 0 ?
              <ul className="list-notification">
                {notsOld.map((not, i) => (
                  <Notification not={not} key={i} onClick={this.props.handleRemoveNotification} />
                ))}
              </ul>
              :
              <span className='empty-list'>Nenhuma notificação</span>
          }
        </div>
      </Fragment>
    );
  }

  renderArchived = () => {
    const nots = this.props.notifications.filter(item => item.active === false)

    return (
      <div className="list-wrapper">
        <span className="list-header">
          <div className="back-btn" onClick={this.toggleArchived}>
            <MdArrowBack fontSize="24px" color="#686868" />
          </div>
          <span className="archive-text">Arquivadas</span>
        </span>
        {
          nots.length > 0 ?
            <ul className="list-notification">
              {nots.map((not, i) => (
                <Notification not={not} key={i} read={not.read} active={not.active} />
              ))}
            </ul>
            :
            <span className='empty-list'>Nenhuma notificação</span>
        }

      </div>
    );
  }

  render() {
    return (
      <NotificationBoxStyled visible={this.props.visible} ref={ref => this.ref = ref}>
        {this.state.archived && this.renderArchived()}
        {!this.state.archived && this.renderCurrent()}
      </NotificationBoxStyled>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...NotificationsActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
  null,
  {forwardRef : true}
)(NotificationBox);
