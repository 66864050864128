import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as TreinometroPerfilHistoricoActions } from "../ducks/historico";
import { Creators as ErrorActions } from "../ducks/error";

export function* getTreinometroPerfilHistorico(action) {
  try {
    let uri = `/api/v1/histories?user_id=${action.payload.userId}`;
    uri += `&date=${action.payload.date}`;
    uri += `&period=${action.payload.period}`;
    uri += `&page=${action.payload.page}`;
    const response = yield call(api.get, uri);

    yield put(
      TreinometroPerfilHistoricoActions.getTreinometroPerfilHistoricoSuccess(
        response.data
      )
    );
  } catch (err) {
    yield put(ErrorActions.setError(err));
  }
}
