import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "user-info/GET_REQUEST",
  GET_SUCCESS: "user-info/GET_SUCCESS",
  UPDATE_REQUEST: "user-info/UPDATE_REQUEST",
  UPDATE_SUCCESS: "user-info/UPDATE_SUCCESS",
  UPDATE_IMAGE_REQUEST: "user-info/UPDATE_IMAGE_REQUEST",
  UPDATE_IMAGE_SUCCESS: "user-info/UPDATE_IMAGE_SUCCESS",
  GET_ERROR: "user-info/GET_ERROR",
  RESET: "user-info/RESET"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  error: false,
  updated: false
});

export default function userInfo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        updated: false
      };
    case Types.GET_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        updated: false
      };
    case Types.GET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        updated: false
      };

    case Types.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        updated: false
      };

    case Types.UPDATE_SUCCESS:
      return {
        data: { ...state.data, ...action.payload },
        loading: false,
        success: true,
        error: false,
        updated: true
      };

    case Types.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        success: false,
        error: false,
        updated: false
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getUserInfoRequest: () => ({
    type: Types.GET_REQUEST
  }),

  getUserInfoSuccess: payload => ({
    type: Types.GET_SUCCESS,
    payload
  }),

  updateUserInfoRequest: payload => ({
    type: Types.UPDATE_REQUEST,
    payload
  }),

  updateUserInfoSuccess: payload => ({
    type: Types.UPDATE_SUCCESS,
    payload
  }),

  updateUserImageRequest: payload => ({
    type: Types.UPDATE_IMAGE_REQUEST,
    payload
  }),

  updateUserImageSuccess: payload => ({
    type: Types.UPDATE_IMAGE_SUCCESS,
    payload
  }),

  getUserInfoError: () => ({
    type: Types.GET_ERROR
  }),

  userInfoReset: () => ({
    type: Types.RESET
  })
};
