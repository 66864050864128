import React from 'react';
import { Depoimento } from './styles';

import RatingStars from '../../components/RatingStars';
import Photo from '../../components/Photo';

export default class DepoimentoItem extends React.Component {
  renderPhoto = () => {
    return <Photo fileId={this.props.fileId} defaultPhoto={this.props.defaultImg} />
  }

  render() {
    let props = this.props
    return (
      <Depoimento>
        <div className='img-wrapper'>
          {this.renderPhoto()}
        </div>

        <div className='info-wrapper'>
          <div>
            <RatingStars rating={props.rating} color='#fbce07' fontSize='18px' />
          </div>
          <p>
            {props.comment}
          </p>

          <span className='nome'>{props.name}</span>
          <span className='estado'>{props.estado}</span>
        </div>

      </Depoimento>
    )
  }
}
