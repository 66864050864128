export const parseErrorResponse = request => {
  let response = JSON.parse(request.response);
  let error = { title: response.messages[0] };
  if (response.details instanceof Array) {
    let formErrors = {};
    for (let i = 0; i < response.details.length; i++) {
      let formError = response.details[i];
      formErrors[formError.source.pointer] = formError.detail;
    }
    error.formErrors = formErrors;
  }
  return error;
};
