import styled from "styled-components";

export const TermosUsoStyled = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 800px;

  .termo-box {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
    font-family: "Arial", sans-serif;
  }

  .control-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;


    .container {
      span:first-child {
        margin-top: 3px;
      }
    }

    > button {
      color: #fff;
      border: 0;
      background-color: #0E65BC;
      padding: 15px 30px;
      text-transform: uppercase;
      cursor: pointer;

      &:disabled {
        background-color: #ddd;
        cursor: default;
      }

      &:hover:not(:disabled) {
        opacity: 0.8;
      }
    }
  }
`;
