import { call, put } from "redux-saga/effects";

import { Creators as EnrollActions } from "../ducks/enroll";
import { Creators as EnrollmentsCompletedActions } from "../ducks/enrollmentCompleted";
import { Creators as EnrollmentsInProgressActions } from "../ducks/enrollmentInProgress";
import { Creators as ErrorActions } from "../ducks/error";

import api from "../../services/api";
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS, GA_LABELS } from "../../analytics";

export function* postEnroll(action) {
  try {
    const response = yield call(api.post, "/api/v1/enrollments", {
      offer_id: action.payload.offerId
    });

    yield put(EnrollActions.postEnrollConcludeSuccess(response.data.details));

    AnalyticsEvent({
      category: GA_CATEGORIES.ENROLLMENT,
      action: GA_ACTIONS.STARTED_ENROLLMENT,
      label: `[${GA_LABELS.OFFER}${action.payload.offerId}] [${GA_LABELS.ENROLLMENT}${response.data.details.id}]`
    })

  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}

export function* postConcludeEnroll(action) {
  try {
    const response = yield call(
      api.put,
      "/api/v1/enrollments/" + action.payload.enrollId + "/conclusion"
    );
    yield put(EnrollActions.getEnrollSuccess(response.details));

  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}

export function* getEnrollmentCompleted(action) {
  try {
    let url = "/api/v1/enrollments?situation=completed";

    if (action.payload.title) {
      url += "&title=" + action.payload.title;
    }

    if (action.payload.categoryId) {
      url += "&category_id=" + action.payload.categoryId
    }

    if (action.payload.page) {
      url += "&page=" + action.payload.page
    }

    if (!!action.payload.order) {
      url += `&order=${action.payload.order}`;
    } else {
      url += `&order=0`;
    }

    const response = yield call(api.get, url);

    yield put(
      EnrollmentsCompletedActions.getEnrollmentCompletedSuccess(
        response.data
      )
    );

    sendAnalytics(action.payload.title, action.payload.categoryId, 'Concluídos')

  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}

export function* getEnrollmentInProgress(action) {
  try {
    let url = "/api/v1/enrollments?situation=in_progress";

    if (action.payload.title) {
      url += "&title=" + action.payload.title;
    }

    if (action.payload.categoryId) {
      url += "&category_id=" + action.payload.categoryId
    }

    if (action.payload.page) {
      url += "&page=" + action.payload.page
    }

    if (!!action.payload.order) {
      url += `&order=${action.payload.order}`;
    } else {
      url += `&order=0`;
    }

    const response = yield call(api.get, url);

    yield put(
      EnrollmentsInProgressActions.getEnrollmentInProgressSuccess(response.data)
    );

    sendAnalytics(action.payload.title, action.payload.categoryId, 'Em andamento')


  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}

function sendAnalytics(title, categoryId, type) {
  const labelTerm = title ? `"${title}"` : 'Nenhum';
    let categoryGA, labelGA;

    if (categoryId) {
      categoryGA = GA_CATEGORIES.SEARCH
      labelGA = `[${GA_LABELS.CATEGORY} ${categoryId}][${type}] - ` + GA_LABELS.TERM + labelTerm
    } else {
      categoryGA = GA_CATEGORIES.SEARCH + ' [Meus Cursos]';
      labelGA = `[${type}] - ` + GA_LABELS.TERM + labelTerm
    }

    AnalyticsEvent({
      category: categoryGA,
      action: GA_ACTIONS.SEARCHED,
      label: labelGA,
    });
}


export function* getEnrollment(action) {
  try {
    const response = yield call(api.get, "/api/v1/enrollments/" + action.payload.enrollId);
    yield put(EnrollActions.getEnrollSuccess(response.data.details));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
