import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as UserInfoActions } from "../ducks/userInfo";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as SuccessActions } from "../ducks/success";

export function* getUserInfo() {
  try {
    const response = yield call(api.get, "/api/v1/user-info/me");
    let details = response.data.details;

    try {
      details.isAdmin = details.role.title === 'Administrador';
    } catch (e) {
      details.isAdmin = false;
    }

    localStorage.setItem("@emae-cursos:userInfo", JSON.stringify(details));

    yield put(UserInfoActions.getUserInfoSuccess(details));
  } catch (err) {
    yield put(UserInfoActions.getUserInfoError());
    if (!!err.response && err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Tente novamente mais tarde"));
    }
  }
}

export function* getUserInfoById(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/user-info/${action.payload.id}`
    );
    let details = response.data.details;

    yield put(UserInfoActions.getUserInfoByIdSuccess(details));
  } catch (err) {
    yield put(UserInfoActions.getUserInfoError());
    if (!!err.response && err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Tente novamente mais tarde"));
    }
  }
}

export function* updateUserInfo(action) {
  const { id, notify = true, ...data } = action.payload;

  try {
    const response = yield call(api.put, `/api/v1/users/update/${id}`, data);

    localStorage.setItem(
      "@emae-cursos:userInfo",
      JSON.stringify(response.data.details)
    );
    yield put(UserInfoActions.updateUserInfoSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess("Perfil atualizado com sucesso!", {
        toasted: notify
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde.",
          { toasted: notify }
        )
      );
    }
  }
}

export function* updateUserImage(action) {
  try {
    const response = yield call(
      api.put,
      `/api/v1/user-upload-photo`,
      action.payload
    );
    yield put(UserInfoActions.updateUserImageSuccess(response.data));
    yield put(UserInfoActions.getUserInfoRequest());
    yield put(
      SuccessActions.setSuccess("Foto de perfil atualizada com sucesso!", {
        toasted: true
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
