import styled, { css } from "styled-components";

export const NotificationBoxStyled = styled.div`
  ${props => props.visible === false && css`
    display: none !important;
  `}
  position: absolute;
  width: 350px;
  max-height: 450px;
  overflow-y: auto;
  top: 45px;
  background-color: #fff;
  right: -100px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  z-index: 2;

  @media screen and (max-width: 900px) {
    right: 0;
    width: auto;
    min-width: 300px;
  }

  @media screen and (max-width: 375px) {
    right: -75px;
  }

  .list-header {
    background-color: #ededed;
    display: flex;
    width: 100%;
    padding: 30px 15px 10px;
    font-size: 14px;
    color: #979797;
    font-weight: bold;
  }

  .archive-text {
    line-height: 25px;
  }

  .archive-btn {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 3px;
  }

  .back-btn {
    cursor: pointer;
    margin-right: 5px;
  }

  .empty-list {
    color: #d5d5d5;
    font-size: 10px;
    padding: 5px;
    display: block;
    text-align: center;
  }

  .list-notification {
    li {
      color: #686868;
      border-bottom: 1px solid #ededed;
      padding: 15px;
      position: relative;

      .close-btn {
        position: absolute;
        right: 15px;
        top: 5px;
        cursor: pointer;
      }
      .content-wrapper {
        width: 90%;
        padding-left: 20px;
        p {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 15px;
        }

        span {
          color: #b0b0b0;
          font-size: 14px;
        }
      }

      :last-child {
        border-bottom: 0;
      }
    }
  }
`;
