import styled, { css } from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0c4f92;
  color: #fff;
  padding: 0 55px;
  height: 68px;

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;
  }

  @media screen and (max-width: 680px) {
    padding: 25px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 25px;
    height: 100px;
  }
`;
export const LogoMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 10px;
    align-items: flex-start;
    flex: 1;
  }
`;

export const Logo = styled.div`
  margin-right: 15%;

  img {
    width: 160px;
    height: 35px;
  }

  @media screen and (max-width: 900px) {
    margin-right: 30px;
  }
  @media screen and (max-width: 600px) {
    margin-right: 0;
    margin-top: 25px;
  }
`;

export const MenuWrapper = styled.ul`
  display: flex;
  li {
    margin-right: 25px;
    :last-child {
      margin-right: 0;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      font-family: "Futura-Bold", sans-serif;

      :hover {
        color: #f9283d;
      }
    }

    &.active {
      a {
        color: #f9283d;
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0px 0 10px;
  }
`;

export const ProfileMenuHamburgerWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const NotificationWrapper = styled.div`
  margin-right: 20px;
  position: relative;

  .bell-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  font-size: 14px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const MenuHamburger = styled.div`
  margin-left: 20px;
  cursor: pointer;
  font-size: 25px;
  width: 30px;
  height: 30px;
  user-select: none;

  svg {
    fill: #fff;
  }

  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
`;

export const DropDownMenu = styled.div`
  ${props => !props.dropIsOpen && css`display: none;`}
  background-color: #0c4f92;
  position: absolute;
  right: 0;
  top: 35px;
  padding: 15px 0;
  min-width: 220px;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  ul {
    user-select: none;
    li {
      a {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        font-size: 14px;
        color: #fff;
        :hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const BackButton = styled.button`
  height: 19px;
  width: 19px;
  background: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    svg {
      path {
      fill: #ff0000;

      }
    }
  }
`

export const Logout = styled.button`
  color: #fff;
  background-color: transparent;
  font-size: 14px;
  border: 0;
  font-family: "Futura-Medium", sans-serif;
  margin-left: 20px;

  &:hover {
    color: #f9283d;
    cursor: pointer;
  }
`
