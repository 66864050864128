import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { CategoriaStyle } from './styles';

export default class MaterialItem extends Component {
  getCountMaterials = count => {
    // if (!!this.props.idMaterial) {
    //   return `${count === 1 ? 'arquivo' : 'arquivos'} de apoio`;
    // }
    return `${count === 1 ? 'material' : 'materiais'} de apoio`;
  };

  render() {
    const {
      idCategoria,
      idMaterial,
      title,
      cor,
      qtdMateriaisApoio,
    } = this.props;

    let link = `/documentos-corporativos/categoria/${idCategoria}`;
    if (!!idMaterial) {
      link = `/documentos-corporativos/categoria/${idCategoria}/material/${idMaterial}`;
    }

    return (
      <CategoriaStyle cor={cor}>
        <Link to={link}>
          <Fragment>
            <div>
              <span className="notranslate tituloCategoria">{title}</span>
            </div>
            <div className="badges">
              {qtdMateriaisApoio > 0 && qtdMateriaisApoio && (
                <span className="badge grey">
                  {`
                    ${qtdMateriaisApoio}
                    ${this.getCountMaterials(Number(qtdMateriaisApoio))}
                  `}
                </span>
              )}
            </div>
          </Fragment>
        </Link>
      </CategoriaStyle>
    );
  }
}
