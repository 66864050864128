import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  CursoItemWrapper,
  CursoInfoAndBadge,
  CursoBadge,
  CursoInfo,
  CursoInfoStars,
  CursoTitle,
  CursoStats,
  CursoIconAndStats,
  CursoProgressBar,
  BadgeNota,
  ContainerBadge
} from "./styles";
import moment from "moment";

import RatingStars from "../../components/RatingStars";
import ProgressBar from "../../components/ProgressBar";

export default class CursoItem extends Component {
  renderDates(inicio, fim) {
    return fim ? (
      <div>
        <span className="title">Conclusão</span>
        <span className="result">
          {moment(fim)
            .format("DD/MM/Y")}
        </span>
      </div>
    ) : (
      <div>
        {/* <span className="title">Início</span>
        <span className="result">
          {moment(inicio)
            .utc()
            .format("DD/MM/Y")}
        </span> */}
      </div>
    );
  }
  render() {
    let {
      rating,
      title,
      isNew,
      progress,
      nivel,
      inicio,
      fim,
      id,
      idMatricula,
      oferta,
      modality,
      nota,
      validade
    } = this.props;
    progress = Number(progress).toFixed(0);
    const isPresencial = modality === "presencial";
    return (
      <CursoItemWrapper>
        <Link
          to={
            idMatricula
              ? `/curso/${id}/matricula/${idMatricula}`
              : `/curso/${id}/oferta/${oferta}`
          }
        >
          <CursoInfoAndBadge>
            <CursoIconAndStats>
              <CursoInfo>
                <CursoInfoStars>
                  <Fragment>
                    <RatingStars
                      color="#fbce07"
                      fontSize="15px"
                      rating={rating}
                    />
                  </Fragment>
                </CursoInfoStars>
                <CursoTitle>
                  <h2 className="notranslate">{title}</h2>
                </CursoTitle>

                <CursoStats>
                    <div>
                      <span className="title">Progresso</span>
                      <span className="result">{progress}%</span>
                    </div>
                  {!fim && (
                    <div>
                      <span className="title">Nível</span>
                      <span className="result">{nivel}</span>
                    </div>
                  )}
                  {this.renderDates(inicio, fim)}
                  {fim && validade && (
                    <div>
                      <span className="title">Renovação</span>
                      <span className="result">
                        {moment(validade)
                          .utc()
                          .format("DD/MM/Y")}
                      </span>
                    </div>
                  )}
                </CursoStats>
              </CursoInfo>
            </CursoIconAndStats>
            <ContainerBadge>
              <CursoBadge>
                {isPresencial && (
                  <span className="badge presencial">Presencial</span>
                )}
                {isNew && <span className="badge">Novo</span>}
              </CursoBadge>

              {!!nota && (
                <BadgeNota>
                  <span className="title">nota</span>
                  <span className="value">{nota}</span>
                </BadgeNota>
              )}
            </ContainerBadge>
          </CursoInfoAndBadge>
          <CursoProgressBar>
            <ProgressBar progress={progress} />
          </CursoProgressBar>
        </Link>
      </CursoItemWrapper>
    );
  }
}
