import styled, { css } from "styled-components";
import { Content as ContentBase } from "../../styles";

export const Content = styled(ContentBase)`
  position: relative;
`;

export const SearchAndViewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -35px;
  margin-bottom: 30px;

  > div {
    height: 70px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SearchWrapper = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    flex: 1;
    border: 0;
    height: 50px;
    font-size: 18px;
  }

  @media screen and (min-width: 600px) {
    div {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 600px) {
    > div:first-child {
      padding-left: 10px;
    }
    input {
      padding-left: 10px;
    }
  }
`;
export const ViewWrapper = styled.div`
  flex: 1;
  margin-left: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: none;
  }

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 70px;

    :hover {
      background: #eee;
    }
  }
`;
export const CategoriaStyle = styled.li`
  width: 100%;
  height: 70px;
  border-left: 2px solid
    ${props =>
      props.cor &&
      css`
        ${props.cor}
      `};
  margin-bottom: 2px;
  overflow: hidden;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    :hover {
      background-color: #dfdfdf;
    }

    > div {
      display: flex;
      align-items: center;
    }
    .tituloCategoria {
      font-size: 24px;
      font-family: "Futura-Bold", sans-serif;
      margin-left: 20px;
      color: #535353;
    }

    .badges {
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .badge {
      color: #0c4f92;
      font-size: 14px;
      font-family: "Futura-Bold", sans-serif;

      &.grey {
        color: #535353;
      }
    }
  }
`;

export const Categorias = styled.div`
  ul {
    display: flex;
    flex-direction: column;
  }
`;
