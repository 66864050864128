import styled from "styled-components";

export const HeaderStyle = styled.header`
  width: 100%;
  height: 50px;
  background-color: #0c4f92;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 20px;

  .left-content {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
    }
    img {
      cursor: pointer;
      padding: 15px;
      margin-left: 25px;
    }
  }

  .center-content {
    display: flex;
    align-items: center;
    img {
      width: 60px;
    }
  }

  .right-content {
    display: flex;
    align-items: center;
    img {
      width: 100px;
    }
  }
`;
