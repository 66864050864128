import React, { Component } from 'react'
import { SearchModeStyled } from './styles';

export default class SearchMode extends Component {

  render() {
    return (
      <SearchModeStyled>
        <div>
          <span>Buscar</span>
          <a href='#!' onClick={this.props.cancel}>Cancelar</a>
        </div>
        <input type='text' onChange={this.props.change} placeholder='Digite o treinamento' autoFocus />
      </SearchModeStyled>
    )
  }
}
