import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as RatingsActions } from "../ducks/rating";
import { Creators as ErrorActions } from "../ducks/error";

export function* getRatings(action) {
  try {
    let params = "?course_id=" + action.payload.courseId;

    if (action.payload.type) {
      params += "&type=" + action.payload.type;
    }

    if (action.payload.answers) {
      params += "&answers=false";
    }

    const response = yield call(api.get, "/api/v1/courserating" + params);
    yield put(RatingsActions.getRatingsSuccess(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
