import styled from 'styled-components';

export const Categorias = styled.div`
  padding-top: 30px;

  ul {
    display: flex;
    flex-direction: column;
  }
`;
