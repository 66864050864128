import styled, { css } from 'styled-components';

export const SupportMaterialItem = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 15px;

  .leftSide {
    flex: 1;
    display: flex;
    flex-direction: column;

    > div:first-child {
      border-left: 5px solid #ff373b;
      border-bottom: 1px solid #f0f0f0;
      padding: 20px;

      display: flex;
      align-items: center;

      .badge {
        background-color: #ff373b;
        padding: 3px 15px;
        text-align: center;
        font-family: 'Futura-Bold', sans-serif;
        font-size: 12px;
        color: #fff;
        margin-right: 15px;
        text-transform: uppercase;
      }
      > span {
        font-family: 'Futura-Bold', sans-serif;
        color: #464646;
        font-size: 14px;
      }
    }

    > div.box {
      padding: 20px 25px;
      display: flex;
      align-items: center;

      > p {
        font-size: 14px;
        color: #6d6d6d;
      }
    }

    > div.border {
      border-top: #f0f0f0 solid 1px;
      padding: 20px;
      p {
        color: #aaa;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .rightSide {
    width: 120px;
    padding: 5px;
    border-left: 1px solid #f0f0f0;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: #6e6e6e;
      font-family: 'Futura-Bold', sans-serif;
      font-size: 12px;

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #6bb64b;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
`;

export const ModalContainer = styled.div`
  width: 300px;
  padding: 20px;
  width: 450px;

  .title {
    font-weight: bold;
    font-family: "Futura-Bold", sans-serif;
    margin-bottom: 10px;
  }
`
export const ModalContent = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-family: "Futura-Light", sans-serif;
  display:flex;

  .text {
    margin-left: 10px
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`

export const CancelButton = styled.button`
  padding: 10px;
  height: 40px;
  width: 100%;
  margin-right: 5px;
  color: #9c9c9c;
  border: #9c9c9c solid 1px;
  background: #fff;

  :hover {
    cursor: pointer;
  }

`
export const DownloadButton = styled.button`
  padding: 10px;
  height: 40px;
  width: 100%;
  margin-left: 5px;
  background-color: #d1d1d1;
  border: #9c9c9c solid 1px;
  color: #9c9c9c;
  font-weight: bold;

  ${props => props.checked === 'ativo' && (
    css`
      background-color: #6ebe4d;
      border:#5bb136 solid 1px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    `
  )}

`

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: red;
  stroke-width: 4px;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #f7f7f7;
  transition: all 150ms;
  border: #DDDDDD solid 2px;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

