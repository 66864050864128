import React, { Component } from "react";
import { TermosUsoStyled } from "./styles";
import { TitleWrapper } from "../../styles";
import { FormCheckBox } from "../../components/Form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as TermosUsoActions } from "../../store/ducks/termosUso";
import { Creators as UserInfoActions } from "../../store/ducks/userInfo";

class TermosUso extends Component {
  state = {
    isChecked: false,
    termAccepted: false,
    btnText: "Aceitar"
  };

  handleChangeCheck = e => {
    this.setState({ isChecked: e.target.checked });
  };

  handleFinish = () => {
    const userInfo = JSON.parse(localStorage.getItem("@emae-cursos:userInfo"));
    if (userInfo.id && this.state.termAccepted === false) {
      this.props.postTermosUsoRequest({ id: userInfo.id });
      this.props.getUserInfoRequest();
      this.setState({ termAccepted: true, btnText: "Carregando..." });
    }
  };

  componentDidUpdate() {
    if (
      !this.props.termosUso.loading &&
      this.props.termosUso.success &&
      this.props.userInfo.success
    ) {
      return this.props.history.push(`/`);
    }
  }

  render() {
    const { isChecked } = this.state;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TermosUsoStyled>
          <TitleWrapper style={{ width: "100%", margin: "20px 0" }}>
            <h1>Termos de uso</h1>
          </TitleWrapper>
          <div className="termo-box">
            Prezado(a) empregado(a),
            <br />
            <br />

            <br />
            Bem-vindo(a) ao Portal de Treinamentos da EMAE!
            <br />
            <br />
            Agradecemos por ter aceitado nosso convite para juntos evoluirmos
            constantemente.
            <br />
            <br />
            Informamos que, como os treinamentos estarão disponíveis
            ininterruptamente, o acesso voluntário fora do horário de trabalho é
            de sua inteira responsabilidade, sem conotação de horas extras ou
            outro possível desdobramento legal.
            <br />
            <br />
            O seu aceite abaixo implica na adesão ao convite de acesso e aos
            correspondentes termos e condições.
            <br />
            <br />
          </div>

          <div className="control-wrapper">
            <FormCheckBox
              label="Li e aceito os termos de uso"
              onChange={e => this.handleChangeCheck(e)}
              defaultChecked={false}
            />

            <button disabled={!isChecked} onClick={this.handleFinish}>
              {this.state.btnText}
            </button>
          </div>
        </TermosUsoStyled>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  termosUso: state.termosUso
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...TermosUsoActions, ...UserInfoActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermosUso);
