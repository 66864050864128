import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as SupportMaterialsActions } from '../../store/ducks/materials/supportMaterials';

import { Content } from '../../styles';
import { Categorias } from './styles';

import MaterialItem from './components/MaterialItem';
import Preloader from '../../components/Preloader';

export class SupportMaterial extends Component {
  componentDidMount() {
    this.props.clearSupportMaterials();
    this.props.getSupportMaterialsCategoriesRequest();
  }

  render() {
    const { supportMaterials } = this.props;
    if (!Array.isArray(supportMaterials.data)) {
      return (
        <Content>
          <Categorias>
            <ul data-testid="preloader-not-array">
              <Preloader />
            </ul>
          </Categorias>
        </Content>
      );
    }

    return (
      <Fragment>
        <Content>
          <Categorias>
            {!supportMaterials.loading &&
              supportMaterials.data.length === 0 &&
              'Nenhuma categoria com material de apoio encontrado.'}
            <ul>
              {supportMaterials.loading ? (
                <div data-testid="preloader">
                  <Preloader />
                </div>
              ) : (
                supportMaterials.data.map((category, key) => (
                  <MaterialItem
                    key={key}
                    idCategoria={category.id}
                    icon={category.icon}
                    title={category.title}
                    // qtdMateriaisApoio={
                    //   !!category.__meta__
                    //     ? category.__meta__.count_support_materials
                    //     : '0'
                    // }
                    cor={category.color}
                  />
                ))
              )}
            </ul>
          </Categorias>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  supportMaterials: state.supportMaterials,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...SupportMaterialsActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportMaterial);
