import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "login/POST_REQUEST",
  GET_SUCCESS: "login/GET_SUCCESS",
  GET_FAILURE: "login/GET_FAILURE",
  LOAD_USER: "login/LOAD_USER",
  RESET_REQUEST: "login/RESET_REQUEST",
  RESET: "login/RESET"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  logged: null,
  error: false
});

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true, logged: false, error: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        logged: true,
        error: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, error: true };
    case Types.RESET:
      return {
        data: [],
        loading: false,
        logged: false,
        error: false
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postLoginRequest: (login, password, profile) => ({
    type: Types.POST_REQUEST,
    payload: { login, password, profile }
  }),

  getLoginSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  getFailure: () => ({
    type: Types.GET_FAILURE
  }),

  loadLocalUser: () => ({
    type: Types.LOAD_USER
  }),

  logoutRequest: () => ({
    type: Types.RESET_REQUEST
  }),

  logout: () => ({
    type: Types.RESET
  })
};
