import React, { Component, Fragment } from 'react'

import MdStarOutline from 'react-ionicons/lib/MdStarOutline'
import MdStar from 'react-ionicons/lib/MdStar'

export default class RatingStars extends Component {

    constructor(props) {
        super(props);

        this.state = {
            qtdStarCheia: [],
            qtdStarVazia: []
        }
    }

    componentDidMount = () => {
        const { rating } = this.props;
        const integer = parseInt(rating)

        const arrayCheia = [];
        const arrayVazia = []

        for (let i = 0; i < integer; i++) {
            arrayCheia.push(i + 1)
        }

        for (let i = 0; i < 5 - integer; i++) {
            arrayVazia.push(i+1)
        }

        this.setState({
            qtdStarCheia: arrayCheia,
            qtdStarVazia: arrayVazia
        })
    }

    render() {
        const { qtdStarCheia, qtdStarVazia } = this.state;
        const {color, fontSize} = this.props;

        return (
            <Fragment>
                {qtdStarCheia.map((i) => {
                    return <MdStar key={i} color={color} fontSize={fontSize} />
                })}

                {qtdStarVazia.map((i) => {
                    return <MdStarOutline key={i} color={color} fontSize={fontSize} />
                })}
            </Fragment>
        )
    }
}
