import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as SupportMaterialsActions } from '../../../store/ducks/materials/supportMaterials';

import {
  HeaderInfo,
  HeaderInfoInner,
  Header as ContainerHeader,
  TitleAndViewWrapper,
  TitleWrapper,
} from '../../../styles';

import { Materials, Content } from './styles';

import MaterialItem from '../components/MaterialItem';
import Preloader from '../../../components/Preloader';

export class SupportMaterialCategory extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.clearSupportMaterials();
    this.props.getSupportMaterialsFilesRequest(id);
  }

  render() {
    const { supportMaterials } = this.props;
    const materials = supportMaterials.data.supportMaterials || [];

    return (
      <Fragment>
        <HeaderInfo>
          <HeaderInfoInner>
            <ContainerHeader>
              <Fragment>
                {!supportMaterials.loading && (
                  <Fragment>
                    <span className="label">Categoria</span>
                    <TitleAndViewWrapper>
                      <TitleWrapper>
                        <h1 className="notranslate">
                          {supportMaterials.data.title}
                        </h1>
                      </TitleWrapper>
                    </TitleAndViewWrapper>
                  </Fragment>
                )}
              </Fragment>
            </ContainerHeader>
          </HeaderInfoInner>
        </HeaderInfo>
        <Content>
          <Materials>
            {!supportMaterials.loading &&
              materials.length === 0 &&
              'Nenhum material de apoio encontrado.'}
            <ul>
              {supportMaterials.loading ? (
                <Preloader />
              ) : (
                supportMaterials.success &&
                materials.length !== 0 &&
                materials.map((material, key) => (
                  <MaterialItem
                    key={key}
                    idCategoria={supportMaterials.data.id}
                    idMaterial={material.id}
                    icon={supportMaterials.data.icon}
                    title={material.name}
                    qtdMateriaisApoio={
                      material.__meta__ ? material.__meta__.count_files : '0'
                    }
                  />
                ))
              )}
            </ul>
          </Materials>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  supportMaterials: state.supportMaterials,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...SupportMaterialsActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportMaterialCategory);
