import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { exibirInfoNovosTreinamentos } from "../../config";

import { CategoriaStyle } from "./styles";

export default class Categoria extends Component {
  renderBadges() {
    const {
      qtdNovosCursos,
      qtdCursosAndamento,
      qtdCursos,
      statusMatricula
    } = this.props;

    return (
      <div className="badges">
        {qtdCursosAndamento && qtdCursos && (
          <span className="badge grey">
            {`
              ${qtdCursos}
              ${
                Number(qtdCursos) > 1
                  ? "não iniciados"
                  : "não iniciado"
              } /
              ${qtdCursosAndamento} em andamento
            `}
          </span>
        )}

        {exibirInfoNovosTreinamentos && qtdNovosCursos && Number(qtdNovosCursos) > 0 && (
          <span className="badge">
            {qtdNovosCursos}{" "}
            {Number(qtdNovosCursos) > 1 ? "novos treinamentos" : "novo treinamento"}
          </span>
        )}

        {!!statusMatricula && (
          <span className="badge grey">{statusMatricula}</span>
        )}
      </div>
    );
  }

  render() {
    const {
      titulo,
      cor,
      id,
      type,
      oferta,
      idMatricula
    } = this.props;

    function TituloRender() {
      return <span className="notranslate tituloCategoria">{titulo}</span>;
    }

    let link;
    if (type === "category") {
      link = `/categoria/${id}`;
    } else {
      link = !!idMatricula
        ? `/curso/${id}/matricula/${idMatricula}`
        : `/curso/${id}/oferta/${oferta}`;
    }

    return (
      <CategoriaStyle cor={cor}>
        <Link to={link}>
          <Fragment>
            <div>
              <TituloRender />
            </div>
          </Fragment>
          {this.renderBadges()}
        </Link>
      </CategoriaStyle>
    );
  }
}
