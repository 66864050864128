import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Creators as AuthActions } from "../../store/ducks/auth";
import { Creators as ErrorActions } from "../../store/ducks/error";

import {
  LoginWrapper,
  LeftSide,
  RightSide,
  LeftSideContent,
  LoginFormWrapper,
  LoginBottomLinks,
  RightSideFooter,
  FooterStyle
} from "./styles";

import {
  FormInput,
  FormCheckBox,
  FormSubmitButton,
} from "../../components/Form";
import { FormItemWrapper } from "../../components/Form/styles";

import RightSideShellFooter from "../../assets/img/emae-footer@2x.png";
import LogoDp from "../../assets/img/dp-logotipo.png";
import Loader from "./components/Loader";
// import LogoGooglePlay from "../../assets/img/logo-google-play.png";
// import LogoAppleStore from "../../assets/img/logo-apple-store.png";
import {
  AnalyticsExternalLink,
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS
} from "../../analytics";

class Login extends Component {
  state = {
    login: "",
    password: ""
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const login = query.get("email");

    if (login) {
      this.setState({ login });
    }
  }

  handleOnChange = e => {
    if (e.target.name === "login") {
      this.setState({ login: e.target.value });
    }

    if (e.target.name === "password") {
      this.setState({ password: e.target.value });
    }
  };

  handleSubmit = e => {
    AnalyticsEvent({
      category: GA_CATEGORIES.AUTH,
      action: GA_ACTIONS.CLICKED_BUTTON_ENTER
    });

    e.preventDefault();
    const { postLoginRequest } = this.props;

    let username = this.state.login;

    postLoginRequest(username, this.state.password);
  };

  render() {
    const { login } = this.props;
    if (login.logged) {
      if (login.data.completeRegister) return <Redirect to="/home" />;

      return <Redirect to="/perfil" />;
    }
    return (
      <LoginWrapper>
        <LeftSide>
          <LeftSideContent>
            <ToastContainer autoClose={8000} />
            <LoginFormWrapper>
              <h4>BEM-VINDO(A) AO PORTAL DE TREINAMENTOS EMAE </h4>
              <form onSubmit={this.handleSubmit}>
                <FormItemWrapper>
                  <FormInput
                    label="E-mail"
                    placeholder="E-mail"
                    name="login"
                    value={this.state.login}
                    type="email"
                    onChange={this.handleOnChange}
                  />
                </FormItemWrapper>

                <FormItemWrapper>
                  <FormInput
                    label="Senha"
                    placeholder="Senha"
                    name="password"
                    type="password"
                    onChange={this.handleOnChange}
                  />
                </FormItemWrapper>

                <FormItemWrapper>
                  <FormCheckBox label="Manter conectado" />
                </FormItemWrapper>

                <FormSubmitButton
                  label={this.props.login.loading ? <Loader /> : "Entrar"}
                />
              </form>

              <LoginBottomLinks>
                <div>
                  <Link to="/esqueci-senha">Esqueci a senha</Link>
                </div>
              </LoginBottomLinks>
            </LoginFormWrapper>

            {AnalyticsExternalLink({
              url: "https://portugues.digitalpages.com.br",
              content: <img src={LogoDp} alt="Digital Pages" width="100" />,
              style: { alignSelf: "center" },
              label: "RDP Learning"
            })}
          </LeftSideContent>
        </LeftSide>

        <RightSide>
          <RightSideFooter>
            <FooterStyle>
              {/* <div>
                <span className="title-suporte">Precisa de suporte?</span>
                <span>emae@digitalpages.com.br</span>
                <div className="vertical-divider" />
                <a
                  href=""
                  target="_blank"
                >
                  <img src={LogoGooglePlay} alt="Google Play" />
                </a>

                <a
                  href="https://pocs.digitalpages.com.br/shell"
                  target="_blank"
                >
                  <img src={LogoAppleStore} alt="Apple Store" />
                </a>
              </div> */}
            </FooterStyle>
            <span>Versão 0.11.37</span>
            <span>©2020 EMAE. Todos os direitos reservados.</span>
            <img src={RightSideShellFooter} alt="Shell" />
          </RightSideFooter>
        </RightSide>
      </LoginWrapper>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions, ...ErrorActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
