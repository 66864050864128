import styled from 'styled-components';
import ImgLoading from '../../../../assets/img/login-loader.svg'


export const LoginLoader = styled.div`
    width: 100%;
    height: 25px;
    background-image: url(${ImgLoading});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
`
