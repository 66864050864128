import api from "../../services/api";
import React from "react";
import PhotoService from "../../services/photo";

export default class Photo extends React.Component {

  state = {
    source: this.props.defaultPhoto,
    private: false
  }

  componentDidMount() {
    let photoUri = PhotoService.getPhoto(this.props.fileId);
    if (photoUri) {
      api.get(photoUri).then(response => {
        if (response.status === 200) {
          this.setState({
            source: "data:image/png;base64," + response.data,
          })
        }
      })
    }

  }

  render = () => {
    return <img width="100%" src={this.state.source} alt={"Imagem de perfil"} />;
  };
}
