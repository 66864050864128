import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'supportMaterials/GET_REQUEST',
  GET_SUCCESS: 'supportMaterials/GET_SUCCESS',
  GET_REQUEST_FILES: 'supportMaterials/GET_REQUEST_FILES',
  GET_SUCCESS_FILES: 'supportMaterials/GET_SUCCESS_FILES',
  GET_REQUEST_CATEGORIES: 'supportMaterials/GET_REQUEST_CATEGORIES',
  GET_SUCCESS_CATEGORIES: 'supportMaterials/GET_SUCCESS_CATEGORIES',
  CLEAR_ITEMS: 'supportMaterials/CLEAR_ITEMS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
});

export default function supportMaterials(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: { ...state.data, ...action.payload },
        loading: false,
        success: true,
      };
    case Types.GET_REQUEST_FILES:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS_FILES:
      return {
        data: { ...state.data, ...action.payload },
        loading: false,
        success: true,
      };
    case Types.GET_REQUEST_CATEGORIES:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS_CATEGORIES:
      return {
        data: [...state.data, ...action.payload.data],
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        total: action.payload.total,
        loading: false,
        success: true,
      };
    case Types.CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        success: false,
        page: null,
        lastPage: null,
        total: 0,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getSupportMaterialsRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  getSupportMaterialsSuccess: payload => ({
    type: Types.GET_SUCCESS,
    payload,
  }),

  getSupportMaterialsFilesRequest: categoryId => ({
    type: Types.GET_REQUEST_FILES,
    payload: { categoryId },
  }),

  getSupportMaterialsFilesSuccess: payload => ({
    type: Types.GET_SUCCESS_FILES,
    payload,
  }),

  getSupportMaterialsCategoriesRequest: () => ({
    type: Types.GET_REQUEST_CATEGORIES,
  }),

  getSupportMaterialsCategoriesSuccess: ({ data, page, lastPage, total }) => ({
    type: Types.GET_SUCCESS_CATEGORIES,
    payload: { data, page, lastPage, total },
  }),

  clearSupportMaterials: () => ({
    type: Types.CLEAR_ITEMS,
  }),
};
