import styled from 'styled-components'
import preloader from './preloader.gif';

export const PreloaderStyle = styled.div`
  background-image: url(${preloader});
  width: 100%;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
`
