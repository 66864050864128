import { all, takeLatest, takeEvery } from "redux-saga/effects";
/**
 * TYPES
 */
import { Types as AuthTypes } from "../ducks/auth";
import { Types as CategoriesTypes } from "../ducks/categories";
import { Types as InterestsTypes } from "../ducks/interests";
import { Types as TermosUsoTypes } from "../ducks/termosUso";
import { Types as CategoryTypes } from "../ducks/category";
import { Types as CoursesTypes } from "../ducks/courses";
import { Types as CourseTypes } from "../ducks/course";
//import { Types as AdminCoursesTypes } from "../ducks/adminCourses";
import { Types as RatingTypes } from "../ducks/rating";
import { Types as RatingAnswerTypes } from "../ducks/ratingAnswer";
import { Types as EnrollmentTypes } from "../ducks/enroll";
import { Types as EnrollmentCompletedTypes } from "../ducks/enrollmentCompleted";
import { Types as EnrollmentInProgressTypes } from "../ducks/enrollmentInProgress";
import { Types as UserInfoTypes } from "../ducks/userInfo";
import { Types as NotificationTypes } from "../ducks/notification";
import { Types as TopicsTypes } from "../ducks/topics";
import { Types as SearchTypes } from "../ducks/search";
import { Types as SearchOfferTypes } from "../ducks/searchOffer";
import { Types as LoginAsUserTypes } from "../ducks/admin/loginAsUser";
import { Types as HtmlCourseTypes } from "../ducks/htmlCourse";

import { updateHtml } from './htmlCourse';

/**
 * treinômetro
 */
import { Types as HistoricoTypes } from "../ducks/historico";
import { Types as CertificadoTypes } from "../ducks/certificado";
import { Types as ScormTypes } from "../ducks/scorm";
import { getSearch } from "./search";
import { getSearchOffer } from "./searchOffer";

/**
 * SAGAS
 */
import { postLogin, loadLocalUser, signOut } from "./auth";
import { loginAsUser } from "./admin/loginAsUser";
import { getCategories } from "./categories";
import { postInterests } from "./interests";
import { postTermosUso } from "./termosUso";
import { getCategory } from "./category";
import { getCourses } from "./courses";
import { getCourse } from "./course";
//import { getAdminCourses } from "./adminCourses";
import { getRatings } from "./rating";
import {
  setRatingValue,
  postRatingAnswer,
  getRatingAnswers
} from "./ratingAnswer";
import { getUserInfo, updateUserInfo, updateUserImage } from "./userInfo";
import {
  postEnroll,
  getEnrollmentCompleted,
  getEnrollmentInProgress,
  getEnrollment,
  postConcludeEnroll
} from "./enroll";
import { getTopics } from "./topics";
import { getScorm, updateScorm } from "./scorm";

import { getTreinometroPerfilHistorico } from "./historico";
import {
  getTreinometroPerfilCertificado,
  downloadCertificado
} from "./certificado";
import {
  getNotifications,
  removeNotificacation,
  readNotificacation
} from "./notification";

/**
 * Support Materials
 */
import { getSupportMaterialDownload } from './materials/download';
import {
  getSupportMaterial,
  getSupportMaterialsFiles,
  getSupportMaterialsCategories,
} from './materials/supportMaterials';
import {
  postAcceptance,
} from './materials/acceptance';
import { Types as AcceptanceTypes } from '../ducks/materials/acceptance';


//ForgotPassword
import { forgotPassword } from "./forgotPassword";
import { ForgotPasswordTypes } from "../ducks/forgotPassword";
import { definePassword } from "./definePassword";
import { DefinePasswordTypes } from "../ducks/definePassword";

//FileDownload
import { Types as FileDownloadTypes } from "../ducks/fileDownload";
import { fileDownload } from "./fileDownload";

import { Types as SupportMaterialDownloadTypes } from '../ducks/materials/download';
import { Types as SupportMaterialsTypes } from '../ducks/materials/supportMaterials';

export default function* rootSaga() {
  yield all([
    //ForgotPassword
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(DefinePasswordTypes.DEFINE_PASSWORD_REQUEST, definePassword),

    takeLatest(AuthTypes.POST_REQUEST, postLogin),
    takeLatest(LoginAsUserTypes.GET_REQUEST, loginAsUser),
    takeLatest(AuthTypes.LOAD_USER, loadLocalUser),
    takeLatest(AuthTypes.RESET_REQUEST, signOut),
    takeLatest(CategoriesTypes.GET_REQUEST, getCategories),
    takeLatest(InterestsTypes.POST_REQUEST, postInterests),
    takeLatest(TermosUsoTypes.POST_REQUEST, postTermosUso),
    takeLatest(CategoryTypes.GET_REQUEST, getCategory),
    takeLatest(CoursesTypes.GET_REQUEST, getCourses),
    takeLatest(CourseTypes.GET_REQUEST, getCourse),
    //takeLatest(AdminCoursesTypes.GET_REQUEST, getAdminCourses),
    takeLatest(RatingTypes.GET_REQUEST, getRatings),
    takeLatest(RatingAnswerTypes.GET_REQUEST, getRatingAnswers),
    takeLatest(RatingAnswerTypes.POST_REQUEST, postRatingAnswer),
    takeLatest(RatingAnswerTypes.SET_RATING, setRatingValue),
    takeLatest(EnrollmentTypes.POST_REQUEST, postEnroll),
    takeLatest(EnrollmentTypes.POST_CONCLUDE_REQUEST, postConcludeEnroll),
    takeLatest(EnrollmentCompletedTypes.GET_REQUEST, getEnrollmentCompleted),
    takeLatest(EnrollmentInProgressTypes.GET_REQUEST, getEnrollmentInProgress),
    takeLatest(EnrollmentTypes.GET_REQUEST, getEnrollment),
    takeLatest(UserInfoTypes.GET_REQUEST, getUserInfo),
    takeLatest(UserInfoTypes.UPDATE_REQUEST, updateUserInfo),
    takeLatest(UserInfoTypes.UPDATE_IMAGE_REQUEST, updateUserImage),
    takeLatest(SearchTypes.GET_REQUEST, getSearch),
    takeLatest(SearchOfferTypes.GET_REQUEST, getSearchOffer),

    // Treinometro
    takeLatest(
      HistoricoTypes.GET_PERFIL_HISTORICO_REQUEST,
      getTreinometroPerfilHistorico
    ),
    takeLatest(
      CertificadoTypes.GET_PERFIL_CERTIFICADO_REQUEST,
      getTreinometroPerfilCertificado
    ),
    takeLatest(
      CertificadoTypes.DOWNLOAD_CERTIFICADO_REQUEST,
      downloadCertificado
    ),

    //notifications
    takeLatest(NotificationTypes.GET_REQUEST, getNotifications),
    takeLatest(NotificationTypes.DELETE_REQUEST, removeNotificacation),
    takeEvery(NotificationTypes.READ_REQUEST, readNotificacation),
    takeLatest(TopicsTypes.GET_REQUEST, getTopics),

    // Scorm
    takeEvery(ScormTypes.POST_REQUEST, updateScorm),
    takeEvery(ScormTypes.GET_REQUEST, getScorm),
    takeLatest(TopicsTypes.GET_REQUEST, getTopics),

    //FileDownload
    takeLatest(FileDownloadTypes.GET_REQUEST, fileDownload),

     // Support Material
     takeLatest(
      SupportMaterialDownloadTypes.GET_REQUEST,
      getSupportMaterialDownload
    ),
    takeLatest(SupportMaterialsTypes.GET_REQUEST, getSupportMaterial),
    takeLatest(
      SupportMaterialsTypes.GET_REQUEST_FILES,
      getSupportMaterialsFiles
    ),
    takeLatest(
      SupportMaterialsTypes.GET_REQUEST_CATEGORIES,
      getSupportMaterialsCategories
    ),
    takeLatest(
      AcceptanceTypes.GET_REQUEST,
      postAcceptance
    ),
    takeLatest(HtmlCourseTypes.POST_REQUEST, updateHtml),
  ]);
}
