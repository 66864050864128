import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "rating-answer/GET_REQUEST",
  GET_SUCCESS: "rating-answer/GET_SUCCESS",
  POST_REQUEST: "rating-answer/POST_REQUEST",
  POST_SUCCESS: "rating-answer/POST_SUCCESS",
  SET_RATING: "rating-answer/SET_RATING",
  GET_RATING: "rating-answer/GET_RATING"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  success: false,
  loading: false,
  value: 1
});

export default function ratingAnswer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        success: true,
        loading: false,
        value: null
      };
    case Types.POST_REQUEST:
      return { ...state, loading: true };
    case Types.POST_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        value: null,
        rated: true
      };
    case Types.SET_RATING:
      return {
        ...state,
        loading: false,
        value: action.payload.value
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, value: null };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getRatingAnswersRequest: (courseRatingId, page) => ({
    type: Types.GET_REQUEST,
    payload: { courseRatingId, page }
  }),

  getRatingAnswersSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  postRatingAnswerRequest: (courseRatingId, rating, comment, courseId) => ({
    type: Types.POST_REQUEST,
    payload: { courseRatingId, rating, comment, courseId }
  }),

  postRatingAnswerSuccess: data => ({
    type: Types.POST_SUCCESS,
    payload: { data }
  }),

  setRatingValue: value => ({
    type: Types.SET_RATING,
    payload: { value }
  })
};
