import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'supportMaterialDownload/GET_REQUEST',
  GET_SUCCESS: 'supportMaterialDownload/GET_SUCCESS',
  CLEAR_ITEMS: 'supportMaterialDownload/CLEAR_ITEMS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  downloading: false,
});

export default function supportMaterialDownload(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, data: {}, downloading: true };
    case Types.GET_SUCCESS:
      return INITIAL_STATE;
    case Types.CLEAR_ITEMS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getSupportMaterialDownloadRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  getSupportMaterialDownloadSuccess: () => ({
    type: Types.GET_SUCCESS,
  }),

  clearSupportMaterialDownload: () => ({
    type: Types.CLEAR_ITEMS,
  }),
};
