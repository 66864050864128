import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'search-offer/GET_REQUEST',
  GET_SUCCESS: 'search-offer/GET_SUCCESS',
  CLEAR_ITEMS: 'search-offer/CLEAR_ITEMS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  page: null,
  lastPage: null
});

export default function search(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        data: [...state.data, ...action.payload.data],
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        loading: false,
      };
    case Types.CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        page: null,
        lastPage: null
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getSearchRequest: data => ({
    type: Types.GET_REQUEST,
    payload: data
  }),

  getSearchSuccess: ({ data, page, lastPage }) => ({
    type: Types.GET_SUCCESS,
    payload: { data, page, lastPage },
  }),

  clearSearch: () => ({
    type: Types.CLEAR_ITEMS
  })
};
