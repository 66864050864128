import styled, { css } from "styled-components";

export const CounterAndFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #686868;
  font-weight: bold;
  position: relative;

  a {
    display: flex;
    align-items: center;
    color: #686868;
  }

  @media screen and (max-width: 680px) {
    > span:first-child {
      padding-left: 10px;
    }
  }
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoadButton = styled.button`
  border: 0;
  background: #fff;
  height: 30px;
  padding: 8px;
  text-transform: uppercase;
  color: #535353;
  border: #535353 solid 1px;
  cursor: pointer;
  margin-top: 16px;

  ${props =>
    props.disabled &&
    css`
      background: #f0f0f0;
      cursor: default;
      color: #ccc;
      border: #ccc solid 1px;
    `}
`;
