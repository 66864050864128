import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";

export default class ConteudoRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;
    return (
      <Fragment>
        <Route
          {...props}
          render={props =>
            1 !== null ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      </Fragment>
    );
  }
}
