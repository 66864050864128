import styled from 'styled-components';
import ImgBackground from '../../assets/img/energia-bg.jpg'

export const LoginWrapper = styled.div`
    display: flex;
    height: 100%;

    @media screen and (max-height: 698px) {
      height: auto;
    }
`

export const LeftSide = styled.div`
    display:flex;
    align-items:center;
    padding: 30px;
    background: #fff;
    width: 500px;
`

export const LeftSideContent = styled.div`
    width:100%;
    min-height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const LogoAndSlogan = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items:center;'

    img {
        width: 100px;
    }

    h4 {
        font-family: 'Futura-Bold', sans-serif;
        text-transform: uppercase;
        color: #535353;
        font-size: 18px;
        margin-left: 15px;
        line-height: 24px;
        width: 100%;
    }
`

export const LoginFormWrapper = styled.div`
    margin-top: 25px;
    flex: 1;

    h4 {
      margin-bottom: 25px;
    }
`

export const LoginBottomLinks = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top:20px;

    div {
        flex:1;
        display:flex;
    }

    a {
        font-size: 12px;
        color: #686868;
        text-decoration: none;

        :hover {
            text-decoration: underline
        }
    }
`

export const RightSide = styled.div`
    flex:2;
    display: flex;
    flex-direction: column;
    background-image: url(${ImgBackground});
    background-size: cover;
    background-repeat:no-repeat;
    background-position-x: 80%;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 40px 0 0 40px;
    @media screen and (max-width: 680px) {
        display: none;
    }
`

export const RightSideFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    span {
        color: #fff;
        margin-right:20px;
        font-size: 12px;

        @media screen and (max-width: 920px) {
            display:none;
        }
    }
    img {
        width: 100px;
        height: 50px;
    }

`

export const TextWrapper = styled.div`
    color: #686868;

    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 16px;
    }

    .badge-title {
      background-color: #FBCE07;
      color: #686868;
      padding: 8px 20px;
      width: fit-content;
      font-family: 'Futura-Bold',sans-serif;
      margin-bottom: 7px;
    }

    span {
      padding: 8px 20px;
      width: fit-content;
      background-color: #fff;
      font-family: 'Futura-Light',sans-serif;
      font-size: 15px;
    }

    h1 {
      color: #fff;
      font-weight: bold;
      font-size: 30px;
      font-family: 'Futura-Bold', sans-serif;
      margin-bottom: 25px;
      white-space: pre-wrap;
      width: 0;
      line-height: 15px;
      margin-bottom: 40px;
    }

    p {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 15px;
      font-weight: 500;
    }
`;

export const FooterStyle = styled.footer`
    display: flex;
    height: 50px;
    justify-content: space-between;
    margin-right: 20px;

    .vertical-divider {
      border: 0.5px solid #A1A1A1;
      height: 15px;
      margin: 0 20px;
    }

    img {
      margin-right: 5px;
    }

    .title-suporte {
      font-weight: bold;
      color: #0c4f92;
      margin-right: 3px;
    }

    div {
        display: flex;
        align-items: center;

        :first-child {

            img {
              width: 100px;
              height: auto;
            }
            span {
              font-size: 12px;
              margin-left: 10px;
              margin-bottom: 10px;
            }
          }
        :last-child {
          display: flex;
          justify-content: flex-end;
            span {
                margin-right: 20px;
                font-weight: bold;
            }

            img {
              margin-right: 0;
            }
        }

        span {
            font-size:12px;
            color:#000;
        }
    }

    @media screen and (max-width: 670px){
      flex-direction: column;
      height: auto;
      div {
        align-self: center;
        :first-child {
          order:2;
          margin: 15px 0;
          justify-content: center;
          padding: 0;
        }

        :nth-child(2) {
          order: 1;
          width: 100%;
          display: flex;
          padding-left: 10px;
          justify-content: center;
          img {
            display: none;
          }
        }
      }
    }
`
