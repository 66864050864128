import styled from 'styled-components';

import { Content as ContentBase } from '../../../styles';

export const Content = styled(ContentBase)`
  position: relative;
  padding-top: 30px;
`;

export const SearchAndViewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  margin-bottom: 30px;

  > div {
    height: 70px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const Materials = styled.div`
  ul {
    display: flex;
    flex-direction: column;
  }
`;
