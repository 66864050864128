import React, { Component } from 'react';
import { Shake as Reshake } from 'reshake'

export const Duration = 250;

/**
 * Como usar:
 *
 * <Shake shake={this.state.shake}><Child /></Shake>
 *
 * Caso precisar dar outro 'shake' é necessário retornar o state para false antes.
 */
export default class Shake extends Component {
  state = {
    shake: false,
    dur: Duration
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shake !== this.props.shake && this.props.shake === true) {
      this.setState({
        shake: true
      })

      setTimeout(() => {
        this.setState({
          shake: false
        })
      }, this.state.dur)
    }
  }

  render() {
    let { children } = this.props;
    let { shake, dur } = this.state;

    return (
      <Reshake
        h={shake ? 20 : 0}
        v={0}
        r={0}
        dur={dur}
        int={0.5}
        max={39}
        fixed={shake}
        freez={false}>
        {children}
      </Reshake>
    )
  }
}
