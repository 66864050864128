import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Content, Filter } from "../../styles";
import CursoItem from "../../components/CursoItem";

import { CounterAndFilterWrapper, LoadMoreWrapper, LoadButton } from "./styles";

import { CursoList } from "../../components/CursoItem/styles";

import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";

import { Creators as CoursesActions } from "../../store/ducks/courses";
import { Creators as SearchOfferActions } from "../../store/ducks/searchOffer";

import Header from "./components/Header";
import Preloader from "../../components/Preloader";
import Shake from "../../components/Shake";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../analytics";

class Categoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: false,
      isSearching: false,
      shake: false,
      title: null
    };
  }

  handleChangeOrderFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleChoiceFilter = event => {
    this.refs.filterSpan.innerHTML = event.target.innerHTML;

    const filterOption = event.target.value;

    this.setState({
      filterOption,
      isFilterOpen: false
    });

    this.props.clearCourses();
    this.props.getCoursesRequest({
      categoryId: this.props.match.params.id,
      order: filterOption
    });

    AnalyticsEvent({
      category: GA_CATEGORIES.ORDER,
      action: GA_ACTIONS.CHANGED_ORDER,
      label: `[${GA_LABELS.CATEGORY} ${this.props.match.params.id}] [Todos] - ${GA_LABELS.ORDER} "${event.target.innerHTML}"`
    });
  };

  componentDidMount() {
    const { getCoursesRequest } = this.props;
    getCoursesRequest({ categoryId: this.props.match.params.id });
  }

  getCountCursos() {
    const { total } = this.props.courses;
    if (Number(total) === 0 || !total) {
      return "Nenhum treinamento disponível";
    } else if (total === 1) {
      return "1 treinamento";
    }

    return total + " treinamentos";
  }

  handleSearch = e => {
    const { value } = e.target;
    const { id } = this.props.match.params;

    this.setState({
      isSearching: !!value.length,
      title: value
    });
    this.props.clearSearch();
    this.props.getSearchRequest({ title: value, categoryId: id });
  };

  renderCourses = () => {
    const { isSearching } = this.state;
    const courses = isSearching ? this.props.searchOffer : this.props.courses;
    return courses.data.map((course, key) => (
      <CursoItem
        icon={course.course.category.icon}
        rating={course.course.rating}
        title={course.title}
        isNew={course.course.new}
        progress={0}
        nivel={course.course.level}
        inicio={course.begin}
        id={course.course.id}
        oferta={course.id}
        key={key}
        modality={course.course.modality}
      />
    ));
  };

  triggerSearchMode = () => {
    this.setState({
      isSearching: !this.state.isSearching
    });

    const { id } = this.props.match.params;

    this.props.clearSearch();
    this.props.getSearchRequest({ categoryId: id });
  };

  nextPage = () => {
    const { isSearching } = this.state;
    const courses = isSearching ? this.props.searchOffer : this.props.courses;
    return courses.lastPage >= courses.page + 1;
  };

  reloadItems = () => {
    const { title, isSearching } = this.state;
    const { id } = this.props.match.params;

    const courses = isSearching ? this.props.searchOffer : this.props.courses;
    const getRequest = isSearching
      ? this.props.getSearchRequest
      : this.props.getCoursesRequest;

    if (this.nextPage()) {
      getRequest({ title, categoryId: id, page: courses.page + 1 });
      AnalyticsEvent({
        category: GA_CATEGORIES.COURSES_LIST,
        action: GA_ACTIONS.CLICKED_LOAD_MORE,
        label: `[${GA_LABELS.CATEGORY} ${id}] [Todos] - ${
          GA_LABELS.PAGE
        } ${courses.page + 1}`
      });
    }
  };

  componentWillUnmount() {
    const { clearCourses, clearSearch } = this.props;
    clearCourses();
    clearSearch();
  }

  render() {
    const { isFilterOpen } = this.state;
    const { courses, searchOffer } = this.props;

    return (
      <Fragment>
        <Header
          handleSearch={this.handleSearch}
          categoryId={this.props.match.params.id}
          mode="all"
          triggerSearchMode={this.triggerSearchMode}
        />

        <Content>
          <CounterAndFilterWrapper>
            <span>{this.getCountCursos()}</span>
            <a href="#!" onClick={this.handleChangeOrderFilter}>
              <span ref="filterSpan">recentes</span>
              <MdArrowDropdown color="#686868" />
            </a>
            {isFilterOpen && (
              <Filter>
                <ul>
                  <li value="0" onClick={this.handleChoiceFilter}>
                    recentes
                  </li>
                  <li value="1" onClick={this.handleChoiceFilter}>
                    mais antigo
                  </li>
                  <li value="2" onClick={this.handleChoiceFilter}>
                    melhor avaliado
                  </li>
                  <li value="3" onClick={this.handleChoiceFilter}>
                    pior avaliado
                  </li>
                </ul>
              </Filter>
            )}
          </CounterAndFilterWrapper>

          <Shake shake={this.state.shake}>
            <CursoList>
              {(courses.loading || searchOffer.loading) && <Preloader />}
              {this.renderCourses()}

              <LoadMoreWrapper>
                {this.nextPage() &&
                (!courses.loading || !searchOffer.loading) ? (
                  <LoadButton onClick={this.reloadItems}>
                    Carregar mais
                  </LoadButton>
                ) : (
                  <LoadButton disabled>Carregar mais</LoadButton>
                )}
              </LoadMoreWrapper>
            </CursoList>
          </Shake>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses,
  searchOffer: state.searchOffer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CoursesActions, ...SearchOfferActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Categoria);
