import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as CategoryActions } from "../../../../store/ducks/category";

import {
  HeaderInfo,
  HeaderInfoInner,
  Header as ContainerHeader,
  TitleAndViewWrapper,
  TitleWrapper,
  ViewWrapper,
  HeaderBtns
} from "../../../../styles";
import Preloader from "../../../../components/Preloader";
import SearchMode from "../SearchMode";

import ReactTooltip from "react-tooltip";
import IosSearch from "react-ionicons/lib/IosSearch";
import PropTypes from "prop-types";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../../../analytics";

class Header extends Component {
  static propTypes = {
    categoryId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    triggerSearchMode: PropTypes.func.isRequired,

    /* by Redux */
    category: PropTypes.shape().isRequired,
    getCategoryRequest: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.consts = {
      mode: {
        inProgress: "in-progress",
        finished: "finished",
        all: "all"
      },

      view: {
        list: "list",
        grid: "grid"
      }
    };

    this.state = {
      isSearchModeActive: false
    };
  }

  componentDidMount() {
    const { getCategoryRequest, categoryId } = this.props;

    getCategoryRequest(categoryId);
  }

  triggerSearchMode = () => {
    this.setState(
      {
        isSearchModeActive: !this.state.isSearchModeActive
      },
      () => {
        if (this.state.isSearchModeActive) {
          AnalyticsEvent({
            category: GA_CATEGORIES.SEARCH,
            action: GA_ACTIONS.CLICKED_ICON_SEARCH,
            label: GA_LABELS.COURSES_LIST
          });
        } else {
          AnalyticsEvent({
            category: GA_CATEGORIES.SEARCH,
            action: GA_ACTIONS.CLICKED_CANCEL_SEARCH,
            label: GA_LABELS.COURSES_LIST
          });
        }
      }
    );

    this.props.triggerSearchMode();
  };

  render() {
    const { category, categoryId, mode } = this.props;
    const { inProgress, finished, all } = this.consts.mode;

    return (
      <HeaderInfo>
        <HeaderInfoInner>
          <ContainerHeader>
            {this.state.isSearchModeActive === false ? (
              <Fragment>
                <span className="label">Categoria</span>
                {category.loading ? (
                  <Preloader />
                ) : (
                  <Fragment>
                    <TitleAndViewWrapper>
                      <TitleWrapper>
                        <h1 className="notranslate">{category.data.title}</h1>
                      </TitleWrapper>
                      <ReactTooltip place="top" effect="solid" />
                      <ViewWrapper>
                        <div onClick={this.triggerSearchMode}>
                          <IosSearch fontSize="26px" color="#fff" />
                        </div>
                      </ViewWrapper>
                    </TitleAndViewWrapper>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <SearchMode
                change={this.props.handleSearch}
                cancel={this.triggerSearchMode}
              />
            )}

            <HeaderBtns>
              <Link
                to={`/categoria/${categoryId}`}
                style={mode === all ? { color: "#f9283d" } : { color: "#fff" }}
              >
                Não iniciados ({category.data.count_available_courses || 0})
              </Link>
              <Link
                to={`/categoria/${categoryId}/em-andamento`}
                style={
                  mode === inProgress ? { color: "#f9283d" } : { color: "#fff" }
                }
              >
                Em andamento ({category.data.count_courses_in_progress || 0})
              </Link>
              <Link
                to={`/categoria/${categoryId}/concluidos`}
                style={
                  mode === finished ? { color: "#f9283d" } : { color: "#fff" }
                }
              >
                Concluídos ({category.data.count_courses_done || 0})
              </Link>
            </HeaderBtns>
          </ContainerHeader>
        </HeaderInfoInner>
      </HeaderInfo>
    );
  }
}

const mapStateToProps = state => ({
  category: state.category
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CategoryActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
