import { call, put } from "redux-saga/effects";

import { Creators as RatingAnswerActions } from "../ducks/ratingAnswer";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as RatingActions } from "../ducks/rating";
import { Creators as SuccessActions } from "../ducks/success";

import api from "../../services/api";

export function* getRatingAnswers(action) {
  try {
    let params = "?course_rating_id=" + action.payload.courseRatingId;
    if (action.payload.page) {
      params += "&page=" + action.payload.page;
    }

    const response = yield call(api.get, "/api/v1/ratinganswers" + params);

    yield put(RatingAnswerActions.getRatingAnswersSuccess(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}

export function* postRatingAnswer(action) {
  try {
    const response = yield call(api.post, "/api/v1/ratinganswers", {
      course_rating_id: action.payload.courseRatingId,
      record: action.payload.rating,
      comment: action.payload.comment
    });

    yield put(RatingAnswerActions.postRatingAnswerSuccess(response.details));
    yield put(RatingActions.getRatingsRequest(action.payload.courseId, 'Avaliação', true));
    yield put(SuccessActions.setSuccess("Avaliação enviada com sucesso", { toasted: true }))
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro na requisição"));
    }
  }
}

export function* setRatingValue(action) {}
