import styled from "styled-components";

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

export const TituloCategoria = styled.h2`
  font-family: "Futura-Bold", sans-serif;
  color: #979797;
  font-size: 14px;
  margin-bottom: 15px;
`;

export const List = styled.ul`
  margin-bottom: 25px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-bottom: 3px;
    padding: 15px;
    cursor: pointer;

    .left-side {
      display: flex;
      flex-direction: column;

      .nome-curso {
        font-size: 16px;
        color: #686868;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .conclusao,
      .nota {
        font-size: 12px;
        color: #686868;
      }
    }

    .right-side {
      img {
        width: 68px;
        height: 68px;
      }
    }
  }
`;

export const ModalStyle = styled.div`
  display: flex;
  justify-content: center;
  > div {
    h2 {
      color: #535353;
      font-family: 'Futura-Bold';
      font-size: 20px;
      margin-bottom: 25px;
      text-transform: uppercase;
      line-height: 24px;
    }

    .btn-wrapper {
      button {
        background-color: #ee1d25;
        border: 0;
        color: #fff;
        font-family: 'Futura-Bold';
        text-transform: uppercase;
        text-align: center;
        padding: 10px 25px;
        cursor: pointer;
        margin-top: 20px;
        :hover {
          opacity: 0.8
        }

        :first-child {
          margin-right: 10px;
        }
      }
    }
  }
`;
