import api from '../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as CategoryActions } from '../ducks/category';
import { Creators as ErrorActions } from '../ducks/error';

export function* getCategory(action) {
  try {
    const response = yield call(api.get, '/api/v1/categories/' + action.payload.id);

    yield put(CategoryActions.getCategorySuccess(response.data.details));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.'));
    }
  }
}
