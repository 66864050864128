import { call, put } from "redux-saga/effects";
import { Creators as HtmlActions } from "../ducks/htmlCourse";
import { Creators as ErrorActions } from "../ducks/error";

import { Creators as SuccessActions } from "../ducks/success";

import api from "../../services/api";

export function* postHtml(data, enrollmentId, topicId) {
  try {
    const response = yield call(api.post, "/api/v1/scorm-user", {
      session_time: data.session_time,
      session_location: data.lesson_location,
      session_status: data.lesson_status,
      score: data.score_raw,
      score_max: data.score_max,
      score_min: data.score_min,
      suspend_data: data.suspend_data,
      enrollment_id: enrollmentId,
      topic_id: topicId
    });

    if (response.data && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return false;
  }
}

export function* putHtml(data, enrollmentId, topicId) {
  try {
    const response = yield call(api.put, "/api/v1/scorm-user", {
      session_time: data.session_time,
      session_location: data.lesson_location,
      session_status: data.lesson_status,
      score: data.score_raw,
      score_max: data.score_max,
      score_min: data.score_min,
      suspend_data: data.suspend_data,
      enrollment_id: enrollmentId,
      topic_id: topicId
    });

    if (response.data && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return false;
  }
}

export function* updateHtml(action) {
  try {
    const response = yield call(api.get, `/api/v1/scorm-user?enrollment_id=${action.payload.enrollmentId}&topic_id=${action.payload.topicId}`);

    if (response.status === 200) {
      let scormExist = response.data;
      const idTopic = action.payload.topicId;
      let data = action.payload.data;

      if (scormExist.status === "ok") {
        yield* postHtml(data, action.payload.enrollmentId, action.payload.topicId);
        yield put(SuccessActions.setSuccess("Tópico concluído!", { toasted: true }));
        action.payload.onConclude();
      } else if (scormExist.topic_id === idTopic && scormExist.session_status !== "completed" && scormExist.session_status !== "passed") {
        yield* putHtml(data, action.payload.enrollmentId, action.payload.topicId);
      }

      const topics = yield call(api.get, `/api/v1/topics?course_id=${action.payload.courseId}&enrollment_id=${action.payload.enrollmentId}`);
      const enrollment = yield call(api.get, "/api/v1/enrollments/" + action.payload.enrollmentId);

      if (topics.data.data && (enrollment.data.details) && enrollment.data.details.status === 'Em andamento') {
        let completedTopics = 0;

        topics.data.data.forEach(topic => {
          if (topic.scormUser && (topic.scormUser.session_status === 'completed' || topic.scormUser.session_status === 'passed')) {
            completedTopics++;
          }
        });

        if (topics.data.data.length === completedTopics) {
          const concluded = yield call(api.put, "/api/v1/enrollments/" + action.payload.enrollmentId + "/conclusion");

          if (concluded.data.details.status === 'Concluído') {
            yield put(SuccessActions.setSuccess("Treinamento concluído com sucesso!", { toasted: true }));
          }
        }
      }
    }

    yield put(HtmlActions.getHTMLCourseSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro na requisição get"));
    }
  }
}

