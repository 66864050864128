import React, {Component} from 'react'

export class IconDownload extends Component {
  render() {
    return (
      <svg
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          fill="#6BB64B"
          d="M25.89,36.75l7.02-7.03c0.49-0.49,0.49-1.27,0-1.76c-0.49-0.49-1.27-0.49-1.76,0l-4.9,4.89V14.14
			c0-0.69-0.56-1.25-1.25-1.25s-1.25,0.56-1.25,1.25v18.7l-4.9-4.89c-0.49-0.49-1.27-0.49-1.76,0c-0.49,0.49-0.49,1.27,0,1.76
			l7.02,7.03c0.25,0.24,0.56,0.36,0.89,0.36S25.64,36.99,25.89,36.75z M2.5,25C2.5,12.59,12.59,2.5,25,2.5S47.5,12.59,47.5,25
			S37.41,47.5,25,47.5S2.5,37.41,2.5,25z M50,25C50,11.22,38.78,0,25,0S0,11.22,0,25s11.22,25,25,25S50,38.78,50,25z"
        />
      </svg>
    );
  }
}

export class IconCheckMarck extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="30px"
        width="30px"
        fill="#3BB300"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        xmlSpace="preserve">
          <path d="M50,13.9c-19.9,0-36.1,16.2-36.1,36.1S30.1,86.1,50,86.1S86.1,69.9,86.1,50S69.9,13.9,50,13.9z M45.5,63.4L30.2,48l3.5-3.5  l11.9,11.9l20.8-19.8l3.4,3.6L45.5,63.4z"/>
      </svg>
    );
  }
}

export class IconArrowLeft extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="19" height="19" viewBox="0 0 1024 1024" rotate="0">
        <path d="M704 256.8l-64.6-64.8-319.4 320 319.4 320 64.6-64.8-254.6-255.2z" fill="#ffffff"></path>
      </svg>
    );
  }
}

export class IconLogout extends Component {
  render() {
    return (
      <svg height="20px" viewBox="0 0 511 512" width="20px" xmlns="http://www.w3.org/2000/svg">
        <path d="m361.5 392v40c0 44.113281-35.886719 80-80 80h-201c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h201c44.113281 0 80 35.886719 80 80v40c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-40c0-22.054688-17.945312-40-40-40h-201c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h201c22.054688 0 40-17.945312 40-40v-40c0-11.046875 8.953125-20 20-20s20 8.953125 20 20zm136.355469-170.355469-44.785157-44.785156c-7.8125-7.8125-20.476562-7.8125-28.285156 0-7.8125 7.808594-7.8125 20.472656 0 28.28125l31.855469 31.859375h-240.140625c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h240.140625l-31.855469 31.859375c-7.8125 7.808594-7.8125 20.472656 0 28.28125 3.90625 3.90625 9.023438 5.859375 14.140625 5.859375 5.121094 0 10.238281-1.953125 14.144531-5.859375l44.785157-44.785156c19.496093-19.496094 19.496093-51.214844 0-70.710938zm0 0" fill="#ffffff"/>
      </svg>
    );
  }
}
