import { photoURL } from "../config";

export default {
  getUserPhoto: user => {
    let fileId = null;
    if (!!user.file_id) {
      fileId = user.file_id;
    }
    if (!!user.file) {
      fileId = user.file.id;
    }
    return !fileId ? null : photoURL + "/" + fileId;
  },
  getPhoto: fileId => {
    return !fileId ? null : photoURL + "/" + fileId;
  }
};
