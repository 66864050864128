import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'react-responsive-modal';
import { IconDownload, IconCheckMarck } from '../../../../components/SVGs';
import moment from 'moment';

import { Creators as SupportMaterialDownloadActions } from '../../../../store/ducks/materials/download';
import { Creators as SupportMaterialAcceptanceActions } from '../../../../store/ducks/materials/acceptance';
import {
  SupportMaterialItem,
  ModalContainer,
  ModalContent,
  ButtonContainer,
  CancelButton,
  DownloadButton,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
 } from './styles';

 export function Checkbox ({ className, checked, ...props }) {
  return (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
  )
}

export class SupportMaterialFile extends Component {

  state = {
    open: false,
    termoAccepetd: false,
  }

  componentDidUpdate() {
    if (this.props.acceptance.success === true) {
      this.setState({
        open: false
      }, () => {
        this.props.resetAcceptance();
        this.props.refresh();
      });
    }
  }

  handleDownloadButton = (file, supportMaterial) => {
    const { termoAccepetd } = this.state;

    if (file.needs_acceptance) {
      if (file.acceptance === null && termoAccepetd === false) {
        this.setState({open: true})
      } else if (file.acceptance === null && termoAccepetd === true) {
        this.props.postRegisterAcceptanceRequest({
          id: file.id,
          support_material_id: supportMaterial.id,
          file_id: file.file.id,
          filename: `${file.file.title}.${file.file.extension}`,
        });
      } else {
        this.downloadFile(file.file, supportMaterial.id)
      }
    } else {
      return this.downloadFile(file.file, supportMaterial.id)
    }
  }

  downloadFile = (item, supportMaterialId) => {
    this.props.getSupportMaterialDownloadRequest({
      id: supportMaterialId,
      file_id: item.id,
      filename: `${item.title}.${item.extension}`,
    });
  };

  render() {
    const { item, supportMaterials } = this.props;
    const { termoAccepetd } = this.state;

    return (
      <SupportMaterialItem>
        <div className="leftSide">
          <div>
            <div className="badge">{item.file.subtype}</div>
            <span>{item.file.title}</span>
          </div>
          <div className="box">
            <p>{item.file.description}</p>
          </div>
          {item.acceptance && (
            <div className="box border">
              <IconCheckMarck />
              <p>Aceite registrado em {moment(item.acceptance.accepted_at).format("DD/MM/YYYY [às] HH:mm:ss")}</p>
            </div>
          )}
        </div>
        <div className="rightSide">
          <div onClick={() => this.handleDownloadButton(item, supportMaterials.data)} data-testid="download-div">
            <div>
              <IconDownload />
            </div>
            <span>DOWNLOAD</span>
          </div>
          <Modal
            open={this.state.open}
            onClose={() => this.setState({open: false})}
            styles={{
                overlay: { backgroundColor: "rgba(125, 125, 125, 0.7)" },
                modal: { borderRadius: "5px", padding:"0px" }
              }}
            showCloseIcon={true}
            focusTrapped={false}
            center
            blockScroll={false}
          >
            <ModalContainer>
            <span className="title">
              Download do arquivo
            </span>
            <ModalContent>
              <div>
                <label>
                  <Checkbox
                    checked={termoAccepetd}
                    onChange={() => this.setState({termoAccepetd: !termoAccepetd})}
                  />
                </label>
              </div>
              <div className="text">
                {item.acceptance_text}
              </div>
            </ModalContent>
            <ButtonContainer>
              <CancelButton
                onClick={() => this.setState({open: false})}
              >
                Cancelar
              </CancelButton>
              <DownloadButton
                checked={termoAccepetd ? 'ativo' : 'inativo'}
                onClick={() => this.handleDownloadButton(item, supportMaterials.data)}
              >
                {this.props.acceptance.loading === true ? 'Baixando ...' : 'Download'}
              </DownloadButton>
            </ButtonContainer>
            </ModalContainer>
          </Modal>
        </div>
      </SupportMaterialItem>
    );
  }
}

const mapStateToProps = state => ({
  supportMaterialDownload: state.supportMaterialDownload,
  acceptance: state.acceptance,
});

const mapDispatchToProps = dispatch =>
bindActionCreators(
  {
    ...SupportMaterialDownloadActions,
    ...SupportMaterialAcceptanceActions,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportMaterialFile);
