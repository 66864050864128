import { call, put } from "redux-saga/effects";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { Creators as LoginActions } from "../ducks/auth";
import { Creators as ErrorActions } from "../ducks/error";
import { Creators as UserInfoActions } from "../ducks/userInfo";
import { getUserInfo } from "../sagas/userInfo";

import api from "../../services/api";

export function* postLogin(action) {
  try {
    const response = yield call(api.post, "/api/v1/auth/signin", {
      username: action.payload.login.trim(),
      password: action.payload.password
    });
    if (response.data && response.status === 200) {
      const dataToken = response.data.token;
      const userId = jwtDecode(dataToken);

      localStorage.setItem("@emae-cursos:token", response.data.token);
      localStorage.setItem("@emae-cursos:refreshToken", response.data.refreshToken);

      yield put(
        LoginActions.getLoginSuccess({ ...response.data, user_id: userId.uid })
      );
      yield* getUserInfo();
    } else {
      yield put(LoginActions.getFailure());
    }
  } catch (err) {
    yield put(LoginActions.getFailure());
    if (!err.response) {
      return yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde.",
          { toasted: true }
        )
      );
    }
    if (err.response.status === 400) {
      yield put(
        ErrorActions.setError("Login ou senha inválidos.", { toasted: true })
      );
    } else if (err.response.status === 401) {
      if (err.response.data.details[0].field === "email") {
        yield put(
          ErrorActions.setError("O e-mail é um campo obrigatório.", {
            toasted: true
          })
        );
      } else if (err.response.data.details[0].field === "password") {
        yield put(
          ErrorActions.setError("Favor informar a senha.", { toasted: true })
        );
      }
    } else if (err.response.status === 500) {
      yield put(
        ErrorActions.setError("Login o senha inválidos.", { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde.",
          { toasted: true }
        )
      );
    }
  }
}

export function* loadLocalUser() {
  const token = localStorage.getItem("@emae-cursos:token");
  const refreshToken = localStorage.getItem("@emae-cursos:refreshToken");
  if (!token || !refreshToken) {
    return yield* signOut();
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common["Accept-Language"] = "pt-BR";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common.Accept = "application/json";
  yield* getUserInfo();
  yield put(LoginActions.getLoginSuccess({ token, refreshToken }));
}

export function* signOut() {
  localStorage.removeItem("@emae-cursos:token");
  localStorage.removeItem("@emae-cursos:refreshToken");
  localStorage.removeItem("@emae-cursos:userInfo");
  yield put(UserInfoActions.userInfoReset());
  yield put(LoginActions.logout());
}
