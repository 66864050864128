import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as EnrollmentInProgressActions } from "../../../store/ducks/enrollmentInProgress";

import { Content, Filter } from "../../../styles";
import CursoItem from "../../../components/CursoItem";

import {
  CounterAndFilterWrapper,
  LoadMoreWrapper,
  LoadButton
} from "../styles";

import { CursoList } from "../../../components/CursoItem/styles";

import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";

import Header from "../components/Header";
import Preloader from "../../../components/Preloader";
import Shake from "../../../components/Shake";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../../analytics";

class Categoria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: false,
      isSearching: false,
      shake: false,
      title: null
    };
  }

  handleChangeOrderFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleChoiceFilter = event => {
    this.refs.filterSpan.innerHTML = event.target.innerHTML;

    const filterOption = event.target.value;

    this.setState({
      filterOption,
      isFilterOpen: false
    });

    this.props.clearEnrollmentInProgress();
    this.props.getEnrollmentInProgressRequest({
      categoryId: this.props.match.params.id,
      order: filterOption
    });

    AnalyticsEvent({
      category: GA_CATEGORIES.ORDER,
      action: GA_ACTIONS.CHANGED_ORDER,
      label: `[${GA_LABELS.CATEGORY} ${
        this.props.match.params.id
      }] [Em andamento] - ${GA_LABELS.ORDER} "${event.target.innerHTML}"`
    });
  };

  componentWillUnmount() {
    this.props.clearEnrollmentInProgress();
  }

  componentDidMount() {
    const { getEnrollmentInProgressRequest } = this.props;
    getEnrollmentInProgressRequest({ categoryId: this.props.match.params.id });
  }

  getCountCursos() {
    const { data } = this.props.enrollmentInProgress;

    if (data.length === 0 || !data.length) {
      return "Nenhum treinamento disponível";
    } else if (data.length === 1) {
      return "1 treinamento";
    }

    return data.length + " treinamentos";
  }

  handleSearch = e => {
    const { value } = e.target;
    const { id } = this.props.match.params;

    this.setState({
      isSearching: !!value.length,
      title: value
    });

    this.props.clearEnrollmentInProgress();
    this.props.getEnrollmentInProgressRequest({ title: value, categoryId: id });
  };

  renderCourses = () => {
    const courses = this.props.enrollmentInProgress;
    if (!courses) {
      return;
    }

    return courses.data.map((enroll, key) => {
      const offer = enroll.offer;
      return (
        <CursoItem
          icon={offer.course.category.icon}
          rating={offer.course.rating}
          title={offer.title}
          isNew={offer.course.new}
          progress={this.calcProgress(enroll.id, offer.course.topics)}
          nivel={offer.course.level}
          inicio={enroll.begin}
          id={offer.course.id}
          idMatricula={enroll.id}
          oferta={offer.id}
          key={key}
          modality={offer.course.modality}
        />
      );
    });
  };

  calcProgress = (enrollId, topics) => {
    let completed = 0;
    if (topics.length > 0) {
      for (let i = 0; i < topics.length; i++) {
        if (topics[i].scormUser) {
          if (
            topics[i].scormUser.enrollment_id === enrollId &&
            (topics[i].scormUser.session_status === "completed" ||
              topics[i].scormUser.session_status === "passed")
          ) {
            completed++;
          }
        }
      }
      return Number((completed / topics.length) * 100).toFixed(0);
    }
    return 0;
  };

  triggerSearchMode = () => {
    this.setState({
      isSearching: !this.state.isSearching
    });

    const { getEnrollmentInProgressRequest } = this.props;
    this.props.clearEnrollmentInProgress();
    getEnrollmentInProgressRequest({ categoryId: this.props.match.params.id });
  };

  reloadItems = () => {
    const { id } = this.props.match.params;
    const { title } = this.state;
    const { enrollmentInProgress } = this.props;

    if (this.nextPage()) {
      this.props.getEnrollmentInProgressRequest({
        title,
        categoryId: id,
        page: enrollmentInProgress.page + 1
      });

      AnalyticsEvent({
        category: GA_CATEGORIES.COURSES_LIST,
        action: GA_ACTIONS.CLICKED_LOAD_MORE,
        label: `[${GA_LABELS.CATEGORY} ${id}] [Em andamento] - ${
          GA_LABELS.PAGE
        } ${enrollmentInProgress.page + 1}`
      });
    }
  };

  nextPage = () => {
    const { enrollmentInProgress } = this.props;
    return enrollmentInProgress.lastPage >= enrollmentInProgress.page + 1;
  };

  render() {
    const { isFilterOpen } = this.state;
    const { enrollmentInProgress } = this.props;

    return (
      <Fragment>
        <Header
          handleSearch={this.handleSearch}
          categoryId={this.props.match.params.id}
          mode="in-progress"
          triggerSearchMode={this.triggerSearchMode}
        />

        <Content>
          <CounterAndFilterWrapper>
            <span>{this.getCountCursos()}</span>
            <a href="#!" onClick={this.handleChangeOrderFilter}>
              <span ref="filterSpan">recentes</span>
              <MdArrowDropdown color="#686868" />
            </a>
            {isFilterOpen && (
              <Filter>
                <ul>
                  <li value="0" onClick={this.handleChoiceFilter}>
                    recentes
                  </li>
                  <li value="1" onClick={this.handleChoiceFilter}>
                    mais antigo
                  </li>
                  <li value="2" onClick={this.handleChoiceFilter}>
                    melhor avaliado
                  </li>
                  <li value="3" onClick={this.handleChoiceFilter}>
                    pior avaliado
                  </li>
                </ul>
              </Filter>
            )}
          </CounterAndFilterWrapper>

          <Shake shake={this.state.shake}>
            <CursoList>
              {enrollmentInProgress.loading && <Preloader />}
              {this.renderCourses()}
            </CursoList>
            <LoadMoreWrapper>
              {this.nextPage() && !enrollmentInProgress.loading ? (
                <LoadButton onClick={this.reloadItems}>
                  Carregar mais
                </LoadButton>
              ) : (
                <LoadButton disabled>Carregar mais</LoadButton>
              )}
            </LoadMoreWrapper>
          </Shake>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enrollmentInProgress: state.enrollmentInProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...EnrollmentInProgressActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Categoria);
