import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'courses/GET_REQUEST',
  GET_SUCCESS: 'courses/GET_SUCCESS',
  CLEAR_ITEMS: 'courses/CLEAR_ITEMS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  page: null,
  lastPage: null
});

export default function courses(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: [...state.data, ...action.payload.data],
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        total: action.payload.total,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.CLEAR_ITEMS:
      return {
        data: [],
        loading: false,
        success: false,
        page: null,
        lastPage: null
      }
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getCoursesRequest: payload => ({
    type: Types.GET_REQUEST,
    payload
  }),

  getCoursesSuccess: ({ data, page, lastPage, total }) => ({
    type: Types.GET_SUCCESS,
    payload: { data, page, lastPage, total }
  }),

  clearCourses: () => ({
    type: Types.CLEAR_ITEMS
  })
};
