import React, { Component } from "react";
import { LoginWrapper, LeftSide, RightSide } from "../Login/styles";
import { CommonTextWrapper, RedButton } from "../../styles";
import LogoDp from "../../assets/img/dp-logotipo.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefinePasswordActions from "../../store/ducks/definePassword";

import Loader from "../Login/components/Loader";

import { FormInput, FormSubmitButton } from "../../components/Form";
import { FormItemWrapper } from "../../components/Form/styles";

class ForgotPassword extends Component {
  static propTypes = {
    definePasswordRequest: PropTypes.func.isRequired
  };

  state = {
    error: false,
    success: false,
    password: "",
    repassword: ""
  };

  handleRedirect = () => {
    return (window.location.href = "/");
  }

  handleSubmitForm = e => {
    e.preventDefault();
    this.setState({ error: "" });
    if (!this.state.password) {
      return this.setState({ error: "Preencha todos os campos" });
    }
    if (this.state.password !== this.state.repassword) {
      return this.setState({ error: "Senhas diferentes" });
    }
    if (this.state.password.length < 8) {
      return this.setState({ error: "É necessário ter mais que 8 caracteres" });
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    const token = urlParams.get("token");
    if (!token) {
      return this.setState({ error: "Token não informado" });
    }
    this.props.definePasswordRequest(token, this.state.password);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isLoading = () => {
    return this.props.definePassword.loading;
  };

  renderError = () => {
    if (!this.props.definePassword.error && !this.state.error) {
      return null;
    }
    return (
      <h2 style={{ color: "#ec0606", "font-size": "15px", margin: "5px" }}>
        {this.props.definePassword.error.title || this.state.error}
      </h2>
    );
  };

  renderLoading = () => {
    if (!this.props.definePassword.loading) {
      return null;
    }
    return (
      <h2 style={{ "font-size": "15px", margin: "5px" }}>
        Carregando alteração de senha...
      </h2>
    );
  };

  renderSuccess = () => {
    if (!this.props.definePassword.success) {
      return null;
    }
    return (
      <h2 style={{ color: "#14d448", "font-size": "15px", margin: "5px" }}>
        Senha alterada com sucesso.
      </h2>
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.definePassword.success && !state.success) {
      state.password = "";
      state.success = true;
    }
    return state;
  }

  render() {
    let { password, repassword } = this.state;

    return (
      <LoginWrapper>
        <LeftSide
          style={{
            alignItems: "unset",
            paddingTop: "40px",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <CommonTextWrapper>
            <h1>Definir Senha</h1>
            <div style={{ maxHeight: "500px" }}>
              <form onSubmit={this.handleSubmitForm}>
                {this.renderError()}
                {this.renderLoading()}
                {this.renderSuccess()}
                <FormItemWrapper>
                  <FormInput
                    label="Senha"
                    placeholder={""}
                    name="password"
                    type="password"
                    onChange={this.handleInputChange}
                    value={password}
                  />
                  <FormInput
                    label="Repetir senha"
                    placeholder={""}
                    name="repassword"
                    type="password"
                    onChange={this.handleInputChange}
                    value={repassword}
                  />
                </FormItemWrapper>
                <FormSubmitButton
                  disabled={this.isLoading()}
                  label={this.isLoading() ? <Loader /> : "Definir Senha"}
                />
              </form>
            </div>
          </CommonTextWrapper>

          <RedButton
            style={{
              alignSelf: "center",
              padding: "15px 50px",
              marginTop: "20px",
              marginBottom: "20px"
            }}
            onClick={this.handleRedirect}
          >
            Voltar para o login
          </RedButton>

          <a
            href="https://portugues.digitalpages.com.br"
            style={{ alignSelf: "center" }}
          >
            <img src={LogoDp} alt="Digital Pages" width="100" />
          </a>
        </LeftSide>
        <RightSide>r</RightSide>
      </LoginWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...DefinePasswordActions }, dispatch);

const mapStateToProps = state => ({ definePassword: state.definePassword });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
