import styled, { css } from "styled-components";

export const AppLogin = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 680px) {
    height: auto;
    min-height: 100%;
  }
`;

export const ContainerLogin = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  background-color: #f0f0f0;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  padding-bottom: 30px;
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  min-height: 100%;
  background: transparent;
  margin-top: 50px;

  @media screen and (max-width: 680px) {
    width: 100%;
  }
`;

export const HeaderInfo = styled.div`
  width: 100%;
  background-color: #0c4f92;
  padding: 15px 0;
`;

export const HeaderInfoInner = styled.div`
  width: 80%;
  margin: 0 auto;
  color: #fff;
  @media screen and (max-width: 680px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .label {
    font-size: 18px;
    font-family: "Futura-Light", sans-serif;
    margin-bottom: 10px;
  }
`;

export const TitleAndViewWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

export const TitleWrapper = styled.div`
  h1 {
    font-size: 42px;
    font-family: "Futura-Bold", sans-serif;
    line-height: 42px;
  }

  ${props =>
    props.forMobile &&
    css`
      @media screen and (min-width: 1000px) {
        width: 70%;
      }
    `}
`;

export const ViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 15px;

  div {
    cursor: pointer;
    margin-right: 10px;
    width: 26px;
    height: 26px;
    flex: 1;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

export const HeaderBtns = styled.div`
  margin-top: 20px;
  a {
    color: #fff;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const FilterBox = styled.div`
  width: 280px;
  margin-top: 10px;
`;

export const Filter = styled.div`
  background-color: #f0f0f0;
  border: 1px dashed #686868;
  border-top: 0;
  position: absolute;
  top: 30px;
  right: 0;

  li {
    padding: 5px 10px;
    cursor: pointer;
    :hover {
      background: #e0e0e0;
    }
  }
`;
export const LoadButton = styled.button`
  border: 0;
  background: #fff;
  height: 30px;
  padding: 8px;
  text-transform: uppercase;
  color: #535353;
  border: #535353 solid 1px;
  cursor: pointer;
  margin-top: 16px;

  ${props =>
    props.disabled &&
    css`
      background: #f0f0f0;
      cursor: default;
      color: #ccc;
      border: #ccc solid 1px;
    `}
`;

export const ModalInner = styled.div`
  .header {
    padding: 10px;
    border-bottom: 2px solid #ccc;

    h2 {
      font-family: "Futura-Bold", sans-serif;
      color: #535353;
    }
  }

  .body {
    padding: 10px;
  }
`;

export const SquareButton = styled.button`
  color: #fff;
  font-family: "Futura-Bold", sans-serif;
  padding: 10px 15px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;

  ${props =>
    props.loading &&
    css`
      width: 150px;
    `}

  :hover {
    opacity: 0.8;
  }
`;

export const RedButton = styled(SquareButton)`
  background-color: #ee1d25;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(SquareButton)`
  background-color: #ccc;
  color: #fff;
`;

export const CommonTextWrapper = styled.div`
  h1,
  h2 {
    font-weight: bold;
    font-size: 18px;
    font-family: "Futura-Bold", sans-serif;
    margin-bottom: 25px;
    text-transform: uppercase;
    color: #535353;
  }

  h2 {
    text-transform: none;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  p {
    color: #9b9b9b;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
    font-weight: 500;
  }
`;
export const TableDefault = styled.table`
  width: 100%;
  font-size: 15px;
  border-spacing: 0;
  color: #535353;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  thead {
    background-color: #fbce07;
    font-family: "Futura-Bold", sans-serif;
    color: #666;
  }

  tr > td:first-child,
  tr > th:first-child {
    padding-left: 15px;
  }

  td,
  th {
    padding: 10px;
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f7f7f7;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }

      td {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }
`;
