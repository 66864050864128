import React from "react";
import ReactGA from "react-ga";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

var ANALYTICS_KEY = "";//UA-143856870-1";
var INSIGHTS_CONNECTION = null;//process.env.INSIGHTS_CONNECTION;

if (window.location.host.indexOf('hmg') != -1 || window.location.host.indexOf('localhost') != -1)
{
  INSIGHTS_CONNECTION = "InstrumentationKey=be701437-678d-4aa7-a6eb-879e46d29f3e;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/";
}else
{ 
  ANALYTICS_KEY = "UA-143856870-1";
  INSIGHTS_CONNECTION ="InstrumentationKey=1cc41574-7893-424e-a0bc-96f3a50d8d9b;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/";
}

let appInsights = null;

if (INSIGHTS_CONNECTION)
{ 
  appInsights = new ApplicationInsights({ config: {
    connectionString: INSIGHTS_CONNECTION,
    autoTrackPageVisitTime : true,
    enableAutoRouteTracking	: true,
    maxBatchInterval : 2000
  } });
}

export function AnalyticsInit() {
  ReactGA.initialize(ANALYTICS_KEY);
  if (appInsights) appInsights.loadAppInsights();
}

export function AnalyticsEvent(data) {
  const { category, action, label } = data;

  ReactGA.event({
    category,
    action,
    label
  });
}

export function CustomEvent(data)
{
  // console.log(data);
  if (appInsights) appInsights.trackEvent({name:"xapi"}, {full_statement:data});
}

export function AnalyticsPageview() {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function AnalyticsExternalLink(data) {
  const { content, style, label, url } = data;
  return (
    <ReactGA.OutboundLink
      eventLabel={`Link Externo: ` + label}
      to={url}
      target="_blank"
      style={style}
    >
      {content}
    </ReactGA.OutboundLink>
  );
}

export const GA_CATEGORIES = {
  AUTH: "Autenticação",
  ITEM_VISUALIZATION: "Visualização de itens",
  SEARCH: "Busca",
  ORDER: 'Ordenação',
  OUTRO: 'Outro',
  ENROLLMENT: 'Matrícula',
  CONTENT: 'Conteúdo',
  COURSES_LIST: 'Listagem de cursos'
};

export const GA_ACTIONS = {
  // AUTH
  CLICKED_BUTTON_ENTER: 'Clicou no botão "Entrar"',
  CLICKED_FINISH_SESSION: 'Clicou em "Encerrar sessão"',
  // ITEM_VISUALIZATION
  CLICKED_BUTTON_LIST_VIEW: 'Clicou no botão "Visualizar em lista"',
  CLICKED_BUTTON_GRID_VIEW: 'Clicou no botão "Visualizar em grade"',
  // SEARCH
  SEARCHED: 'Realizou busca',
  CLICKED_ICON_SEARCH: 'Clicou no ícone de "Busca" (lupa)',
  CLICKED_CANCEL_SEARCH: 'Clicou no botão "Cancelar busca"',
  // ORDER
  CHANGED_ORDER: 'Trocou ordenação',
  // OUTRO
  CLICKED_LOAD_MORE: 'Clicou no botão "Carregar mais"',
  // ENROLLMENT
  STARTED_ENROLLMENT: 'Iniciou curso',
  CONTINUED_ENROLLMENT: 'Clicou no botão "Continuar"',
  CLICKED_TRAIL_ITEM: 'Clicou em um item da trilha',
  // CONTENT
  CLICKED_OPEN_TRAIL: 'Clicou no botão "Abrir Trilha"',
  CLICKED_CLOSE_TRAIL: 'Clicou no botão "Fechar Trilha"',
  CLICKED_BACK_BUTTON: 'Clicou no botão "Voltar"',
  CLICKED_MODULE: 'Clicou em um módulo',
  FINISHED_MODULE: 'Concluiu um módulo',
};

export const GA_LABELS = {
  TERM: 'Termo: ',
  CATEGORIES_LIST: 'Listagem de categorias',
  COURSES_LIST: 'Listagem de cursos',
  CATEGORY: 'id_categoria: ',
  ORDER: 'Ordem: ',
  PAGE: 'Página: ',
  COURSE: 'id_curso: ',
  OFFER: 'id_oferta: ',
  ENROLLMENT: 'id_matricula: ',
  TITLE: 'Título: ',
  MODULE: 'Módulo: ',
  MODULE_ID: 'id_modulo: ',
}
