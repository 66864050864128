import React, { Component } from 'react';

import { FooterStyle } from './styles'
import ImgShellFooter from '../../assets/img/emae-footer@2x.png'
import DpLogotipo from '../../assets/img/dp-logotipo.png'
// import LogoGooglePlay from '../../assets/img/logo-google-play.png'
// import LogoAppleStore from '../../assets/img/logo-apple-store.png'

export default class Footer extends Component {
  render() {
    return (
      <FooterStyle>
        <div>
          <a href='https://portugues.digitalpages.com.br/' rel='noopener noreferrer' target='_blank'>
            <img src={DpLogotipo} alt='Digital Pages' />
          </a>
          <span>Versão 0.11.37</span>
        </div>
        {/* <div>
          <span className="title-suporte">Precisa de suporte?</span>
          <span>emae@digitalpages.com.br</span>
          <div className="vertical-divider"></div>
          <a href="#!" target="_blank">
            <img src={LogoGooglePlay} alt='Google Play' />
          </a>

          <a href="https://pocs.digitalpages.com.br/shell">
            <img src={LogoAppleStore} alt='Apple Store' />
          </a>
        </div> */}
        <div>
          <span>©2020 EMAE. Todos os direitos reservados.</span>
          <img src={ImgShellFooter} alt='Shell' />
        </div>
      </FooterStyle>
    )
  }
}
