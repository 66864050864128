var baseURL = "https://emae-cursos.digitalpages.com.br";
var baseEmaeApiURL = "https://emae.digitalpages.com.br";
var photoURL = "https://emae-cursos.digitalpages.com.br/api/v1/users/profile-picture";

if (window.location.host.indexOf('hmg') != -1 || window.location.host.indexOf('localhost') != -1)
{
  baseURL = "https://hmgemae-cursos.digitalpages.com.br";
  baseEmaeApiURL = "https://hmgemae.digitalpages.com.br";
  photoURL = "https://hmgemae-cursos.digitalpages.com.br/api/v1/users/profile-picture";
}

module.exports = {
  baseURL: baseURL,
  baseEmaeApiURL: baseEmaeApiURL,
  photoURL: photoURL,
  exibirInfoNovosTreinamentos: false
};
