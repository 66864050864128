import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as CoursesActions } from "../ducks/courses";
import { Creators as ErrorActions } from "../ducks/error";

export function* getCourses(action) {
  try {
    let url = "/api/v1/offers";
    if (action.payload.categoryId) {
      url += `?category_id=${action.payload.categoryId}`;
    }
    if (action.payload.page) {
      url += `&page=${action.payload.page}`;
    }
    if (!!action.payload.order) {
      url += `&order=${action.payload.order}`;
    } else {
      url += `&order=0`;
    }

    const response = yield call(api.get, url);

    yield put(CoursesActions.getCoursesSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
