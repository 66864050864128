import React, { Component } from 'react';

import { PreloaderStyle } from './styles'

export default class Preloader extends Component {
  render() {
    return (
      <PreloaderStyle/>
    )
  }
}
