import styled from 'styled-components';

export const Counter = styled.div`
  margin-bottom: 15px;

  > p {
    font-size: 16px;
    color: #585858;
  }
`;

export const MaterialContent = styled.div`
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SupportMaterialWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;



export const MaterialDadosWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  min-height: 100%;
  background: transparent;

  @media screen and (max-width: 680px) {
    width: 100%;
  }
`;

export const MaterialDados = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -50px;

  div {
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    :not(:last-child) {
      margin-right: 2px;
    }

    span {
      color: #535353;
      font-size: 14px;

      :nth-child(2) {
        font-size: 20px;
        color: #9b9b9b;
        font-family: 'Futura-Bold', sans-serif;
        margin-top: 5px;
      }
    }

    @media screen and (max-width: 680px) {
      padding: 10px;
      :not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
  }
`;
