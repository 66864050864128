import axios from "axios";
import { baseURL, baseEmaeApiURL } from "../config";
import { Creators as AuthActions } from "../store/ducks/auth";

export const emaeApiURL = baseEmaeApiURL;

const api = axios.create({
  baseURL,
  headers: { "Accept-Language": "pt-BR" }
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem("@emae-cursos:token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.common["Accept-Language"] = "pt-BR";
    config.headers.common["Content-Type"] = "application/json";
    config.headers.common.Accept = "application/json";
  }

  return config;
});

async function refreshToken(originalRequest, dispatch, getState) {
  let refreshToken = localStorage.getItem("@emae-cursos:refreshToken");
  if (!refreshToken) {
    throw new Error("Token not found");
  }
  let response = await api.post("/api/v1/auth/token/refresh", {
    refresh_token: refreshToken
  });
  if (!response.data || response.status !== 200) {
    throw new Error();
  }
  const dataToken = response.data.token;
  refreshToken = response.data.refresh_token || response.data.refreshToken;
  localStorage.setItem("@emae-cursos:token", `${dataToken}`);
  localStorage.setItem("@emae-cursos:refreshToken", refreshToken);

  api.defaults.headers.common.Authorization = `Bearer ${dataToken}`;
  originalRequest.headers.Authorization = `Bearer ${dataToken}`;

  return api.request(originalRequest);
}

export function createRefreshTokenInterceptor(dispatch, getState) {
  api.interceptors.response.use(
    response => response,
    error => {
      return new Promise((resolve, reject) => {
        const originalRequest = error.config;
        if (error.response.status !== 401 || originalRequest._retry) {
          return reject(error);
        }
        refreshToken(originalRequest, dispatch, getState)
          .then(request => {
            resolve(request);
          })
          .catch(err => {
            setTimeout(() => {
              refreshToken(originalRequest, dispatch, getState)
                .then(request => {
                  resolve(request);
                })
                .catch(err => {
                  setTimeout(() => {
                    refreshToken(originalRequest, dispatch, getState)
                      .then(request => {
                        resolve(request);
                      })
                      .catch(err => {
                        dispatch(AuthActions.logoutRequest());
                        reject(error);
                      });
                  }, 200);
                });
            }, 200);
          });
      });
    }
  );
}

export default api;

export const apiQuiz = axios.create({
  baseURL: baseEmaeApiURL
});

apiQuiz.interceptors.request.use(config => {
  const token = localStorage.getItem("@emae-cursos:token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.common["Accept-Language"] = "pt-BR";
    config.headers.common["Content-Type"] = "application/json";
    config.headers.common.Accept = "application/json";
  }

  return config;
});
