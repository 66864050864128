import React, { Component, Fragment } from "react";
import Header from "./components/Header";
import Sidenav from "./components/Sidenav";
import { ContainerStyled, IframeStyled, ModalStyle, ModalConclusaoInner, SpanErrorStyled } from "./styles";
import Loading from "../../components/Loading";
import IframeComm from "react-iframe-comm";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { FormInput } from "../../components/Form";
import RatingModal from "./components/RatingModal";
import { bindActionCreators } from "redux";
import { Creators as EnrollmentActions } from "../../store/ducks/enroll";
import { Creators as CourseActions } from "../../store/ducks/course";
import { Creators as TopicsActions } from "../../store/ducks/topics";
import { Creators as ScormActions } from "../../store/ducks/scorm";
import { Creators as RatingAnswerActions } from "../../store/ducks/ratingAnswer";
import { Creators as SuccessActions } from "../../store/ducks/success";
import { Creators as HtmlCourseActions } from "../../store/ducks/htmlCourse";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../analytics";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import Sdk from "api.digitalpages.module.sdk.api";
import {CustomEvent} from "../../analytics";

class Conteudo extends Component {
  state = {
    user : null,
    fetch: true,
    ready: false,
    forceRender: null,
    scormInject: false,
    topicActive: null,
    chaptersReady: false,
    isSidenavOpen: false,
    hasSidenavOpenedOnConclude: false,
    topicsCompleted: 0,
    isModalConclusaoOpen: false,
    chapters: [],
    inputComentarioCurso: "",
    qtdRatingStars: 0,
    inputComentarioCursoValid: true,
    qtdRatingStarsValid: true,
    failedMessage: false,
    lastScormData : '',
    scormCalls : 0
  };

  updateStars = qtdRatingStars => {
    this.setState({ qtdRatingStars, qtdRatingStarsValid: true });
  };

  fetchInfo() {
    const user = JSON.parse(localStorage.getItem("@emae-cursos:userInfo"));
    const { getTopicsRequest, getCourseRequest } = this.props;

    getCourseRequest(this.props.match.params.id);
    getTopicsRequest(
      this.props.match.params.id,
      this.props.match.params.idMatricula
    );

    this.setState({
      user: user,
      userId: user.id,
      forceRender: null,
      scormInject: false,
      topicActive: null,
      chaptersReady: false,
      isSidenavOpen: false,
      hasSidenavOpenedOnConclude: false,
      topicsCompleted: 0,
      isModalConclusaoOpen: false,
      reverCurso: this.props.match.params.reverCurso === "true" ? true : false,
      chapters: [
        {
          name: "Introducao",
          topic: null,
          completed: false,
          isActive: true
        }
      ],
      inputComentarioCurso: ""
    });
  }

  navTopic = chapter => {
    if (!chapter.isActive) {
      AnalyticsEvent({
        category: GA_CATEGORIES.CONTENT,
        action: GA_ACTIONS.CLICKED_MODULE,
        label: `${GA_LABELS.MODULE}"${chapter.name}"`
      });
      this.props.history.push(chapter.url);
      this.setState({ fetch: true });
    }
  };

  handleOpenSidenav = () => {
    this.setState({ isSidenavOpen: true });
  };

  toggleSidenav = () => {
    this.setState({ isSidenavOpen: !this.state.isSidenavOpen }, () => {
      const isSidenavOpen = this.state.isSidenavOpen;
      AnalyticsEvent({
        category: GA_CATEGORIES.CONTENT,
        action: isSidenavOpen
          ? GA_ACTIONS.CLICKED_OPEN_TRAIL
          : GA_ACTIONS.CLICKED_CLOSE_TRAIL
      });
    });
  };

  onCloseModalConclusao = () => {
    this.setState({
      isModalConclusaoOpen: false
    }, () => {
        window.location.href = `/curso/${this.props.match.params.id}/matricula/${this.props.match.params.idMatricula}`
      });
  };

  handleInputComentarioModal = e => {
    this.setState({ inputComentarioCurso: e.target.value });
  };

  componentDidMount() {


    Sdk.insights.createXapiTracking({
      endpoint : "-",
      auth : "-"
    });

    this.setState({
      initialTime: moment()
    });

    this.props.clearTopicItems();
    this.setState({ fetch: false }, () => {
      this.props.clearScorm();
      this.fetchInfo();
      this.props.getEnrollRequest(this.props.match.params.idMatricula);
    });
  }

  setChapterCompleted = id => {
    this.setState({
      chapters: this.state.chapters.map(chapter => {
        if (chapter.id === id) {
          chapter.completed = true;
          chapter.session_status = "completed";
        }

        return chapter;
      })
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.fetch) {
      this.setState({ fetch: false }, () => {
        this.props.clearScorm();
        this.fetchInfo();
      });
    }

    if ((this.props.scorm.data.session_status === "completed" || this.props.scorm.data.session_status === "passed") && !this.state.completedCourse) {
      if (
        this.props.topics.data.length &&
        (this.props.topics.data.length === 1 || this.verifyCourseIsCompleted(this.props.scorm.data.topic_id, this.props.scorm.data.session_status)) &&
        this.props.enrollment.data.status === 'Em andamento') {
          this.completeCourse();
        } else {
          var { hasSidenavOpenedOnConclude } = this.state;
          if (!hasSidenavOpenedOnConclude) {
            this.setChapterCompleted(this.props.scorm.data.topic_id);
            this.handleOpenSidenav();
            this.setState({ hasSidenavOpenedOnConclude: true });
            AnalyticsEvent({
              category: GA_CATEGORIES.CONTENT,
              action: GA_ACTIONS.FINISHED_MODULE,
              label: GA_LABELS.MODULE_ID + this.props.scorm.data.topic_id
            });
          }
        }
    }
  }

  saveRatingAnswer = async () => {
    await this.setState({
      qtdRatingStarsValid: this.state.qtdRatingStars !== 0
    });

    if (
      !this.state.qtdRatingStarsValid ||
      !this.state.inputComentarioCursoValid
    ) {
      return;
    }

    const { postRatingAnswerRequest } = this.props;

    await postRatingAnswerRequest(
      this.props.match.params.idRating,
      this.props.ratingAnswer.value ? this.props.ratingAnswer.value : 1,
      this.state.inputComentarioCurso
    );
    this.onCloseModalConclusao();
  };

  verifyScormData(data) {
    const time = moment().diff(this.state.initialTime);
    const timerAtual = this.state.topicActive.scormUser ? this.state.topicActive.scormUser.session_time : "00:00:00";
    data.session_time = moment(timerAtual, "HH:mm:ss").add(time).format("HH:mm:ss");

    if (data.session_time === "") data.session_time = "00:00:00";
    if (data.lesson_location === "") data.lesson_location = "0";
    if (data.lesson_status === "") data.lesson_status = "started";
    if (data.score_raw === "") data.score_raw = "0";
    if (data.score_max === "") data.score_max = "0";
    if (data.score_min === "") data.score_min = "0";

    return data;
  }

  verifyCourseIsCompleted(topicId, lessonStatus) {
    let topics = this.props.topics.data;
    let completed = 0;

    for (let i = 0; i < topics.length; i++) {
      if (topics[i].scormUser && topics[i].id !== topicId) {
        if (topics[i].scormUser.session_status === "completed" || topics[i].scormUser.session_status === "passed") {
          completed++;
        }
      }
    }

    if (lessonStatus === "completed" || lessonStatus === "passed") {
      completed++;
    }

    if (completed >= topics.length) {
      return true;
    }

    return false;
  }

  updateScormData(updateScormRequest, data, enrollId) {
    let idTopic = this.state.topicActive.id;
    data = this.verifyScormData(data);
    updateScormRequest(enrollId, idTopic, data);
  }

  //Recebe mensagem
  receiveMessage = async e => {
    const { updateScormRequest } = this.props;
    let enrollId = this.props.match.params.idMatricula;

    if (typeof e.data == "string") {

      if (e.data == this.state.lastScormData && this.state.scormCalls < 20) {
        this.setState({lastScormData:e.data, scormCalls:this.state.scormCalls+1});
        return;
      }

      this.setState({lastScormData:e.data, scormCalls:0});
      let dataScorm = JSON.parse(e.data);

      if (
        !!dataScorm &&
        enrollId &&
        dataScorm.hasOwnProperty("lesson_status")
      ) {
        if (!this.state.reverCurso) {
          dataScorm = this.verifyScormData(dataScorm);
          this.updateScormData(updateScormRequest, dataScorm, enrollId);

          if (
            dataScorm.lesson_status === "failed" &&
            !this.state.reverCurso &&
            !this.state.failedMessage
          ) {
            this.setState({
              failedMessage: true
            });
          }
        }
      }
    }
    // conteúdo está acessando a api scorm de forma direta
    // else if (e.data.type == "scorm")
    // {
    //   var scorm = this.state.scormInject;
    //   var updated = false;

    //   switch(e.data.action)
    //   {
    //     case 'cmi.core.lesson_status':
    //       {
    //         scorm.lesson_status = e.data.value;
    //         updated = true;
    //         break;
    //       }
    //       case 'cmi.suspend_data':
    //       {
    //         scorm.suspend_data = e.data.value;
    //         updated = true;
    //         break;
    //       }
    //   }

    //   if (!updated) return;

    //   scorm = this.verifyScormData(scorm);
    //   this.updateScormData(updateScormRequest, scorm, enrollId);
    // } 
    else if (e.data.type == "xapi")
    {
      var composerEvent = e.data;
      const {user, topicActive} = this.state;
      const course = this.props.course;

      Sdk.authorization.user = {
        uid : `${user.id}`,
        detail : {
          name : user.name
        }
      }

      const statements = Sdk.insights.xapi.builder.create(composerEvent, {
        id : course.data.id,
        title : course.data.title,
        description : course.data.description
      },{
        id : topicActive.id,
        title : topicActive.description
      });

      if (statements) {
          statements.forEach(statement=> {
          CustomEvent(statement.asVersion());
        })
      }

    }
    // else if (e.data.type == "xapi" && e.data.action == "result" && e.data.verb == "answered")
    // {
    //   var info = e.data.metadata;
    //   var scorm = this.state.scormInject;

    //   var scoreMin = info.result.min_percentage;
    //   var score = info.result.percentage_average;
    //   var status = scoreMin == null || score >= scoreMin ? "passed" : "failed";

    //   scorm.score = score;
    //   scorm.score_min = scoreMin == null ? 0 : scoreMin;
    //   scorm.score_max = 100;
    //   scorm.session_status = status;
    //   scorm.suspend_data = JSON.stringify(info);
    //   scorm = this.verifyScormData(scorm);

    //   this.updateScormData(updateScormRequest, scorm, enrollId);
    //   return;


    //   var data = {};
      
    //   var questionIndex = 0;
    //   info.questions.forEach(question => {
    //     questionIndex++;

    //     data[`cmi.interactions.${questionIndex}.id`] = question.id;
    //     data[`cmi.interactions.${questionIndex}.type`] = 'choice';
    //     data[`cmi.interactions.${questionIndex}.description`] = question.question;
    //     data[`cmi.interactions.${questionIndex}.objectives.0.id`] = info.element_id;

    //     var userResponses = question.choices.filter(choice => choice.user_selected).map(choice => choice.id).join("_");
    //     data[`cmi.interactions.${questionIndex}.learner_response`] = userResponses;
    //     data[`cmi.interactions.${questionIndex}.student_response`] = userResponses;

    //     var correctResponses = question.choices.filter(choice => choice.correct).map(choice => choice.id).join("_");
    //     data[`cmi.interactions.${questionIndex}.correct_responses.0.pattern`] = correctResponses;
    //   });

    //   var scoreMin = info.result.min_percentage;
    //   var scoreMax = info.result.percentage_average;

    //   data[`cmi.objectives.0.id`] = info.element_id;
    //   data[`cmi.objectives.0.score.raw`] = scoreMax;
    //   data[`cmi.objectives.0.score.min`] = scoreMin;
    //   data[`cmi.objectives.0.success_status`] = scoreMin == null || scoreMax >= scoreMin ? "passed" : "failed";
    //   data[`cmi.objectives.0.completion_status`] = "completed";
    // }
  };

  iframeReady = async e => {
    // console.log(e)
  };

  updateChapters(topics) {
    let chapters = [];
    let topicoAtivo = null;
    let hasActive = false;
    let countCompleted = 0;

    for (let i = 0; i < topics.length; i++) {
      let topic = topics[i];
      let chapter = {
        id: topic.id,
        name: topic.title,
        topic_id: topic.id,
        prerequisite: topic.prerequisite,
        is_test: topic.type === "avaliacao"
      };
      if (
        this.props.match.params.idTopico !== "initial" &&
        this.props.match.params.idTopico == topic.id &&
        !hasActive
      ) {
        hasActive = true;
        topicoAtivo = topic;
        chapter.isActive = true;
      }

      if (topic.scormUser) {
        if (
          topic.scormUser.session_status === "completed" ||
          topic.scormUser.session_status === "passed"
        ) {
          chapter.completed = true;
          countCompleted++;
        } else {
          chapter.completed = false;
        }

        if (!hasActive &&
          ((this.props.match.params.reverCurso === "true" &&
            topic.type !== "avaliacao") ||
            !chapter.completed) &&
          this.props.match.params.idTopico === "initial"
        ) {
          hasActive = true;
          topicoAtivo = topic;
          chapter.isActive = true;
        }
        chapter.session_status = topic.scormUser.session_status;
      } else {
        chapter.completed = false;
        if (!hasActive && this.props.match.params.idTopico === "initial") {
          hasActive = true;
          topicoAtivo = topic;
          chapter.isActive = true;
        }
      }
      chapter.url = this.returnTopicUrl(topic.id);
      chapters.push(chapter);
    }

    this.props.getScormRequest(
      this.props.match.params.idMatricula,
      topicoAtivo.id
    );

    this.setState({
      topicActive: topicoAtivo,
      reverCurso: topicoAtivo.scormUser && (topicoAtivo.scormUser.session_status === "completed" || topicoAtivo.scormUser.session_status === "passed"),
      chapters: chapters,
      chaptersReady: true,
      topics: topics,
      topicsCompleted: countCompleted
    });
  }

  verifyCourseIsDone() {
    const matricula = this.props.course.data.offers[0].enrollments[0];
    const idMatricula = this.props.match.params.idMatricula;
    let contCompleted = this.state.topics.length === this.state.topicsCompleted;

    if (matricula.id === idMatricula && matricula.end == null && contCompleted) {
      return true;
    }
    return false;
  }

  returnTopicUrl(idTopic) {
    return `/conteudo/${this.props.match.params.id}/oferta/${this.props.match.params.idOferta}/matricula/${this.props.match.params.idMatricula}/topico/${idTopic}/rating/${this.props.match.params.idRating}/rever/${this.props.match.params.reverCurso}`;
  }

  completeCourse() {
    const { postEnrollConclude } = this.props;
    let enrollId = this.props.match.params.idMatricula;
    postEnrollConclude(enrollId);

    this.props.setSuccess("Treinamento concluido com sucesso!", {
      toasted: true
    });

    this.setState({
      completedCourse: true,
      isModalConclusaoOpen: true
    });
  }

  componentWillUnmount() {
    this.setState({
      fetch: true,
      ready: false,
      forceRender: null,
      scormInject: false,
      topicActive: null,
      chaptersReady: false,
      isSidenavOpen: false,
      hasSidenavOpenedOnConclude: false,
      topicsCompleted: 0,
      isModalConclusaoOpen: false,
      chapters: [],
      inputComentarioCurso: ""
    });
  }

  getPrerequisite = topic => {
    if (!topic.prerequisite) return null;

    return this.props.course.data.topics.find(_topic => {
      return _topic.id === topic.prerequisite;
    });
  };

  isVisible = topic => {
    const prerequisite = this.getPrerequisite(topic);

    if (topic.is_test && topic.scormUser &&
      (topic.scormUser.session_status === "completed" ||
        topic.scormUser.session_status === "passed")
    )
      return {
        bool: false,
        message: "Você já realizou essa avaliação."
      };

    if (prerequisite) {
      if (!prerequisite.scormUser) {
        return {
          bool: false,
          message: `É necessário visualizar a unidade "${prerequisite.title}" para prosseguir`
        };
      }

      const { session_status } = prerequisite.scormUser;
      if (session_status !== "passed" && session_status !== "completed") {
        return {
          bool: false,
          message: `É necessário visualizar a unidade "${prerequisite.title}" para prosseguir`
        };
      }
    }

    return {
      bool: true
    };
  };

  onCloseModal = () => {
    this.setState({
      failedMessage: false
    });
  };

  // iframe has loaded
  onHTMLReady = () => {
    const { updateHTMLCourseRequest } = this.props;

    let data = {};
    data.session_time = '00:00:00';
    data.lesson_location = '0';
    data.lesson_status = 'completed'
    data.score_raw = '0';
    data.score_max = '0';
    data.score_min = '0';

    let idTopic = this.state.topicActive.id;
    let enrollId = this.props.match.params.idMatricula;
    let courseId = this.props.match.params.id;

    const onConclude = () => { this.setState({isSidenavOpen: true})}

    updateHTMLCourseRequest(enrollId, idTopic, data, courseId, onConclude);

  };

  resolveType() {
    if (this.state.topicActive.scormUpload.type === 'SCORM') {
      return (
        <IframeComm
          attributes={{
            src: this.state.topicActive.url_server +
            this.state.topicActive.scormUpload.route,
            id: "iframeId",
            sandbox:
              "allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups",
            allowFullScreen: true,
            height: "100%",
            width: "100%"
          }}
          handleReceiveMessage={this.receiveMessage}
          handleReady={this.iframeReady}
          postMessageData={JSON.stringify(this.state.scormInject)}
          ref={f => (this.ifr = f)}
        />
      )
    } else if (this.state.topicActive.scormUpload.type === 'HTML') {
      return (
        <IframeComm
          attributes={{
            src:
              this.state.topicActive.url_server +
              this.state.topicActive.scormUpload.route,
            id: "iframeId",
            sandbox:
              "allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups",
            allowFullScreen: true,
            height: "100%",
            width: "100%"
          }}
          handleReady={this.onHTMLReady}
        />
      )
    }
  }

  render() {
    const { isSidenavOpen } = this.state;
    const { topics, course } = this.props;

    if (!topics) {
      return <Loading text={"Carregando contéudo..."} loading={true} />;
    }
    if (!topics.success) {
      return <Loading text={"Carregando contéudo..."} loading={true} />;
    }
    if (topics.success && !this.state.chaptersReady && topics.data.length) {
      this.updateChapters(topics.data);
    }

    if (
      !topics.success ||
      !this.state.chaptersReady ||
      !this.state.topicActive
    ) {
      return <Loading text={"Preparando contéudo..."} loading={true} />;
    }

    if (!this.state.scormInject && this.props.scorm.getData) {
      let scormInject = this.props.scorm.data;
      scormInject.user_id = this.state.userId;
      scormInject.course_id = this.props.match.params.id;
      scormInject.topic_id = this.state.topicActive.id;
      scormInject.score_min_passed = this.state.topicActive.score_min_passed ? this.state.topicActive.score_min_passed : this.props.course.data.min_grade;

      if (this.state.reverCurso) {
        scormInject.suspend_data = null;
      }

      this.setState({ scormInject });
    }

    if (!this.state.scormInject) {
      return <Loading text={"Preparando contéudo..."} loading={true} />;
    }

    if (!this.props.course.success) {
      return <Loading text={"Preparando contéudo..."} loading={true} />;
    }

    return (
      <Fragment>
        <Modal
          open={this.state.isModalConclusaoOpen}
          onClose={() => this.setState({ isModalConclusaoOpen: false})}
          showCloseIcon={false}
          styles={{
            overlay: { backgroundColor: "rgba(125, 125, 125, 0.7)" },
            modal: { boxShadow: "none", maxWidth: "375px", padding: "40px" }
          }}
        >
          <ModalConclusaoInner>
            <h2>
              Parabéns! Você concluiu o treinamento {course.data.title}. O que você achou do treinamento?
            </h2>
            <RatingModal updateStars={this.updateStars} />
            {!this.state.qtdRatingStarsValid && (
              <SpanErrorStyled className="spanErrorBlock">
                Necessário selecionar a quantidade de estrelas
              </SpanErrorStyled>
            )}
            <div className="input-wrapper">
              <FormInput
                label="Deixe o seu comentário"
                type="text"
                id="comentario-curso"
                name="inputComentarioCurso"
                onChange={this.handleInputComentarioModal}
              />
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                onClick={() => {
                  this.onCloseModalConclusao();
                }}
              >
                Pular
              </button>
              <button
                onClick={() => {
                  this.saveRatingAnswer();
                }}
                type="button"
              >
                Enviar
              </button>
            </div>
          </ModalConclusaoInner>
        </Modal>

        <Modal
          open={this.state.failedMessage}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          center
          styles={{
            overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
            modal: {
              boxShadow: "none",
              maxWidth: "375px",
              padding: "40px"
            }
          }}
        >
          <ModalStyle>
            <div>
              <span>
                Infelizmente, você não alcançou o percentual mínimo de aproveitamento para conclusão do treinamento.
              </span>

              <div className="btn-wrapper">
                <button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Tentar novamente
                </button>
                <button
                  onClick={() => {
                    this.props.history.push(
                      `/curso/${this.props.match.params.id}/matricula/${this.props.match.params.idMatricula}`
                    );
                  }}
                >
                  Sair
                </button>
              </div>
            </div>
          </ModalStyle>
        </Modal>
        <ToastContainer autoClose={5000} />
        <Header
          history={this.props.history}
          backRoute={`/curso/${this.props.match.params.id}/matricula/${this.props.match.params.idMatricula}`}
          isSidenavOpen={
            !this.isVisible(this.state.topicActive).bool || isSidenavOpen
          }
          toggleSidenav={this.toggleSidenav}
        />
        <ContainerStyled>
          {(!this.isVisible(this.state.topicActive).bool || isSidenavOpen) && (
            <Sidenav
              courseTitle={course.data.offers[0] ? course.data.offers[0].title : course.data.title}
              topicClick={this.navTopic}
              countCompleted={this.state.topicsCompleted}
              chapters={this.state.chapters}
            />
          )}

          <IframeStyled>
            {!this.isVisible(this.state.topicActive).bool ? (
              <p className="error-page">
                {this.isVisible(this.state.topicActive).message}
              </p>
            ) : (
              this.resolveType()
            )}
          </IframeStyled>
        </ContainerStyled>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  course: state.course,
  ratingAnswer: state.ratingAnswer,
  topics: state.topics,
  scorm: state.scorm,
  enrollment: state.enrollment,
  htmlCourse: state.htmlCourse,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...EnrollmentActions,
      ...CourseActions,
      ...RatingAnswerActions,
      ...TopicsActions,
      ...ScormActions,
      ...SuccessActions,
      ...HtmlCourseActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conteudo);
