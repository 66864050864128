import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "termosUso/POST_REQUEST",
  POST_SUCCESS: "termosUso/POST_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  success: false,
  loading: false
});

export default function termosUso(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.POST_SUCCESS:
      return { ...state, success: true, loading: false };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postTermosUsoRequest: payload => {
    return { type: Types.POST_REQUEST, payload };
  },

  postTermosUsoSuccess: () => {
    return {
      type: Types.POST_SUCCESS
    };
  }
};
