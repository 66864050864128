import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  HeaderInfo,
  HeaderInfoInner,
  Header,
  TitleAndViewWrapper,
  TitleWrapper
} from "../../../../styles";
import RatingStars from "../../../../components/RatingStars";
import Modal from "react-responsive-modal";
import { ButtonAcessarCurso, ModalStyle } from "../../styles";
import { Creators as EnrollmentActions } from "../../../../store/ducks/enroll";
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS, GA_LABELS } from "../../../../analytics";

class HeaderInfoCurso extends Component {
  state = {
    modalAcessCourseOpen: false
  };

  componentDidMount() {
    this.setState({ modalAcessCourseOpen: this.props.modalAcessCourseOpen });
  }

  onOpenModal = () => {
    this.setState({ modalAcessCourseOpen: true });
  };

  onCloseModal = () => {
    if (this.state.modalAcessCourseOpen) {
      this.setState({ modalAcessCourseOpen: false });
    }
  };

  enrollOnOffer() {
    const { postEnrollRequest, offer } = this.props;
    postEnrollRequest(offer.id);
  }

  renderButtonsModal = () => {
    if (this.props.enrollment.loading) {
      return (
        <div className="btn-wrapper">
          <span>Carregando...</span>
        </div>
      );
    }
    return (
      <div className="btn-wrapper">
        <button onClick={this.onCloseModal}>Não</button>
        <button
          onClick={() => {
            this.enrollOnOffer();
          }}
        >
          Sim
        </button>
      </div>
    );
  };

  continueCourse = (idRating) => {
    const { enrollmentAtivo, course, offer } = this.props;
    const idCurso = course.data.id;
    const idOffer = offer.id;
    const idEnrollment = enrollmentAtivo.id;

    AnalyticsEvent({
      category: GA_CATEGORIES.ENROLLMENT,
      action: GA_ACTIONS.CONTINUED_ENROLLMENT,
      label: `[${GA_LABELS.COURSE}${idCurso}] [${GA_LABELS.OFFER}${idOffer}] [${GA_LABELS.ENROLLMENT}${idEnrollment}]`
    })

    this.props.history.push(
      `/conteudo/${idCurso}/oferta/${idOffer}/matricula/${
        idEnrollment
      }/topico/initial/rating/${idRating}/rever/false`
    );
  };

  renderButtonCurso(idRating) {
    const { enrollmentAtivo, course, offer } = this.props;
    const { modality } = this.props.course.data;
    const enrollmentConcluido = enrollmentAtivo.status === "Concluído";
    const isPresencial = modality === "presencial";

    if (!enrollmentAtivo) {
      return (
        <ButtonAcessarCurso onClick={this.onOpenModal}>
          {!isPresencial ? "Iniciar" : "Inscrever-se"}
        </ButtonAcessarCurso>
      );
    } else if (!enrollmentConcluido) {
      return (
        <ButtonAcessarCurso onClick={() => this.continueCourse(idRating)}>
          Continuar
        </ButtonAcessarCurso>
      );
    } else if (enrollmentConcluido) {
      return (
        <ButtonAcessarCurso
          onClick={() =>
            this.props.history.push(
              `/conteudo/${course.data.id}/oferta/${offer.id}/matricula/${
                enrollmentAtivo.id
              }/topico/initial/rating/${idRating}/rever/true`
            )
          }
        >
          Rever
        </ButtonAcessarCurso>
      );
    }
  }

  render() {
    const { course, offer, idRating } = this.props;
    const { modalAcessCourseOpen } = this.state;

    return (
      <HeaderInfo style={{ paddingBottom: "80px" }}>
        <HeaderInfoInner>
          <Header>
            <div>
              <span
                className="label notranslate"
                style={{ marginRight: "15px" }}
              >
                {!course.loading && course.data.category
                  ? course.data.category.title
                  : "loading"}
              </span>
              {!course.loading && course.data.rating ? (
                <RatingStars
                  rating={course.data.rating}
                  color="#FBCE07"
                  fontSize="15px"
                />
              ) : (
                "loading"
              )}
            </div>
            <TitleAndViewWrapper style={{ marginTop: "15px" }}>
              <TitleWrapper
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <h1 className="notranslate">
                  {!course.loading && offer && offer.title
                    ? offer.title
                    : "loading"}
                </h1>
                {this.renderButtonCurso(idRating)}
                <Modal
                  open={modalAcessCourseOpen}
                  onClose={this.onCloseModal}
                  showCloseIcon={false}
                  center
                  styles={{
                    overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
                    modal: {
                      boxShadow: "none",
                      maxWidth: "375px",
                      padding: "40px"
                    }
                  }}
                >
                  <ModalStyle>
                    <div>
                      <h2>
                        Você confirma a sua inscrição no treinamento{" "}
                        {!course.loading && offer && offer.title
                          ? offer.title
                          : "loading"}
                        ?
                      </h2>
                      {this.renderButtonsModal()}
                    </div>
                  </ModalStyle>
                </Modal>
              </TitleWrapper>
            </TitleAndViewWrapper>
          </Header>
        </HeaderInfoInner>
      </HeaderInfo>
    );
  }
}
const mapStateToProps = state => ({
  enrollment: state.enrollment
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...EnrollmentActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderInfoCurso);
