import React, { Component, Fragment } from "react";
import {
  CursoContent,
  CursoContentBlock,
  CursoConteudoList,
  CursoDados,
  BoxAvaliarCurso,
  ModalConclusaoInner
} from "../../styles";

import Modal from "react-responsive-modal";
import RatingModal from "../RatingModal";
import { FormInput } from "../../../../components/Form";

import CursoConteudoItem from "../../cursoConteudoItem";

export default class CursoInformacoes extends Component {
  state = {
    certificadoMsg: "Baixar",
    codigoCondutaMsg: "Baixar",
  };

  componentDidUpdate(prevProps, prevState) {
    const { certificate, fileDownload } = this.props;
    if (
      !certificate.downloading &&
      certificate.downloading !== prevProps.certificate.downloading
    ) {
      this.setState({ certificadoMsg: "Baixar" });
    }

    if (
      fileDownload &&
      ((fileDownload.success && !prevProps.fileDownload.success) ||
        (fileDownload.error && !prevProps.fileDownload.error))
    ) {
      this.setState({ codigoCondutaMsg: "Baixar" });
    }
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(localStorage.getItem("@emae-cursos:userInfo"))
    })
  }

  downloadCertificado = () => {
    this.props.downloadCertificado();
    this.setState({ certificadoMsg: "Baixando..." });
  };

  downloadAnexo = async courseFile => {
    const { file } = courseFile
    const fileName = `${courseFile.title}.${file.subtype}`

    this.props.downloadFile({
      id: file.id,
      fileName,
      course_file_id: courseFile.id,
      user_id: this.state.user.id
    })

    this.setState({ codigoCondutaMsg: "Baixando..." });
  };

  renderBoxesInfo(course, enrollmentAtivo) {
    const { realizacoesCount } = this.props;
    return (
      <CursoDados>
        <div>
          <span>Carga horária</span>
          <span>{!course.loading ? course.data.duration : "loading"}</span>
        </div>
        <div>
          <span>Nível</span>
          <span>
            {!course.loading && course.data.level
              ? course.data.level
              : "loading"}
          </span>
        </div>
        {enrollmentAtivo.end ? (
          <div>
            <span>Certificado</span>
            <span
              className={
                !this.props.certificate.downloading ? "btn-certificado" : ""
              }
              onClick={this.downloadCertificado}
            >
              {this.state.certificadoMsg}
            </span>
          </div>
        ) : (
          <div>
            <span>Modalidade</span>
            <span>
              {!course.loading && course.data.modality
                ? course.data.modality
                : "loading"}
            </span>
          </div>
        )}
        {course.data.courseFiles.map(courseFile => (
          <div key={courseFile.id}>
            <span>{courseFile.title}</span>
            <span
              className="codigo-conduta-link"
              onClick={() => this.downloadAnexo(courseFile)}
            >
              {this.state.codigoCondutaMsg}
            </span>
          </div>
        ))}
      </CursoDados>
    );
  }

  isVisible = topic => {
    const prerequisite = this.getPrerequisite(topic);

    if (
      topic.type === "avaliacao" &&
      topic.scormUser &&
      (topic.scormUser.session_status === "completed" ||
        topic.scormUser.session_status === "passed")
    )
      return {
        bool: false,
        message: "Você já realizou essa avaliação."
      };

    if (prerequisite) {
      if (!prerequisite.scormUser) {
        return {
          bool: false,
          message: `É necessário visualizar a unidade "${prerequisite.title}" para prosseguir`
        };
      }

      const { session_status } = prerequisite.scormUser;

      if (
        session_status !== "passed" &&
        session_status !== "failed" &&
        session_status !== "completed"
      ) {
        return {
          bool: false,
          message: `É necessário visualizar a unidade "${prerequisite.title}" para prosseguir`
        };
      }
    }

    return {
      bool: true
    };
  };

  getPrerequisite = topic => {
    if (!topic.prerequisite) return null;

    return this.props.course.data.topics.find(_topic => {
      return _topic.id === topic.prerequisite;
    });
  };

  onCloseModalConclusao = () => {
    this.setState({ isModalConclusaoOpen: false });
  };

  onOpenModalConclusao = () => {
    this.setState({ isModalConclusaoOpen: true });
  };

  saveRatingAnswer(e) {
    const { postRatingAnswerRequest } = this.props;
    postRatingAnswerRequest(
      this.props.ratings.data.id,
      this.props.ratingAnswer.value ? this.props.ratingAnswer.value : 5,
      this.state.inputComentarioCurso,
      this.props.course.data.id
    );

    this.onCloseModalConclusao();
  }

  handleInputComentarioModal = e => {
    this.setState({
      inputComentarioCurso: e.target.value,
      inputComentarioError: !!e.target.value
    });
  };

  getUserRatedCourse = () => {
    const { ratingAnswer } = this.props;

    if (ratingAnswer.success && this.state.user) {
      const findAnswer = ratingAnswer.data.data.find(answer => (
        answer.user_created === this.state.user.id
      ));

      if (findAnswer) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      course,
      verifyTopicCompleted,
      getUrlScorm,
      enrollmentAtivo
    } = this.props;

    let rated =
      enrollmentAtivo.offer &&
      enrollmentAtivo.offer.course &&
      enrollmentAtivo.offer.course.courseRating &&
      !!enrollmentAtivo.offer.course.courseRating.ratingAnswers.length;

    const renderRatingContainer = !!(!rated && enrollmentAtivo.end && enrollmentAtivo.offer.course.courseRating && !this.getUserRatedCourse());

    return (
      <div>
        <Modal
          blockScroll={false}
          open={this.state.isModalConclusaoOpen || false}
          onClose={this.onCloseModalConclusao}
          showCloseIcon={false}
          styles={{
            overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
            modal: { boxShadow: "none", maxWidth: "375px", padding: "40px" }
          }}
        >
          <ModalConclusaoInner>
            <h2>Como avalia o treinamento {course.data.title}?</h2>
            <RatingModal />
            <div className="input-wrapper">
              {this.state.inputComentarioError && (
                <p className="input-comentario-error">
                  {this.state.inputComentarioError}
                </p>
              )}
              <FormInput
                label="Deixe o seu comentário"
                type="text"
                id="comentario-curso"
                name="inputComentarioCurso"
                onChange={this.handleInputComentarioModal}
              />
            </div>
            <div className="btn-wrapper">
              <button
                onClick={(e) => {
                  this.saveRatingAnswer(e);
                }}
                type="button"
              >
                Enviar
              </button>
            </div>
          </ModalConclusaoInner>
        </Modal>

        {this.renderBoxesInfo(course, enrollmentAtivo)}

        {renderRatingContainer && (
          <BoxAvaliarCurso>
            <div className="avalia-curso-message">
              <span>Avalie o treinamento</span>
            </div>
            <button
              onClick={this.onOpenModalConclusao}
              className="avalia-curso-button"
            >
              Avaliar treinamento
            </button>
          </BoxAvaliarCurso>
        )}
        <CursoContent>
          <CursoContentBlock>
            <h2>Objetivo deste treinamento</h2>
            {!course.loading && course.data.description
              ? course.data.description
              : "loading"}
          </CursoContentBlock>

          <CursoContentBlock>
            <Fragment>
              <h2>Conteúdo do Treinamento</h2>
              <CursoConteudoList>
                {!course.loading && course.data.topics
                  ? course.data.topics.map(topic => (
                      <CursoConteudoItem
                        key={topic.id}
                        titulo={topic.title}
                        duracao={null}
                        isFeito={verifyTopicCompleted(topic)}
                        urlScorm={getUrlScorm(topic)}
                        enrollmentAtivo={enrollmentAtivo}
                        history={this.props.history}
                        visible={this.isVisible(topic)}
                        prerequisite={this.getPrerequisite(topic)}
                      />
                    ))
                  : "loading"}
              </CursoConteudoList>
            </Fragment>
          </CursoContentBlock>
        </CursoContent>
      </div>
    );
  }
}
