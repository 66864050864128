import React, { Component } from "react";
import moment from "moment";
import { Provider } from "react-redux";

import store from "./store";
import LoadingApp from "./pages//LoadingApp";
import { createRefreshTokenInterceptor } from "./services/api";

import "./config/ReactotronConfig";
import "./assets/css/fonts.css";
import "./styles.css";
import { AnalyticsInit, CustomEvent } from "./analytics";

createRefreshTokenInterceptor(store.dispatch, store.getState);

moment.defineLocale("pt_BR", {
  relativeTime: {
    future: "em %s",
    past: "%s atrás",
    s: "há poucos segundos",
    ss: "%d segundos",
    m: "há um minuto",
    mm: "%d minutos",
    h: "há uma hora",
    hh: "%d horas",
    d: "há um dia",
    dd: "%d dias",
    M: "Há um mês",
    MM: "%d meses",
    y: "há um ano",
    yy: "%d anos"
  }
});
moment.locale("pt_BR");

// if (process.env.NODE_ENV === "production") {
  AnalyticsInit()
// }

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <LoadingApp />
      </Provider>
    );
  }
}

export default App;
