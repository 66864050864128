import React, { Component } from 'react'
import MdClose from "react-ionicons/lib/MdClose";
import moment from 'moment';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as NotificationsActions } from "../../../../store/ducks/notification";


class Notification extends Component {

  render() {
    const { not, onClick } = this.props;
    return (
      <li>
        {onClick && <div className="close-btn" onClick={() => onClick(not.id)}>
            <MdClose fontSize="18px" color="#686868" />
          </div>}
        <div className="content-wrapper">
          <p>{not.text}</p>
          <span>{moment(not.created_at).fromNow()}</span>
        </div>
      </li>
    )
  }
}

const mapStateToProps = state => ({
  notification: state.notification,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...NotificationsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
