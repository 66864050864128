import api from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as SearcActions } from "../ducks/searchOffer";
import { Creators as ErrorActions } from "../ducks/error";
import {
  AnalyticsEvent,
  GA_CATEGORIES,
  GA_ACTIONS,
  GA_LABELS
} from "../../analytics";

export function* getSearchOffer(action) {
  try {
    let params = "?title=" + action.payload.title;

    if (action.payload.categoryId) {
      params += "&category_id=" + action.payload.categoryId;
    }

    if (action.payload.page) {
      params += "&page=" + action.payload.page;
    }

    const response = yield call(api.get, "/api/v1/offers" + params);

    const labelTerm = action.payload.title ? `"${action.payload.title}"` : 'Nenhum';
    AnalyticsEvent({
      category: GA_CATEGORIES.SEARCH,
      action: GA_ACTIONS.SEARCHED,
      label: `[${GA_LABELS.CATEGORY} ${action.payload.categoryId}][Todos] - ` + GA_LABELS.TERM + labelTerm
    });

    yield put(SearcActions.getSearchSuccess(response.data));
  } catch (err) {
    if (err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro ao realizar a busca"));
    }
  }
}
