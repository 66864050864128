import styled from 'styled-components';
import { CarouselProvider, DotGroup } from "pure-react-carousel";

export const Carousel = styled(CarouselProvider)`
  @media screen and (max-width: 1000px) {
    height: 258px !important;
  }

  @media screen and (max-width: 600px) {
    height: 200px !important;
  }

  @media screen and (max-width: 1600px) {
    height: 420px;
  }

  @media screen and (min-width: 1600px) {
    height: 505px;
  }

  .btn-carousel {
    position: absolute;
    top: 145px;
    background: 0;
    border: 0;

    &-back {
      left: 0;
    }

    &-next {
      right: 0;
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const BannerSlide = styled.div`
    width: 100%;
    @media screen and (max-width: 1000px) {
      height: 258px !important;
    }

    @media screen and (max-width: 600px) {
      height: 182px !important;
    }

    @media screen and (max-width: 1600px) {
      height: 420px;
    }

    @media screen and (min-width: 1600px) {
      height: 505px;
    }

    height: 327px;
    background: url(${props => props.source}) no-repeat;
    background-size: cover;
    background-position-y: 25%;
    background-position: right;
    display: flex;
    align-items: center;

    div {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'Futura-Bold', sans-serif;

        h2 {
            font-size: 50px;
            color: #fff;
            text-transform: uppercase;
        }

        span {
            font-size: 30px;
            color: #fff;

        }
    }
`;

export const CountGroup = styled(DotGroup)`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    border-right: none;
    margin: 5px;
    opacity: 0.3;

    &[disabled] {
      opacity: 1;
    }
  }
`
