import React, { Fragment, Component } from "react";
import { List, TituloCategoria, ListWrapper, ModalStyle } from "./styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as TreinometroPerfilCertificadoActions } from "../../../../store/ducks/certificado";

import Modal from "react-responsive-modal";
import { ToastContainer } from "react-toastify";

class CertificadoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        visible: false,
        certificate: {}
      }
    };
  }

  openModal = certificate => {
    this.setState({
      modal: {
        visible: true,
        certificate
      }
    });
  };

  closeModal = () => {
    this.setState({
      modal: { visible: false, certificate: {} }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.perfilCertificado.downloading &&
      !this.props.perfilCertificado.downloading
    ) {
      this.closeModal();
    }
  }

  download = () => {
    this.props.downloadCertificadoRequest(this.state.modal.certificate.id);
  };

  renderModal() {
    const { modal } = this.state;
    const { certificate } = modal;

    return (
      <Modal
        open={modal.visible}
        onClose={this.closeModal}
        showCloseIcon={true}
        center
        styles={{
          overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
          modal: {
            boxShadow: "none",
            maxWidth: "550px",
            padding: "40px"
          }
        }}
      >
        <ModalStyle>
          <div>
            <h2>Certificado de conclusão do curso {certificate.nome}</h2>
            <div className="btn-wrapper">
              <button onClick={this.download}>
                {this.props.perfilCertificado.downloading
                  ? "Baixando..."
                  : "Baixar"}
              </button>
            </div>
          </div>
        </ModalStyle>
      </Modal>
    );
  }

  render() {
    const { certificados } = this.props;

    return (
      <Fragment>
        <ToastContainer autoClose={3000} />
        {this.renderModal()}
        <ListWrapper>
          {certificados.length === 0 && "Nenhum certificado encontrado."}
          {certificados.map(certs => (
            <Fragment key={certs.id}>
              <TituloCategoria>{certs.categoria}</TituloCategoria>
              <List key={certs.id}>
                {certs.certificados.map(cert => (
                  <li onClick={() => this.openModal(cert)} key={cert.id}>
                    <div className="left-side">
                      <span className="nome-curso">{cert.nome}</span>
                      <span className="conclusao">
                        Conclusão: {cert.conclusao}
                      </span>
                      {!!cert.nota && (
                        <span className="nota">Nota: {cert.nota}</span>
                      )}
                    </div>
                  </li>
                ))}
              </List>
            </Fragment>
          ))}
        </ListWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  perfilCertificado: state.certificado
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...TreinometroPerfilCertificadoActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificadoList);
