import styled from 'styled-components';

export const FooterStyle = styled.footer`
    display: flex;
    height: 50px;
    justify-content: space-between;
    background-color: #F0F0F0;

    .vertical-divider {
      border: 0.5px solid #A1A1A1;
      height: 15px;
      margin: 0 20px;
    }

    img {
      margin-right: 5px;
    }

    .title-suporte {
      font-weight: bold;
      color: #0c4f92;
      margin-right: 3px;
    }

    div {
        display: flex;
        align-items: center;

        :first-child {
            padding-left: 4%;

            img {
              width: 100px;
              height: auto;
            }
            span {
              font-size: 10px;
              margin-left: 10px;
              margin-bottom: 10px;
            }
          }
        :last-child {
          display: flex;
          justify-content: flex-end;
            span {
                margin-right: 20px;
            }

            img {
              width: 100px;
              height: 50px;
              margin-right: 0;
            }
        }

        span {
            font-size:12px;
            color:#A1A1A1;
        }
    }

    @media screen and (max-width: 670px){
      flex-direction: column;
      height: auto;
      div {
        align-self: center;
        :first-child {
          order:2;
          margin: 15px 0;
          justify-content: center;
          padding: 0;
        }

        :nth-child(2) {
          order: 1;
          width: 100%;
          display: flex;
          padding-left: 10px;
          justify-content: center;
          img {
            display: none;
          }
        }
      }
    }
`
