import React from 'react'
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';

export function CollapsibleItemHead(props) {
  return (
    <div>
      <span>{props.title}</span>
      <IosArrowDown fontSize='24px' color='#0c4f92' />
    </div>
  )
}
