import React from "react";
import PropTypes from "prop-types";

import { LoadingStyled } from "./styles";

export default class Loading extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired
  };

  render() {
    if (!this.props.loading) {
      return null;
    }
    let text = this.props.text;
    if (!this.props.text) {
      text = "AGUARDE...";
    }
    return (
      <LoadingStyled>
        <p>{text}</p>
      </LoadingStyled>
    );
  }
}
