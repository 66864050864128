import { apiQuiz } from "../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as TermosUsoActions } from "../ducks/termosUso";
import { Creators as ErrorActions } from "../ducks/error";

export function* postTermosUso(action) {
  try {
    yield call(
      apiQuiz.post,
      `/api/v1/terms-uso/${action.payload.id}`
    );

    yield put(TermosUsoActions.postTermosUsoSuccess());
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          "Um erro ocorreu, por favor tente novamente mais tarde."
        )
      );
    }
  }
}
