import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "fileDownload/GET_REQUEST",
  GET_SUCCESS: "fileDownload/GET_SUCCESS",
  GET_FAILURE: "fileDownload/GET_FAILURE"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false
});

export default function fileDownload(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { loading: true, success: false, error: false };
    case Types.GET_SUCCESS:
      return { loading: false, success: true, error: false };
    case Types.GET_FAILURE:
      return { loading: false, success: false, error: true };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getFileDownloadRequest: payload => ({
    type: Types.GET_REQUEST,
    payload
  }),

  getFileDownloadSuccess: () => ({
    type: Types.GET_SUCCESS
  }),

  getFileDownloadFailure: () => ({
    type: Types.GET_FAILURE
  }),
};
