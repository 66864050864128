import styled, { css } from "styled-components";

export const UserHeadInfo = styled.div`
  display: flex;
  align-items: center;

  > div {
    :first-child {
      margin-right: 30px;
    }

    span {
      font-size: 12px;
      margin-left: 10px;
    }
  }
`;

export const UserContent = styled.div`
  display: flex;

  > div {
    background-color: #fff;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  flex: 3;
  margin-right: 30px;
  margin-top: 30px;
  padding: 30px 25px;

  input {
    margin-bottom: 25px;
  }

  @media screen and (max-width: 1000px) {
    order: 2;
    flex: 1;
    margin-right: 0;
  }
`;
export const RightSide = styled.div`
  width: 325px;
  background-color: #fff;

  ${props =>
    props.overlayMenu &&
    css`
      margin-top: -60px;
    `}

  ${props =>
    props.padding &&
    css`
      padding: 16px;
    `}

  .header {
    display: flex;
    border-bottom: 3px solid #ddd;
    height: 100px;

    .img-wrapper {
      overflow: hidden;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .change-pic-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 1;

      span {
        color: #535353;
        font-size: 14px;
      }
    }
  }

  .list {
    margin: 0;
    padding-left: 25px;

    li {
      border-bottom: 1px solid #ddd;

      :last-child {
        border-bottom: none;
      }

      a {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;

        :hover {
          opacity: 0.8;
        }
      }

      div {
        display: flex;
        align-items: center;
        span {
          margin-left: 15px;
          font-weight: bold;
          font-family: "Futura-Bold", sans-serif;
          font-size: 16px;
          color: #535353;
        }
      }
    }
  }

  .header-interesses {
    span {
      font-family: "Futura-Bold", sans-serif;
      color: #a1a1a1;
    }
  }

  .body-interesses {
    padding-top: 10px;

    > div {
      padding: 10px;
    }
  }

  @media screen and (max-width: 1000px) {
    order: 1;
    margin-top: 30px;
    width: 100%;
  }
`;

export const InputFileWrapper = styled.div`
  input {
    margin: 10px auto 15px;
    display: block;
  }
`;

export const ModalPicBox = styled.div`
  display: flex;
  margin: 10px 20px 0 20px;
  max-height: 250px;

  .content-img {
    flex: 0.5;
    margin-right: 5px;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  .content-crop {
    flex: 0.5;
    margin-left: 5px;

    img {
      max-width: 100%;
    }

    .ReactCrop {
      cursor: auto;
      max-width: 100%;

      img {
        pointer-events: none;
      }
    }
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 15px;

  button {
    :first-child {
      margin-right: 10px;
    }
  }
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent !important;

  > div {
    margin-bottom: 20px;
  }
`;
