import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CursoDepoimentos } from "../../pages/Curso/styles";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import DepoimentoItem from "../../pages/Curso/depoimentoItem";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import ImgFrentista from "../../assets/img/frentista.jpg";
import { Creators as RatingAnswersAction } from "../../store/ducks/ratingAnswer";
import Loading from "../Loading";

class NewCarouselRatings extends Component {
  state = {
    currentSlide: 0,
    ratingAnswers: [],
    ready: false,
    showMore: false,
    page: 1,
    lastPage: 1
  };

  componentDidMount() {
    const { courseRatingId, getRatingAnswersRequest } = this.props;
    if (this.props.courseRatingId) {
      getRatingAnswersRequest(courseRatingId, 1);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      ratingAnswer,
      courseRatingId,
      getRatingAnswersRequest
    } = this.props;

    if (this.state.showMore) {
      getRatingAnswersRequest(courseRatingId, this.state.page);
      this.setState({ showMore: false, ready: false });
    }

    if (
      ratingAnswer.success &&
      ratingAnswer.success !== prevProps.ratingAnswer.success
    ) {
      const answers = this.state.ratingAnswers.concat(ratingAnswer.data.data);
      this.setState({
        ready: true,
        ratingAnswers: answers,
        page: ratingAnswer.data.page,
        lastPage: ratingAnswer.data.lastPage
      });
    }
  }

  verifyCurrentSlide() {
    const { currentSlide, page, lastPage } = this.state;
    if (
      currentSlide === this.state.ratingAnswers.length - 2 &&
      page < lastPage
    ) {
      this.setState({ showMore: true, page: page + 1, ready: false });
    }
    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.verifyCurrentSlide();
    return (
      !this.state.ready ||
      this.state.showMore !== nextState.showMore ||
      JSON.stringify(this.props.ratingAnswer) !==
        JSON.stringify(nextProps.ratingAnswer) ||
      this.props.loading !== nextProps.loading ||
      JSON.stringify(this.state.ratingAnswers) !==
        JSON.stringify(nextState.ratingAnswer)
    );
  }

  render() {
    const { ratingAnswers, currentSlide } = this.state;
    const { loading } = this.props;
    if (this.props.ratingAnswer.loading) {
      return (
        <CursoDepoimentos>
          <div style={{ marginTop: "20px", height: "140px", width: "100%" }}>
            <Loading text={"Carregando mais depoimentos..."} loading={true} />
          </div>
        </CursoDepoimentos>
      );
    }
    if (ratingAnswers.length === 0) {
      return (
        <CursoDepoimentos>
          <div style={{ marginTop: "20px", height: "140px", width: "100%" }}>
            <Loading
              text={"Nenhum depoimento encontrado para esse treinamento"}
              loading={true}
            />
          </div>
        </CursoDepoimentos>
      );
    }
    return (
      <CursoDepoimentos>
        <div style={{ flex: 1 }}>
          <CarouselProvider
            key={"CarouselProvider"}
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            currentSlide={currentSlide}
            totalSlides={ratingAnswers ? ratingAnswers.length : 0}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "160px",
              overflowY: "hidden",
              position: "relative",
              overflowX: "hidden"
            }}
          >
            <Slider style={{ width: "90%" }}>
              {loading
                ? "loading"
                : ratingAnswers
                ? ratingAnswers.map((item, i) => (
                    <Slide index={i} key={i}>
                      <DepoimentoItem
                        rating={item.record}
                        comment={item.comment}
                        name={item.user.name}
                        estado={item.user.state}
                        fileId={item.user.file_uuid}
                        defaultImg={ImgFrentista}
                      />
                    </Slide>
                  ))
                : null}
            </Slider>
            <ButtonBack
              onClick={e => {
                this.setState({
                  currentSlide: this.state.currentSlide - 1
                });
              }}
              className="btn-carousel btn-carousel-back"
            >
              <IosArrowBack fontSize="50px" color="#535353" />
            </ButtonBack>
            <ButtonNext
              onClick={e => {
                this.setState({
                  currentSlide: this.state.currentSlide + 1
                });
              }}
              className="btn-carousel btn-carousel-next"
            >
              <IosArrowForward fontSize="50px" color="#535353" />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </CursoDepoimentos>
    );
  }
}

const mapStateToProps = state => ({
  ratingAnswer: state.ratingAnswer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RatingAnswersAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCarouselRatings);
