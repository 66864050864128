import React, { Component } from "react";
import IosNotificationsOutline from "react-ionicons/lib/IosNotificationsOutline";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";
import MdClose from "react-ionicons/lib/MdClose";
import MdMenu from "react-ionicons/lib/MdMenu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Creators as NotificationsActions } from "../../store/ducks/notification";
import NotificationBox from "./components/NotificationBox";
import {
  DropDownMenu,
  Header,
  LogoMenuWrapper,
  MenuHamburger,
  MenuWrapper,
  NotificationWrapper,
  Profile,
  ProfileMenuHamburgerWrapper,
  BackButton,
  Logout
} from "./styles";
import { AnalyticsEvent, GA_CATEGORIES, GA_ACTIONS } from "../../analytics";

import { IconArrowLeft, IconLogout } from '../SVGs'

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        name: ""
      },
      dropIsOpen: false,
      isNotificationsOpen: false,
      togglingNotifications: false
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack(){
    window.history.back();
  }

  signOut = () => {
    AnalyticsEvent({
      category: GA_CATEGORIES.AUTH,
      action: GA_ACTIONS.CLICKED_FINISH_SESSION
    });

    localStorage.removeItem("@emae-cursos:userInfo");
    localStorage.removeItem("@emae-cursos:refreshToken");
    localStorage.removeItem("@emae-cursos:token");
    window.location.href = "/";
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.route) {
      state.route = props.location;
      state.dropIsOpen = false;
      state.isNotificationsOpen = false;
      window.scroll(0, 0);
    }

    return state;
  }

  componentDidMount() {
    const { getNotificationRequest } = this.props;

    getNotificationRequest();

    const userInfo = JSON.parse(localStorage.getItem("@emae-cursos:userInfo"));

    if (userInfo) {
      this.setState({ user: userInfo });
    } else {
      this.setState({ user: this.props.userInfo.data });
    }

    this.stickyNavbar();
    document.addEventListener("click", this.handleClick, false);
  }

  handleClick = e => {
    if (
      !this.nav ||
      this.nav.contains(e.target) ||
      this.not.ref.contains(e.target)
    ) {
      return;
    }
    if (this.state.togglingNotifications === true) {
      this.setState({ togglingNotifications: false });
      return;
    }

    this.setState({
      dropIsOpen: this.btnNav.contains(e.target)
        ? !this.state.dropIsOpen
        : false,
      isNotificationsOpen: this.btnNot.contains(e.target)
        ? !this.state.isNotificationsOpen
        : false
    });
  };

  setTogglingNotifications = afterFunction => {
    this.setState(
      {
        togglingNotifications: true
      },
      afterFunction
    );
  };

  stickyNavbar() {
    var navbar = document.getElementById("navbar");
    var sticky = navbar ? navbar.offsetTop : null;

    window.onscroll = function() {
      if (navbar) {
        myFunction();
      }
    };

    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
  }

  renderNotificationBellColor = () => {
    const notsNew = this.props.notification.data.filter(item => {
      return item.read === false && item.active === true;
    });
    return notsNew.length > 0 ? "#FBCE07" : "#fff";
  };

  handleRemoveNotification = id => {
    this.props.removeNotificationRequest({ id });
  };

  render() {
    const { dropIsOpen, isNotificationsOpen, user } = this.state;
    const { location } = this.props;

    if (location.split("/", 2)[1] === "complemento") {
      return null;
    }

    const inicioClass = location === "/home" ? "active" : "";
    const desempenhoClass = location.split("/", 2)[1] === "desempenho" ? "active" : "";
    const documentosClass = location.split("/", 2)[1] === "documentos-corporativos" ? "active" : "";

    return (
      <Header id="navbar">
        <LogoMenuWrapper>
          <MenuWrapper>
            {location !== '/home' && (
            <li>
              <BackButton onClick={this.goBack}>
                <IconArrowLeft />
              </BackButton>
            </li>
            )}
            <li className={inicioClass}>
              <Link to="/home">Início</Link>
            </li>
            {/* <li className={meusCursosClass}>
              <Link to="/meus-cursos">Cursos</Link>
            </li> */}
            <li className={desempenhoClass}>
              <Link to="/desempenho">Desempenho</Link>
            </li>
            <li className={documentosClass}>
              <Link to="/documentos-corporativos">Documentos Corporativos</Link>
            </li>
          </MenuWrapper>
        </LogoMenuWrapper>

        <ProfileMenuHamburgerWrapper>
          <NotificationWrapper>
            <div className="bell-wrapper" ref={ref => (this.btnNot = ref)}>
              <IosNotificationsOutline
                color={this.renderNotificationBellColor()}
                fontSize="28px"
              />
            </div>

            <NotificationBox
              ref={ref => (this.not = ref)}
              visible={isNotificationsOpen}
              handleRemoveNotification={this.handleRemoveNotification}
              notifications={this.props.notification.data}
              notsNew={this.state.notsNew}
              notsOld={this.state.notsOld}
              setTogglingNotifications={this.setTogglingNotifications}
            />
          </NotificationWrapper>
          <Profile>
            <span>
              Olá, {user.name ? user.name : this.props.userInfo.data.name}
            </span>
          </Profile>
          <MenuHamburger ref={ref => (this.btnNav = ref)}>
            {!dropIsOpen ? (
              <MdMenu fontSize="30px" />
            ) : (
              <MdClose fontSize="30px" />
            )}
          </MenuHamburger>
          <Logout onClick={this.signOut}>
            <IconLogout />
          </Logout>
          <DropDownMenu dropIsOpen={dropIsOpen} ref={nav => (this.nav = nav)}>
            <ul>
              <li>
                <Link to="/desempenho">
                  <span>Meu desempenho</span>
                  <MdArrowDropright color="#FBCE07" />
                </Link>
              </li>
              <li>
                <Link to="/perfil">
                  <span>Meu perfil</span>
                  <MdArrowDropright color="#FBCE07" />
                </Link>
              </li>
              <li>
                <Link to="/alterar-senha">
                  <span>Alterar senha</span>
                  <MdArrowDropright color="#FBCE07" />
                </Link>
              </li>
              {this.props.userInfo.data.isAdmin && (
                <li>
                  <Link to="/admin/login-as-user">
                    <span>Logar com outro usuário</span>
                    <MdArrowDropright color="#FBCE07" />
                  </Link>
                </li>
              )}
              <li>
                <a onClick={this.signOut} href="#!">
                  <span>Encerrar sessão</span>
                  <MdArrowDropright color="#FBCE07" />
                </a>
              </li>
            </ul>
          </DropDownMenu>
        </ProfileMenuHamburgerWrapper>
      </Header>
    );
  }
}
const mapStateToProps = state => ({
  userInfo: state.userInfo,
  notification: state.notification
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...NotificationsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
