import React, { Component } from "react";

import { HeaderWrapper, EmaeLogoWrapper } from "./style";

import EmaeLogo from '../../assets/img/emaeBranco.png'

export default class HeaderMain extends Component {
  state = {
    showLogout: false
  }

  static getDerivedStateFromProps(props, state) {
    let adminToken = localStorage.getItem('@emae-cursos:admin-token');
    state.showLogout = !!adminToken;
    return state;
  }

  signOut = () => {
    localStorage.removeItem("@emae-cursos:userInfo");
    localStorage.removeItem("@emae-cursos:refreshToken");
    localStorage.removeItem("@emae-cursos:token");
    window.location.href = "/";
  };

  renderAdminLogout = () => {
    if (!this.state.showLogout) {
      return false;
    }
    return (
      <a onClick={this.signOut} href="#!">
        <span>Voltar para sessão de administrador</span>
      </a>
    );
  };

  render() {
    return (
      <HeaderWrapper>
        <EmaeLogoWrapper>
          <img className='logo-emae' alt='Emae' src={EmaeLogo} />
        </EmaeLogoWrapper>
        {this.renderAdminLogout()}
      </HeaderWrapper>
    );
  }
}
